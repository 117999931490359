import _ from 'lodash';
import { useContext } from 'react';
import type * as React from 'react';

const useSafeContext = <ContextType>(name: string, Context: React.Context<ContextType>) => {
  const context = useContext<ContextType>(Context);
  if (!context) {
    throw new Error(`use${_.capitalize(name)} must be used within a ${name}Provider`);
  }
  return context;
};

export default useSafeContext;

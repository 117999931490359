import { css, withTheme } from '@emotion/react';
import styled from '@emotion/styled/macro';
import * as DM from '@radix-ui/react-dropdown-menu';
import type { Align, Side } from '@radix-ui/react-popper';
import type { FC, ReactNode } from 'react';
import type { Theme } from 'theme/theme';

import { Icon } from '../..';

const iconSize = 25;

const StyledContent = styled(DM.Content)`
  background-color: transparent;
  min-width: 164px;
  z-index: 10;
`;

const StyledItem = styled(DM.Item)(
  ({ theme }) => css`
    align-items: center;
    background-color: ${theme.accentColorDark};
    box-sizing: border-box;
    color: ${theme.mainColor};
    cursor: pointer;
    display: flex;
    font-size: 14px;
    height: 42px;
    margin-bottom: 1px;
    padding: 10px 10px;
    position: relative;
    user-select: none;

    &:first-child {
      border-radius: 3px 3px 0 0;
    }

    &:last-child {
      border-radius: 0 0 3px 3px;
    }

    &:focus-visible {
      background-color: ${theme.accentColorBright};
      outline: none;
    }
  `
);

const ItemIcon = styled.div`
  flex: none;
  margin-right: 10px;
  width: ${iconSize}px;
`;

const ItemText = styled.div`
  flex: 1;
`;

export type DropdownMenuProps = {
  align?: Align;
  alignOffset?: number;
  children: ReactNode;
  disabled?: boolean;
  onClick?: (index: number) => void;
  options: Array<{ text: string; iconName?: string; testId?: string }>;
  side?: Side;
  sideOffset?: number;
  testId?: string;
  theme?: Theme;
};

export const DropdownMenu: FC<DropdownMenuProps> = ({
  align = 'end',
  alignOffset = 10,
  children,
  disabled = false,
  onClick,
  options,
  side = 'bottom',
  sideOffset = 10,
  testId,
  theme,
}) => {
  return (
    <DM.Root>
      <DM.Trigger asChild disabled={disabled} data-testid={testId}>
        {children}
      </DM.Trigger>
      <StyledContent sideOffset={sideOffset} side={side} alignOffset={alignOffset} align={align}>
        {options.map((option, index) => (
          <StyledItem
            key={option.text}
            onClick={() => (onClick ? onClick(index) : null)}
            data-testid={option.testId}
          >
            <ItemIcon>
              {option.iconName && (
                <Icon width={iconSize} type={option.iconName} color={theme?.mainColor} />
              )}
            </ItemIcon>
            <ItemText>{option.text}</ItemText>
          </StyledItem>
        ))}
      </StyledContent>
    </DM.Root>
  );
};

export default withTheme(DropdownMenu);

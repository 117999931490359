export default (props) => (
  <svg viewBox="0 0 22 22" {...props}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <rect fillOpacity="0" fill={props.fill} x="0" y="0" width="22" height="22" />
      <path
        d="M4,6 L17,6 L17,7 L4,7 L4,6 Z M4,16 L17,16 L17,17 L4,17 L4,16 Z M4,11 L12,11 L12,12 L4,12 L4,11 Z"
        id="Combined-Shape"
        fill={props.fill}
      />
    </g>
  </svg>
);

import { css } from '@emotion/css/macro';
import styled from '@emotion/styled/macro';
import PropTypes, { bool, oneOf } from 'prop-types';
import { space, width } from 'styled-system';

const defaultButtonStyles = {
  boxShadow: '0 2px 7px 0 rgba(0,0,0,.1)',
  borderRadius: '20px',
  padding: '10px 20px',
  cursor: 'pointer',
  fontSize: '15px',
  fontWeight: '400',
  lineHeight: '15px',
  textAlign: 'center',
  display: 'inline-flex',
  marginRight: '5px',
  textDecoration: 'none',

  '&:disabled': {
    opacity: 0.65,
    cursor: 'not-allowed',
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
    },
  },

  a: {
    display: 'flex',
    alignItems: 'center',
    color: 'inherit',
    textDecoration: 'none',
  },
};

export const BUTTON_TYPES = ['primary', 'secondary', 'success', 'danger', 'info'];

const StyledButton = styled('button')(
  ({ color, theme: { buttons } }) => {
    if (!buttons) {
      return {};
    }
    return {
      ...defaultButtonStyles,
      color: buttons[color].color,
      border: `1px solid ${buttons[color].border}`,
      backgroundColor: buttons[color].background,
      svg: {
        fill: buttons[color].color,
        marginRight: '5px',
      },

      '&:hover:not(:disabled)': {
        color: buttons[color].hover.color,
        border: `1px solid ${buttons[color].hover.border}`,
        backgroundColor: buttons[color].hover.background,
      },
    };
  },

  ({ active, color, theme: { buttons } }) =>
    active &&
    buttons &&
    css`
      color: ${buttons[color].active.color};
      border: 1px solid ${buttons[color].active.border};
      background-color: ${buttons[color].active.background};
    `,

  ({ small }) =>
    small &&
    css`
      height: auto;
      padding: 4px 11px;
      border-radius: 12px;
    `,

  ({ big }) =>
    big &&
    css`
      padding: 18px 40px;
      text-transform: uppercase;
      font-size: 12px;
      height: auto;
      border-radius: 40px;
      box-shadow: 0 28px 40px 0 rgba(0, 0, 0, 0.28);
      letter-spacing: 1.2px;
    `,

  width,
  space
);

/**
 * Primary UI component for user interaction
 */
const Button = ({
  children,
  color = 'primary',
  active = false,
  disabled = false,
  small = false,
  big = false,
  ...props
}) => (
  <StyledButton
    color={color}
    active={active}
    disabled={disabled}
    small={small}
    big={big}
    {...props}
  >
    {children}
  </StyledButton>
);

Button.propTypes = {
  /**
   * The button content
   */
  children: PropTypes.node.isRequired,
  /**
   * The type of button.
   */
  color: oneOf(BUTTON_TYPES), // NOTE: rename `type` ?
  /**
   * Whether the button is active.
   */
  active: bool,
  /**
   * Whether the button is disabled.
   */
  disabled: bool,
  /**
   * Whether the button has a smaller aspect
   */
  small: bool, // NOTE: merge `small` and `big` in a `size` parameter ?
  /**
   * Whether the button has a bigger aspect
   */
  big: bool,
  ...width.propTypes,
  ...space.propTypes,
};

export default Button;

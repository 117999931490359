export default (props) => (
  <svg viewBox="0 0 14 15" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill={props.fill}
      d="M1 7.5C1 4.18817 3.68817 1.5 7 1.5C10.3118 1.5 13 4.18817 13 7.5C13 10.8118 10.3118 13.5 7 13.5C3.68817 13.5 1 10.8118 1 7.5ZM7 0.5C3.13588 0.5 0 3.63588 0 7.5C0 11.3641 3.13588 14.5 7 14.5C10.8641 14.5 14 11.3641 14 7.5C14 3.63588 10.8641 0.5 7 0.5ZM7.75 8V3.5H6.25V8H7.75ZM7.75 11V9.5H6.25V11H7.75Z"
    />
  </svg>
);

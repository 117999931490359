export type Props = {
  fill: string;
};
export default (props: Props) => (
  <svg
    {...props}
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 7.00012C10 8.10469 9.10457 9.00012 8 9.00012C6.89543 9.00012 6 8.10469 6 7.00012C6 5.89555 6.89543 5.00012 8 5.00012C9.10457 5.00012 10 5.89555 10 7.00012ZM11 7.00012C11 8.65698 9.65685 10.0001 8 10.0001C6.34315 10.0001 5 8.65698 5 7.00012C5 5.34327 6.34315 4.00012 8 4.00012C9.65685 4.00012 11 5.34327 11 7.00012ZM4.46447 12.367C5.40215 11.4918 6.67392 11.0001 8 11.0001V12.0001C6.91772 12.0001 5.89248 12.402 5.14678 13.098C4.40342 13.7918 4 14.7175 4 15.6668V16.0001H9.63368L9.36185 17.0001H4H3V16.0001V15.6668C3 14.4291 3.52678 13.2421 4.46447 12.367ZM11.6182 17.0001H12H12.3818L12 16.7274L11.6182 17.0001ZM15.3911 14.0001H18H19V13.1112V13.11C19 13.0734 18.9993 13.0367 18.9978 13.0001C18.983 12.6294 18.8937 12.2638 18.7338 11.9206C18.5579 11.5431 18.3001 11.2001 17.9751 10.9112C17.65 10.6223 17.2642 10.3931 16.8395 10.2368C16.4148 10.0805 15.9597 10 15.5 10.0001C14.5717 10.0001 13.6815 10.3279 13.0251 10.9113C12.944 10.9835 12.8672 11.0588 12.795 11.137L13.2133 12.2612C13.3269 12.0443 13.4857 11.8399 13.6895 11.6588C14.1517 11.2479 14.8014 11.0001 15.5 11.0001H15.5002C15.8438 11.0001 16.1818 11.0603 16.4941 11.1752C16.8063 11.2901 17.0829 11.4561 17.3107 11.6586C17.5383 11.8609 17.7116 12.0946 17.8273 12.3429C17.9263 12.5554 17.9826 12.7774 17.9965 13.0001H16.3064L15.3911 14.0001ZM10.9206 11.8786C10.6084 11.6689 10.2706 11.4931 9.91356 11.3551C9.30688 11.1206 8.65664 11 8 11.0001L8.00018 12.0001C8.53468 12 9.06255 12.0983 9.55306 12.2879C9.80392 12.3848 10.0419 12.5045 10.264 12.6443L10.6364 12.6365L10.9206 11.8786ZM16.7127 7.14261C16.7127 7.77359 16.2012 8.28509 15.5702 8.28509C14.9392 8.28509 14.4277 7.77359 14.4277 7.14261C14.4277 6.51163 14.9392 6.00012 15.5702 6.00012C16.2012 6.00012 16.7127 6.51163 16.7127 7.14261ZM17.7127 7.14261C17.7127 8.32587 16.7535 9.28509 15.5702 9.28509C14.387 9.28509 13.4277 8.32587 13.4277 7.14261C13.4277 5.95935 14.387 5.00012 15.5702 5.00012C16.7535 5.00012 17.7127 5.95935 17.7127 7.14261Z"
      fill="white"
    />
    <path
      d="M12 11.0001L12.7416 13.2435L15 13.2919L13.2 14.7268L13.8541 17.0001L12 15.6435L10.1459 17.0001L10.8 14.7268L9 13.2919L11.2584 13.2435L12 11.0001Z"
      fill="white"
    />
  </svg>
);

import { css } from '@emotion/css/macro';
import styled from '@emotion/styled/macro';
import { Paragraph2 } from 'components/text/Paragraph';
import _ from 'lodash';
import { useState } from 'react';
import { computeTimePeriodLabel } from 'routes/Projects/Project/Discovery/Filters/timePeriodUtils';
import { formatNumber } from 'shared/formatters';

import wording from './wording';

const Container = styled.div(
  () => css`
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
  `
);

const HoverRow = styled.div(
  ({ left, width }) => css`
    position: absolute;
    bottom: 0;
    top: 0;
    left: ${left - width / 2}px;
    width: ${width}px;
  `
);

const TooltipContainer = styled.div(
  ({ theme, left, top }) => css`
    top: ${top}px;
    left: ${left}px;
    transform: translateX(-50%);
    position: absolute;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
    z-index: 1;
    min-width: ${theme.lineChart.minTooltipWidth};
  `
);

const ValueContainer = styled(Paragraph2)`
  padding: 9px 11px;
  white-space: nowrap;
  border-top-left-radius: ${({ theme }) => theme.borderRadius};
  border-top-right-radius: ${({ theme }) => theme.borderRadius};
  background-color: ${({ theme }) => theme.lineChart.tooltipValueBackground};
  color: ${({ theme }) => theme.mainColorLight};
`;

const CtaContainer = styled(Paragraph2)`
  padding: 5px 11px;
  text-align: center;
  white-space: nowrap;
  border-bottom-left-radius: ${({ theme }) => theme.borderRadius};
  border-bottom-right-radius: ${({ theme }) => theme.borderRadius};
  background-color: ${({ theme }) => theme.accentColorDark};
  cursor: pointer;
`;

const Value = styled(Paragraph2)`
  display: inline;
  color: ${({ theme }) => theme.mainColor};
  font-weight: 500;
  margin-left: 7px;
`;

const Circle = styled.div(
  ({ left, top, theme }) => css`
    width: 7px;
    height: 7px;
    border-radius: 7px;
    background-color: ${theme.mainColor};
    left: ${left}px;
    top: ${top}px;
    transform: translate(-50%, -50%);
    position: absolute;
    cursor: pointer;
  `
);
const CircleSoft = styled(Circle)`
  background-color: rgba(255, 255, 255, 0.2);
  width: 14px;
  height: 14px;
  border-radius: 14px;
  pointer-events: none;
`;

const Tooltip = ({ item, top, left, onPeriodClick }) => {
  const label = computeTimePeriodLabel([item.from, item.to]);

  return (
    <TooltipContainer top={top} left={left}>
      <ValueContainer>
        {label}:<Value>{formatNumber(item.value, { isTrueInteger: true })}</Value>
      </ValueContainer>
      <CtaContainer onClick={onPeriodClick}>{wording.cta}</CtaContainer>
    </TooltipContainer>
  );
};

const HoveredItem = ({ hoveredItem, yScale, rowWidth, onPeriodClick }) => {
  const top = yScale(hoveredItem.hoverValue);
  return (
    <>
      <Circle top={top} left={rowWidth / 2} onClick={onPeriodClick} />
      <CircleSoft top={top} left={rowWidth / 2} />
      <Tooltip
        top={top + 14}
        left={rowWidth / 2}
        item={hoveredItem}
        onPeriodClick={onPeriodClick}
      />
    </>
  );
};

const HoverSensor = ({ width, data, xScale, yScale, onPeriodClick }) => {
  const [hoveredItem, setHoverItem] = useState();
  const rowWidth = width / _.size(data);
  return (
    <Container>
      {_.map(data, (item, i) => {
        const left = xScale(item.hoverDate);

        if (item.noHover) {
          return null;
        }

        return (
          <HoverRow
            key={i}
            left={left}
            width={rowWidth}
            onMouseEnter={() => setHoverItem(item)}
            onMouseLeave={() => setHoverItem(null)}
          >
            {hoveredItem === item && (
              <HoveredItem
                yScale={yScale}
                rowWidth={rowWidth}
                hoveredItem={hoveredItem}
                onPeriodClick={() => onPeriodClick(item)}
              />
            )}
          </HoverRow>
        );
      })}
    </Container>
  );
};

export default HoverSensor;

import common from 'theme/common';

import cobaltComponentsValues from './componentsValues';
import cobaltValues from './values';

export default {
  ...common,
  ...cobaltValues,
  ...cobaltComponentsValues,
  fontFace: '',
};

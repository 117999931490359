export type EllipsisHorizontalProps = {
  fill: string;
  strokeWidth?: number;
};

export default (props: EllipsisHorizontalProps) => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    {...props}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="22" height="22" fill="white" fillOpacity="0.01" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 13C5.82843 13 6.5 12.3284 6.5 11.5C6.5 10.6716 5.82843 10 5 10C4.17157 10 3.5 10.6716 3.5 11.5C3.5 12.3284 4.17157 13 5 13Z"
      fill={props.fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 13C11.8284 13 12.5 12.3284 12.5 11.5C12.5 10.6716 11.8284 10 11 10C10.1716 10 9.5 10.6716 9.5 11.5C9.5 12.3284 10.1716 13 11 13Z"
      fill={props.fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17 13C17.8284 13 18.5 12.3284 18.5 11.5C18.5 10.6716 17.8284 10 17 10C16.1716 10 15.5 10.6716 15.5 11.5C15.5 12.3284 16.1716 13 17 13Z"
      fill={props.fill}
    />
  </svg>
);

const fontFamily = 'Lato, arial, sans-serif';

export default {
  primary: '#f3c600',
  secondary: '#bfbfbf',
  success: '#6bc996',
  danger: '#f25757',
  info: '#515979',
  color: '#030619',
  subColor: '#536F87',
  fontColor: '#515979',
  placeholderFontColor: '#8A8A8A',
  blackFontColor: '#828282',
  disabledFontColor: '#b5bacd',
  gray: '#F4F4F4',
  darkGrayColor: '#E5E8F4',
  blueGrayColor: '#8e99c4',
  widthMargin: '40px',
  borderColor: '#D9D9D9',
  borderRadioDisabbled: '#d9d9d9',
  backgroundRadioDisabled: '#f2f2f2',

  mainColor: '#030619',
  mainColorLight: 'rgba(3,6,25, 0.6)',
  oppositeColor: '#ffffff',
  oppositeColorLight: 'rgba(255, 255, 255, 0.6)',
  iconSize: 18,
  fontFamily,
  bodyBackgroundPrimary: '#f5f5f5',
};

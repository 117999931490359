export default (props) => (
  <svg viewBox="0 0 22 22" {...props}>
    <g fill={props.color}>
      <path
        d="M11.8535534,6.14644661 C12.0488155,6.34170876 12.0488155,6.65829124 11.8535534,6.85355339 L7.70710678,11 L17,11 L17,12 L7.70710678,12 L11.8535534,16.1464466 C12.0488155,16.3417088 12.0488155,16.6582912 11.8535534,16.8535534 C11.6582912,17.0488155 11.3417088,17.0488155 11.1464466,16.8535534 L6.14644661,11.8535534 C5.95118446,11.6582912 5.95118446,11.3417088 6.14644661,11.1464466 L11.1464466,6.14644661 C11.3417088,5.95118446 11.6582912,5.95118446 11.8535534,6.14644661 Z"
        transform="translate(11.500000, 11.500000) scale(-1, 1) translate(-11.500000, -11.500000)"
      />
    </g>
  </svg>
);

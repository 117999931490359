import styled from '@emotion/styled/macro';
import { node, oneOf, string } from 'prop-types';

export const BADGE_TYPE = ['primary', 'secondary', 'danger', 'info', 'success'];
const IconText = styled.span`
  margin-left: 5px;
`;

const StyledBadge = styled.span`
  font-size: ${({ size }) => size}; // main size
  display: inline-flex;
  align-items: center;
  margin: 0 5px;
  padding: ${({ icon }) => (icon ? '2px 12px' : '.3em .9em')};
  border-radius: 20px;
  background: ${({ theme, color }) => theme[color]};
  color: white;
  vertical-align: top;

  > svg {
    fill: white;
    stroke: white;
    width: 20px;
    height: 20px;
  }
`;

/**
 *
 * WIP Status are used to show the process state of a pipeline or an element. They have several states.
 * Badges are used to count something.
 */
const Badge = ({ children, icon = null, color = 'primary', size = '13px', ...props }) => {
  return (
    <StyledBadge icon={icon} color={color} size={size} {...props}>
      {icon}
      {icon && children ? <IconText>{children}</IconText> : children}
    </StyledBadge>
  );
};

Badge.propTypes = {
  color: oneOf(BADGE_TYPE),
  icon: node,
  size: string,
};

export default Badge;

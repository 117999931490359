export type Props = {
  fill: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

export default (props: Props) => (
  <svg viewBox="0 0 11 10" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx="5.5" cy="5" r="5" fill="#000039" />
  </svg>
);

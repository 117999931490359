import _ from 'lodash';
import { useContext } from 'react';
import ViewerContext from 'routes/ViewerContext';
import { hasSomeRoles } from 'services/acl';

/**
 * This is a HOC component that will read the [roles] prop
 * and display the BaseComponent if and only if the current user has a role
 * compatible with the one defined in roles
 */
const withDisplayIfProperRoles = (BaseComponent) => (props) => {
  const { roles, PlaceholderForbiddenAccess } = props;
  const viewerContext = useContext(ViewerContext);

  if (_.isNil(viewerContext) || hasSomeRoles(viewerContext.viewer, roles))
    return <BaseComponent {..._.omit(props, 'roles')} />;
  return PlaceholderForbiddenAccess ? <PlaceholderForbiddenAccess /> : null;
};

export default withDisplayIfProperRoles;

export default (props) => {
  const { fill, ...others } = props;
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={fill}
      {...others}
    >
      <path
        d="M10.5 4.08333H6.31944C6.31944 4.08333 2.91667 4.08333 2.91667 7.48611C2.91667 10.8889 2.91667 12.25 2.91667 12.25"
        stroke="#9999B0"
        strokeLinecap="round"
      />
      <path
        d="M8.74967 6.41667L11.083 4.08333L8.74967 1.75"
        stroke="#9999B0"
        strokeLinecap="round"
      />
    </svg>
  );
};

import type { Nilable } from '@bloom-hub/shared';
import * as Sentry from '@sentry/browser';
import { getConfig } from 'config/config-service';
import _ from 'lodash';
import { isDevEnv, isE2eEnv } from 'services/utils';

const LOGS_LEVEL = {
  INFO: 'info',
  DEBUG: 'debug',
  WARNING: 'warning',
  ERROR: 'error',
  FATAL: 'fatal',
} as const;

const LOGS_TYPE = {
  REACT: 'react',
  ELASTIC: 'elastic',
  GRAPHQL: 'graphql',
  SUBSCRIPTION: 'subscription',
} as const;

const init = () => {
  const config = getConfig();
  if (isDevEnv(config) && !config.FORCE_SENTRY) return;

  // We don't want sentry logging for e2e
  if (isE2eEnv(config) && !config.FORCE_SENTRY) return;

  const environment = config.APP_ENV;
  const release = `${process.env.REACT_APP_NAME}@${process.env.REACT_APP_VERSION}`;
  const dsn = config.SENTRY_DSN;
  Sentry.init({ dsn, environment, release });
};

type User = {
  email: string;
};

const setUser = (user: Nilable<User>) => {
  if (user) return Sentry.setUser({ email: user.email });
  return Sentry.captureException(new Error('no user found when sentry.setUser was called'));
};

type SendLogsParams = {
  error?: Error;
  type?: (typeof LOGS_TYPE)[keyof typeof LOGS_TYPE];
  metadata?: Record<string, unknown> | Array<Record<string, string>>; // array shouldnt be used here
  message?: Nilable<string>;
  level?: (typeof LOGS_LEVEL)[keyof typeof LOGS_LEVEL];
};

const sendLogs = ({ error, type, metadata, message, level = LOGS_LEVEL.ERROR }: SendLogsParams) => {
  //eslint-disable-next-line
  if (isDevEnv()) console.error(error); // logging some errors that are not catched/loggued elsewhere (ex: graphql)

  // we don't want to log every time a user's token is expired, or some specific errors with "withSentry" flag set to false
  if (
    _.get(error, 'networkError.statusCode') === 401 || // happens via graphql api
    _.get(error, 'networkError.statusCode') === 307 || // redirect to maintenance page
    _.get(error, 'response.status') === 401 || // happens on axios ES client from white dashboards
    _.get(error, 'message') === 'jwt expired' || // happens on apollo subscriptions errors
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    /* @ts-ignore */
    _.find(metadata, (e) => !e.extensions?.withSentry)
  )
    return;

  if (!error && !message) return Sentry.captureException(new Error(`Unknown error`));

  Sentry.withScope((scope) => {
    if (type) scope.setTags({ type });
    if (metadata) scope.setExtras({ metadata });
    if (error) {
      if (_.isError(error)) return Sentry.captureException(error);
      if (_.isString(error)) return Sentry.captureMessage(error, level);
    }

    return Sentry.captureMessage(message || 'Unknown error (message)', level);
  });
};

export default { init, setUser, sendLogs };

export { LOGS_LEVEL, LOGS_TYPE };

import { css } from '@emotion/css/macro';
import { withTheme } from '@emotion/react';
import styled from '@emotion/styled/macro';
import { Icon } from 'components';
import { Paragraph1, Paragraph3 } from 'components/text/Paragraph';
import TooltipContent, { TooltipTrigger } from 'components/Tooltip';
import _ from 'lodash';
import { components } from 'react-select';

const DropdownIndicatorContainer = styled.div`
  height: 22px;
  padding: 0px ${({ theme }) => theme.select.internalMargin};
`;
const DropdownIcon = ({ isOpen }) => {
  return (
    <Icon
      width={22}
      height={22}
      type={isOpen ? 'discovery-chevron-up' : 'discovery-chevron-down'}
      color={'#FFFFFF'}
    />
  );
};
const GenericDropdownIndicator = (props) => {
  return (
    <DropdownIndicatorContainer className={props.className || ''}>
      <DropdownIcon isOpen={!!props.selectProps.menuIsOpen} />
    </DropdownIndicatorContainer>
  );
};

const MultiValueContainerWrapper = styled.div(
  ({ theme }) => css`
    background-color: ${theme.primaryColor};
    border-radius: ${theme.borderRadius};
    line-height: normal;
    margin: 0 0 ${theme.select.internalMargin} ${theme.select.internalMargin};
  `
);
const MultiValueContainerDefault = (props) => {
  return (
    <MultiValueContainerWrapper>
      <components.MultiValueContainer {...props} />
    </MultiValueContainerWrapper>
  );
};

const LabelContainer = styled.span`
  padding: 5px;
  display: flex;
`;
const LabelTitle = styled(Paragraph3)(
  ({ theme }) => css`
    display: inline;
    color: ${theme.mainColorLight};
    margin-right: 5px;
  `
);
const LabelValue = styled(Paragraph3)(
  ({ theme }) => css`
    display: inline;
    color: ${theme.mainColor};
  `
);
const ColorCircle = styled.div`
  background-color: ${({ color }) => color};
  height: 5px;
  width: 5px;
  min-width: 5px;
  border-radius: 50%;
  margin-right: 7px;
  align-self: center;
`;
const WarningContainer = styled.div`
  margin-left: 10px;
`;
const Warnning = withTheme(({ theme, id, tooltipMessage }) => (
  <WarningContainer>
    <TooltipTrigger targetId={`tooltip-${id}-filter`}>
      <Icon type="discovery-infos" height={theme.iconSizeSmall} color={theme.warningColor} />
    </TooltipTrigger>
    <TooltipContent id={`tooltip-${id}-filter`} place="bottom">
      {tooltipMessage}
    </TooltipContent>
  </WarningContainer>
));
/**
 * A component that show a "tag" when a filter is selected
 * We don't use the default one because we want to show the type of filter
 */
const MultiValueLabelDefault = (props) => {
  return (
    <LabelContainer>
      {props.data.color && <ColorCircle color={props.data.color} />}
      {!props.data.color && props.data.groupLabel && (
        <LabelTitle>{props.data.groupLabel}:</LabelTitle>
      )}
      <LabelValue>{props.data.label}</LabelValue>
      {props.data.warning && <Warnning id={props.data.label} tooltipMessage={props.data.warning} />}
    </LabelContainer>
  );
};

const RemoveContainer = styled.div(
  ({ theme, isDisabled }) => css`
    height: 100%;
    display: flex;
    align-items: center;
    border-radius: ${theme.borderRadius};
    display: ${isDisabled ? 'none' : null};
    :hover {
      background-color: ${theme.primaryColorDarkUltra};
    }
  `
);
const MultiValueRemoveDefault = (props) => {
  return (
    <components.MultiValueRemove {...props}>
      <RemoveContainer isDisabled={props.selectProps.isDisabled}>
        <Icon type="close" color="white" />
      </RemoveContainer>
    </components.MultiValueRemove>
  );
};

const PlaceholderTextRelativeContainer = styled(Paragraph1)(
  ({ theme, isMulti }) => css`
    position: relative;
    width: 0;
    color: transparent;
    margin-bottom: ${isMulti ? theme.select.internalMargin : '0px'};
  `
);
const PlaceholderText = styled.div(
  ({ theme }) => css`
    position: absolute;
    top: 0;
    left: calc(${theme.select.internalMargin} + 2px);
    color: ${theme.mainColorLight};
    white-space: nowrap;
  `
);
const PlaceholderDefault = ({ selectProps, isMulti, ...props }) => {
  return (
    <components.Placeholder {...props}>
      <PlaceholderTextRelativeContainer isMulti={isMulti}>
        &nbsp;<PlaceholderText>{selectProps.placeholder}</PlaceholderText>
      </PlaceholderTextRelativeContainer>
    </components.Placeholder>
  );
};

const GroupHeadingContainer = styled.div`
  display: flex;
  margin-bottom: 5px;
`;
const GroupLabel = styled.div(
  ({ theme }) => css`
    padding-left: calc(${theme.col} * 2);
    color: ${theme.mainColorLight};
    text-transform: ${theme.label2.textTransform};
    font-weight: ${theme.label2.fontWeight};
    font-size: ${theme.label2.fontSize};
    letter-spacing: ${theme.label2.letterSpacing};
    line-height: ${theme.label2.lineHeight};
    padding-bottom: 0;
    margin-right: 7px;
  `
);
const GroupHeading = (props) => {
  const groupData = _.find(props.selectProps.options, { label: props.children });
  const color = groupData && groupData.color;
  return (
    <GroupHeadingContainer>
      <GroupLabel>{props.children}</GroupLabel>
      {color && <ColorCircle color={color} />}
    </GroupHeadingContainer>
  );
};

export {
  GenericDropdownIndicator,
  MultiValueContainerDefault,
  MultiValueLabelDefault,
  MultiValueRemoveDefault,
  PlaceholderDefault,
  GroupHeading,
};

import { localStorageGet, localStorageRemove, localStorageSet } from '@bloom-hub/shared';
import { Body, Button, ButtonMode, Checkbox, Icon, IconName, Label, Modal } from '@bloom/ui';
import styled from '@emotion/styled/macro';
import type { FC } from 'react';
import { useEffect, useState } from 'react';

import wording from './wording.json';

const LabelWrapper = styled.label`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 1rem;
  cursor: pointer;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const Description = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  & > * {
    flex: none;
  }
`;

const Content = styled.div`
  overflow: auto;
  margin-bottom: 1rem;
  flex: 1;
  max-height: 200px;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  justify-content: end;
  gap: 2rem;
`;

const Url = styled.span`
  overflow-wrap: break-word;
`;

const localStorageKey = 'alwaysOpenExternalLink';

type ExternalLinkModalProps = {
  href: string;
  showPopup: boolean;
  onClose: () => void;
};
const ExternalLinkModal: FC<ExternalLinkModalProps> = ({ showPopup, href, onClose }) => {
  const [checked, setChecked] = useState(true);
  const handleOpenLink = () => {
    if (checked) {
      localStorageSet(localStorageKey, true, { ttl: 60 * 60 * 24 * 15 });
    } else {
      localStorageRemove(localStorageKey);
    }
    onClose();
    window.open(href, '_blank', 'noopener,noreferrer');
  };

  const handleToggleCheckbox = (newChecked: boolean) => {
    setChecked(newChecked);
  };

  const shoulOpenDirectly = () => {
    return !!localStorageGet(localStorageKey);
  };

  useEffect(() => {
    if (shoulOpenDirectly() && showPopup === true) {
      handleOpenLink();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showPopup]);

  return !shoulOpenDirectly() ? (
    <Modal
      onOpenChange={(isOpen: boolean) => {
        if (!isOpen) {
          onClose();
        }
      }}
      open={showPopup}
      title={
        <Title>
          <Icon name={IconName.InfoCircle} />
          {wording.warning}
        </Title>
      }
      description={
        <Description>
          <Body>{wording.description}</Body>

          <Content>
            <Body color="secondary">
              <Url>{href}</Url>
            </Body>
          </Content>

          <LabelWrapper>
            <Checkbox onChange={handleToggleCheckbox} isChecked={checked} />
            <Label>{wording.remember}</Label>
          </LabelWrapper>
        </Description>
      }
      action={
        <Buttons>
          <Button mode={ButtonMode.Outlined} onClick={onClose}>
            {wording.back}
          </Button>
          <Button mode={ButtonMode.Filled} onClick={handleOpenLink}>
            {wording.open}
          </Button>
        </Buttons>
      }
    />
  ) : null;
};

export default ExternalLinkModal;

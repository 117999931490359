export default (props) => (
  <svg viewBox="0 0 27 11" {...props}>
    <g stroke={props.fill} strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M10.5,5.5 C10.5,2.73855349 8.26144651,0.5 5.5,0.5 C2.73855349,0.5 0.5,2.73855349 0.5,5.5 C0.5,8.26144651 2.73855349,10.5 5.5,10.5 C8.26144651,10.5 10.5,8.26144651 10.5,5.5 Z"
        transform="translate(5.500000, 5.500000) translate(-5.500000, -5.500000) "
      />
      <path
        d="M26.5,5.5 C26.5,2.73855349 24.2614465,0.5 21.5,0.5 C18.7385535,0.5 16.5,2.73855349 16.5,5.5 C16.5,8.26144651 18.7385535,10.5 21.5,10.5 C24.2614465,10.5 26.5,8.26144651 26.5,5.5 Z"
        transform="translate(21.500000, 5.500000) translate(-21.500000, -5.500000) "
      />
      <path d="M6.5,5.5 L22.5,5.5" strokeLinecap="square" strokeDasharray="2,4" />
    </g>
  </svg>
);

import { Loader } from 'components';
import { sendAnalyticsData } from 'libs/usage-analytics';
import _ from 'lodash';
import { Suspense, useEffect } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';

import withDisplayIfProperRoles from './withDisplayIfProperRoles';

const RouteWithRoles = withDisplayIfProperRoles((props) => {
  // if specified scroll the window to the top each time a new route is mounted
  useEffect(() => {
    if (props.scrollTop) window.scrollTo(0, 0);
  }, [props.scrollTop]);
  return <Route {...props} />;
});

const RouteComponent = ({ match, location, path, Component, ...otherProps }) => {
  /**
   * we voluntarily don't watch match because [params] object changes even when params don't change
   * Plus if [params] change, [match.url] will change, so watching [match.url] is enough
   */
  useEffect(() => {
    // only send the event on exact match, ie: this is the final page
    // only send the event once when the match is not empty, ie: the route is not at the same url as its parent
    if (match.isExact && !_.isEmpty(path)) {
      const params = new URLSearchParams(location.search);
      const queryParams = {};
      for (let entry of params.entries()) {
        queryParams[entry[0]] = entry[1];
      }
      sendAnalyticsData('page', {
        path: match.path,
        prams: match.params,
        url: match.url,
        queryParams,
      });
    }
  }, [match.isExact, match.url, location.search]); // eslint-disable-line

  return <Component {...otherProps} match={match} path={path} location={location} />;
};

const BloomRouter = (props) => {
  const searchParams = location.search;
  const {
    match: { path: parentPath },
    routes,
  } = props;
  const otherProps = _.omit(props, ['match', 'routes']);
  const prefix =
    _.last(parentPath) === '/' ? parentPath.substring(0, _.size(parentPath) - 1) : parentPath;
  return (
    <Suspense fallback={<Loader />}>
      <Switch>
        {routes.routes.map(({ path, roles, exact, Component, PlaceholderForbiddenAccess }) => (
          <RouteWithRoles
            key={prefix + path}
            path={prefix + path}
            roles={roles}
            exact={exact}
            scrollTop={true}
            PlaceholderForbiddenAccess={PlaceholderForbiddenAccess}
            render={({ match: routeMatch, location: routeLocation }) => (
              <RouteComponent
                {...otherProps}
                match={routeMatch}
                location={routeLocation}
                path={path}
                Component={Component}
              />
            )}
          />
        ))}
        <Redirect to={{ pathname: prefix + routes.defaultPath, search: searchParams }} />
      </Switch>
    </Suspense>
  );
};

/**
 * get the url of a parent route
 * @param {string} url the url of the child
 * @param {int} n the ancester number (1 for the parent, 2 for the parent of the parent...)
 */
const getParentUrl = (url, n = 1) => {
  const splitedUrl = url.split('/');
  const splitedUrlSize = _.size(splitedUrl);
  return _.reduce(
    url.split('/'),
    (path, chunk, index) => {
      if (index === 0) return path;
      if (index < splitedUrlSize - n) path += `/${chunk}`;
      return path;
    },
    ''
  );
};

export default withRouter(BloomRouter);
export { getParentUrl };

export default (props) => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.2461 4.19626C10.0141 4.32483 9.82319 4.50946 9.69339 4.73083L3.18309 15.9169C3.05702 16.1339 2.99403 16.3781 3.00045 16.6251C3.00686 16.8721 3.08245 17.1131 3.21964 17.3241C3.35683 17.5351 3.5508 17.7085 3.78209 17.8271C4.01339 17.9457 4.27388 18.0052 4.53746 17.9996H17.4964C17.7571 17.9996 18.0132 17.9361 18.2398 17.8153C18.4663 17.6946 18.6555 17.5207 18.7887 17.3108C18.9219 17.1008 18.9946 16.8621 18.9997 16.6179C19.0048 16.3737 18.942 16.1324 18.8176 15.9178L12.3307 4.73171C12.201 4.51024 12.0103 4.32549 11.7784 4.19677C11.5464 4.06805 11.2819 4.00009 11.0123 4C10.7428 3.99991 10.4781 4.06769 10.2461 4.19626ZM3.9351 16.6063C3.93218 16.512 3.956 16.4186 4.00412 16.3357H4.00365L10.513 5.15097C10.5623 5.06732 10.6345 4.99759 10.7223 4.94904C10.8101 4.9005 10.9102 4.87491 11.0122 4.87496C11.1141 4.875 11.2142 4.90067 11.3019 4.9493C11.3897 4.99792 11.4619 5.06772 11.511 5.15141L17.9966 16.3353C18.0438 16.4166 18.0676 16.508 18.0658 16.6005C18.0639 16.6931 18.0364 16.7836 17.986 16.8631C17.9355 16.9427 17.8639 17.0086 17.7781 17.0544C17.6922 17.1002 17.5952 17.1243 17.4964 17.1244H4.52718C4.42656 17.1281 4.32668 17.1068 4.23771 17.0626C4.14875 17.0184 4.07387 16.9529 4.02071 16.8727C3.96756 16.7926 3.93801 16.7006 3.9351 16.6063ZM11 14.0489C10.8743 14.0489 10.7537 14.0021 10.6648 13.9187C10.5759 13.8354 10.5259 13.7223 10.5259 13.6044V8.2711C10.5259 8.15323 10.5759 8.04018 10.6648 7.95684C10.7537 7.87349 10.8743 7.82666 11 7.82666C11.1257 7.82666 11.2463 7.87349 11.3352 7.95684C11.4241 8.04018 11.4741 8.15323 11.4741 8.2711V13.6044C11.4741 13.7223 11.4241 13.8354 11.3352 13.9187C11.2463 14.0021 11.1257 14.0489 11 14.0489ZM11 16.1733C11.3482 16.1733 11.6305 15.9087 11.6305 15.5822C11.6305 15.2558 11.3482 14.9911 11 14.9911C10.6518 14.9911 10.3695 15.2558 10.3695 15.5822C10.3695 15.9087 10.6518 16.1733 11 16.1733Z"
    />
  </svg>
);

import cobaltValues from 'theme/cobalt/values';
import common from 'theme/common';

import lightComponentsValues from './componentsValues';
import fontFace from './fontFace';
import lightValues from './values';

export default {
  ...common,
  ...cobaltValues, // temprarily contain cobalt theme for compatibility with non uptodate components
  ...lightValues,
  ...lightComponentsValues,
  fontFace,
};

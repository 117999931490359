export default (props) => (
  <svg {...props} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill={props.fill} d="M13.105 11a2.105 2.105 0 1 0-4.21 0 2.105 2.105 0 0 0 4.21 0Z" />
    <path
      fill={props.fill}
      d="M15.924 7.266a1.962 1.962 0 0 0-.473-.728 1.957 1.957 0 0 0-.726-.474c-.222-.086-.557-.19-1.172-.218-.667-.03-.867-.037-2.553-.037-1.687 0-1.886.007-2.552.037-.616.028-.95.132-1.173.218a1.955 1.955 0 0 0-.726.474c-.21.204-.372.453-.473.728-.087.223-.19.558-.217 1.175-.03.668-.037.868-.037 2.559 0 1.69.006 1.89.037 2.558.028.618.13.953.217 1.176.101.275.263.524.472.728.204.21.452.372.727.474.222.086.557.19 1.173.218.666.03.865.036 2.552.036s1.887-.006 2.552-.036c.616-.028.95-.132 1.173-.218.551-.213.987-.65 1.199-1.202.086-.223.19-.558.218-1.176.03-.667.036-.868.036-2.558s-.006-1.891-.037-2.559c-.027-.617-.13-.952-.217-1.175ZM11 14.25A3.247 3.247 0 0 1 7.756 11 3.247 3.247 0 0 1 11 7.749a3.247 3.247 0 0 1 3.244 3.25A3.247 3.247 0 0 1 11 14.252zm3.372-5.871a.759.759 0 1 1 .002-1.518.759.759 0 0 1-.002 1.518z"
    />
    <path
      fill={props.fill}
      d="M11 22C4.926 22 0 17.074 0 11S4.926 0 11 0s11 4.926 11 11-4.926 11-11 11Zm6.278-13.604c-.03-.673-.137-1.132-.293-1.533a3.23 3.23 0 0 0-1.848-1.848c-.401-.156-.86-.263-1.533-.293-.673-.031-.888-.038-2.604-.038-1.715 0-1.93.007-2.604.038-.672.03-1.132.137-1.533.293a3.094 3.094 0 0 0-1.12.729c-.32.315-.569.697-.728 1.119-.156.401-.263.86-.293 1.533-.031.673-.038.889-.038 2.604s.007 1.93.038 2.604c.03.673.137 1.132.293 1.533.159.422.407.804.729 1.12.315.32.697.57 1.119.728.401.156.86.263 1.533.293.673.031.889.038 2.604.038s1.93-.007 2.604-.038c.673-.03 1.132-.137 1.533-.293a3.097 3.097 0 0 0 1.12-.729c.32-.315.57-.697.728-1.119.156-.401.263-.86.294-1.533.03-.673.037-.889.037-2.604s-.007-1.93-.038-2.604Z"
    />
  </svg>
);

import { differenceInDays } from 'date-fns';

const computePeriodTimeSpan = ({ fromDate, toDate, diffInDays }) => {
  const diffInDaysSafe = diffInDays || differenceInDays(toDate, fromDate);
  if (diffInDaysSafe < 7) return `(${diffInDaysSafe} days)`;
  if (diffInDaysSafe < 31) return `(${Math.round((diffInDaysSafe / 7) * 10) / 10} weeks)`;
  return `(${Math.round((diffInDaysSafe / 30) * 10) / 10} months)`;
};

export { computePeriodTimeSpan };

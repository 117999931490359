import '@bloom/ui/dist/style.css';

import { Global, css, withTheme } from '@emotion/react';
import type { FC } from 'react';

import type { Theme } from './theme/theme';

type GlobalStylesProps = {
  theme: Theme;
  withRoot: boolean;
};

const GlobalStyles: FC<GlobalStylesProps> = ({ theme, withRoot = true }) => (
  <Global
    styles={css`
      ${theme.fontFace}

      input,
      button,
      select,
      textarea,
      optgroup,
      option {
        font-family: inherit;
        font-size: inherit;
        font-style: inherit;
        font-weight: inherit;
      }

      body,
      html ${withRoot ? ', #root' : null} {
        margin: 0;
        padding: 0;
        width: 100%;
        font-family: ${theme.fontFamily};
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: ${theme.mainColor};
      }

      html {
        height: 100vh;
      }

      body {
        min-height: 100vh;
        height: 100%;
      }

      body.grabbing {
        cursor: grabbing !important;
        user-select: none;
      }

      ${withRoot
        ? `#root {
        display: flex;
        flex-direction: column;
        min-height: 100vh;
        height: 100%;
        justify-content: space-between;
      }`
        : null}

      :focus {
        outline: none;
      }

      *,
      ::before,
      ::after {
        box-sizing: border-box;
      }

      canvas {
        vertical-align: bottom; /* this is to prevent ghost margin, see https://stackoverflow.com/a/9878137 */
      }

      #modal-root {
        position: relative;
        z-index: 10000;
      }
    `}
  />
);

export default withTheme(GlobalStyles);

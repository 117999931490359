export type CheckProps = {
  fill: string;
};

export default (props: CheckProps) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 11 9"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    stroke={props.fill}
    fill="none"
  >
    <path d="M0.5 4.65387L3.40906 7.5L10.5 0.5" strokeWidth="1.3" strokeLinejoin="round" />
  </svg>
);

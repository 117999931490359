import styled from '@emotion/styled/macro';
import emptyFolder from 'assets/empty-folder.svg';
import { ButtonWithIcon, Icon } from 'components';
import { Link } from 'components/text/Link';
import { Component } from 'react';

const Container = styled.div`
  position: relative;
  height: 100%;
  text-align: center;
`;

const CenteredContainer = styled('div')({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
});

const EmptyContainerTitle = styled('div')({
  fontSize: 18,
  marginTop: 35,
  marginBottom: 10,
});

const EmptyContainerSubtitle = styled('div')({
  color: '#BFBFBF',
  fontSize: 14,
  marginBottom: 38,
});

class EmptyMainContainer extends Component {
  render() {
    const { buttonText, subtitleText, titleText, createUrl, children } = this.props;
    return (
      <Container>
        {children}
        <CenteredContainer>
          <img src={emptyFolder} alt="empty folder" height={160} />
          <EmptyContainerTitle>{titleText}</EmptyContainerTitle>
          <EmptyContainerSubtitle>{subtitleText}</EmptyContainerSubtitle>
          {buttonText && (
            <Link to={createUrl}>
              <ButtonWithIcon iconComponent={<Icon fill="white" type="add" />}>
                {buttonText}
              </ButtonWithIcon>
            </Link>
          )}
        </CenteredContainer>
      </Container>
    );
  }
}

export default EmptyMainContainer;

import { Component, createContext } from 'react';

const ViewerContext = createContext();
const { Provider, Consumer } = ViewerContext;
class ViewerProvider extends Component {
  state = {
    viewer: this.props.viewer,
  };

  render() {
    return <Provider value={this.state}>{this.props.children}</Provider>;
  }
}

export default ViewerContext;
export { ViewerProvider, Consumer as ViewerConsumer };

import { css } from '@emotion/css/macro';
import styled from '@emotion/styled/macro';
import { area, curveMonotoneX, line } from 'd3-shape';

const curve = curveMonotoneX;

const LinePath = styled.path(
  ({ theme, opacify }) => css`
    stroke: ${theme.mainColor};
    // Fix rendering bug on chrome
    stroke-width: 0.99px;
    fill: none;
    opacity: ${opacify ? theme.lightOpacity : 1};
  `
);

const AreaPath = styled.path(
  () => css`
    stroke: none;
  `
);

const Line = ({ opacify, data, xScale, yScale }) => {
  const lineFunc = line()
    .curve(curve)
    .x((d) => xScale(d.date))
    .y((d) => yScale(d.value));

  return <LinePath d={lineFunc(data)} opacify={opacify} />;
};

const Bound = styled.path(
  ({ theme }) => css`
    stroke: ${theme.accentColorDark};
  `
);

const Bound2 = styled.path(
  ({ theme }) => css`
    stroke: ${theme.secondaryColorLightUltra};
  `
);

const Area = ({
  data,
  xScale,
  yScale,
  idClipPath,
  idGrandient,
  idClipPath2,
  idGrandient2,
  withBounds,
  maskStart,
  maskEnd,
  maskStart2,
  maskEnd2,
  y,
  height,
}) => {
  const lineFunc = area()
    .curve(curve)
    .x((d) => xScale(d.date))
    .y((d) => yScale(d.value))
    .y1(() => yScale.range()[0]);
  return (
    <>
      <g clipPath={`url(#${idClipPath})`}>
        {withBounds && <Bound d={`M ${maskStart} ${y} V ${height}`} />}
        {withBounds && <Bound d={`M ${maskEnd} ${y} V ${height}`} />}
        <AreaPath d={lineFunc(data)} fill={`url(#${idGrandient})`} />
        <Line data={data} xScale={xScale} yScale={yScale} />
      </g>
      {maskStart2 && (
        <g clipPath={`url(#${idClipPath2})`}>
          {withBounds && <Bound2 d={`M ${maskStart2} ${y} V ${height}`} />}
          {withBounds && <Bound2 d={`M ${maskEnd2} ${y} V ${height}`} />}
          <AreaPath d={lineFunc(data)} fill={`url(#${idGrandient2})`} />
          <Line data={data} xScale={xScale} yScale={yScale} />
        </g>
      )}
    </>
  );
};

export { Line, Area };

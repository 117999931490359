export default {
  scrollbar: {
    width: '10px',
  },
  navbar: {
    height: '60px',
    logoHeight: 11,
  },
  footer: {
    height: '30px',
  },
  navLinearProgress: {
    height: '4px',
    animationTime: '2.2s',
  },
  menu: {
    width: '400px',
    fontSize: '13px',
    lineHeight: '15px',
  },
  CookiesPrefPanel: {
    width: '450px',
  },
  lineChart: {
    gradientStart: '#883fff',
    gradientEnd: '#883fff',
    gradient2Start: '#5CFFF5',
    gradient2End: '#5CFFF5',
    tooltipValueBackground: 'rgba(21, 42, 119, 0.8)',
    minTooltipWidth: '188px',
  },
  rank: {
    minHeight: '15px',
    baseFontSize: '13px',
    baseSize: '24px',
    borderWidth: '1px',
    stepSize: '3px',
  },
  rightPanel: {
    /** @todo there is debt no colors should be here */
    backgroundColor: 'radial-gradient(circle at 100% 0, #48346c, #2b2040)',
    toggleButtonActiveBg: '#48346c',
    toggleButtonTextColor: '#FFFFFF',
  },
  select: {
    internalMargin: '10px',
  },
  notification: {
    width: '450px',
    zIndex: 50,
    fullHeight: '170px',
  },
  discovery: {
    sortDropDownPanelHeight: '350px',
    filtersHeight: '60px',
    editHeader: '40px',
    filterComparisonTimePeriodWidth: '300px',
    filterBaseTimePeriodWidth: '350px',
    analysisContainerWidth: '380px',
    analysisContainerSubPanelWidth: '220px',
    layout: {
      actorInterestHeight: '133px',
      gutter: '10px',
      pageHeaderLeftMargin: '274px',
      pageHeaderTextWitdh: '680px',
      pageHeaderTextWitdhSmall: '470px',
      pageContentLeftMargin: '270px',
      collapsedRowHeight: '120px',
      projectRowHeight: '100px',
      powerIndexWidth: '28%',
      publicationsWidth: '27%',
      emotionsWidth: '20%',
      consumerExpectationWidth: '25%',
      toggleArrowWidth: '40px',
      linkBlockBorder: 'rgb(54, 90, 216, 0.3)',
      containerPaddingBottom: '60px',
      expandedInfoWidth: '255px',
      projectBlockPadding: '20px',
      projectPeriodsWidth: '200px',
      projectSourcesWidth: '150px',
      projectChartsWidth: '200px',
      arrowLeftTopExpandedRow: '36px',
    },
    graph: {
      searchBarHeight: '60px',
      sliderWidth: '300px',
    },
    viz: {
      small: {
        width: '153px',
        discHeight: '10px',
      },
      big: {
        width: '232px',
        discHeight: '21px',
      },
      indicatorLineHeight: '35px',
      valueLabelSpace: '45px',
      labelValue: {
        fontWeight: 500,
        fontSize: '11px',
        lineHeight: '20px',
      },
      bound: {
        small: {
          fontWeight: 400,
          fontSize: '11px',
          start: '20px',
          end: '-40px',
        },
        big: {
          fontWeight: 400,
          fontSize: '11px',
          start: '10.5px',
          end: '-10.5px',
        },
      },
    },
    filters: {
      categoriesColors: {
        // colors picked from here : https://flatuicolors.com/palette/se
        languages: '#ef5777',
        countries: '#808e9b',
        sources: '#ffc048',
        actorTypes: '#0be881',
      },
      timePeriods: {
        maxListHeight: '500px',
      },
    },
    comparisonBarChart: {
      closeWidth: '60px',
    },
    synchroOfConv: {
      filtersHeight: '60px',
    },
    evolutionIcon: {
      angles: {
        strongDecrease: 90,
        decrease: 45,
        stable: 0,
        increase: -45,
        strongIncrease: -90,
      },
    },
    editPanel: {
      creationPage: {
        referenceCategorySelectHeight: '250px',
      },
    },
    communitiesList: {
      powerIndexHeight: '276px',
      statsHeight: '264px',
      emotionsHeight: '326px',
      lastBlockHeight: '481px',
      arrowHeight: '8px',
      pageHeaderGradientHeight: '100px',
      expandedRowBlocksMargin: '40px',
      subTitleOpacity: 0.7,
    },
    topicsList: {
      loaderHeight: '22px',
    },
  },
  projectsList: {
    pageHeaderGradientHeight: '200px',
  },
};

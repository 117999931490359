export type Props = {
  fill: string;
};

export default (props: Props) => (
  <svg {...props} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11 0.440002C5.1678 0.440002 0.440002 5.1678 0.440002 11C0.440002 16.8322 5.1678 21.56 11 21.56C16.8322 21.56 21.56 16.8322 21.56 11C21.56 5.1678 16.8322 0.440002 11 0.440002ZM15.0612 12.3541C15.0612 12.3541 15.9951 13.2759 16.225 13.7038C16.2316 13.7126 16.2349 13.7214 16.2371 13.7258C16.3306 13.8831 16.3526 14.0052 16.3064 14.0965C16.2294 14.2483 15.9654 14.3231 15.8752 14.3297H14.2252C14.1108 14.3297 13.871 14.3 13.5806 14.0998C13.3573 13.9436 13.1373 13.6873 12.9228 13.4376C12.6027 13.0658 12.3255 12.7446 12.0461 12.7446C12.0106 12.7445 11.9753 12.7501 11.9416 12.7611C11.7304 12.8293 11.4598 13.1307 11.4598 13.9337C11.4598 14.1845 11.2618 14.3286 11.1221 14.3286H10.3664C10.109 14.3286 8.7681 14.2384 7.5801 12.9855C6.1259 11.451 4.8169 8.3732 4.8059 8.3446C4.7234 8.1455 4.8939 8.0388 5.0798 8.0388H6.7463C6.9685 8.0388 7.0411 8.1741 7.0917 8.294C7.1511 8.4337 7.3689 8.9892 7.7264 9.614C8.3061 10.6326 8.6614 11.0462 8.9463 11.0462C8.99972 11.0456 9.05219 11.032 9.0992 11.0066C9.471 10.7998 9.4017 9.4743 9.3852 9.1993C9.3852 9.1476 9.3841 8.6064 9.1938 8.3468C9.0574 8.1587 8.8253 8.0872 8.6845 8.0608C8.74149 7.98216 8.81657 7.9184 8.9034 7.8749C9.1586 7.7473 9.6184 7.7286 10.0749 7.7286H10.329C10.824 7.7352 10.9516 7.7671 11.1309 7.8122C11.4939 7.8991 11.5016 8.1334 11.4697 8.9353C11.4598 9.163 11.4499 9.4204 11.4499 9.724C11.4499 9.79 11.4466 9.8604 11.4466 9.9352C11.4356 10.3433 11.4224 10.8064 11.7106 10.9967C11.7482 11.0203 11.7916 11.0328 11.836 11.033C11.9361 11.033 12.2375 11.033 13.0537 9.6327C13.3054 9.18199 13.5242 8.71363 13.7082 8.2313C13.7247 8.2027 13.7731 8.1147 13.8303 8.0806C13.8725 8.05908 13.9193 8.04813 13.9667 8.0487H15.9258C16.1392 8.0487 16.2855 8.0806 16.313 8.1631C16.3614 8.294 16.3042 8.6933 15.4099 9.9044L15.0106 10.4313C14.1999 11.4939 14.1999 11.5478 15.0612 12.3541Z"
      fill={props.fill}
    />
  </svg>
);

import { css } from '@emotion/css/macro';
import styled from '@emotion/styled/macro';

const Header = styled.div(
  ({ theme }) => css`
    padding: ${theme.header.padding};
    font-size: ${theme.header.fontSize};
    line-height: ${theme.header.lineHeight};
    color: ${theme.header.colors.text};
    background-color: ${theme.header.colors.background};
    min-height: 65px;
    font-weight: ${theme.header.fontWeight};
    border-bottom: 1px solid ${theme.header.colors.border};
    box-shadow: ${theme.header.boxShadow};
  `
);

export default Header;

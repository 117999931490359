import sentry, { LOGS_TYPE } from 'libs/sentry';
import _ from 'lodash';

import { BannerType, Body, vars } from '@bloom/ui';
import Banner from './DesignSystem/Banner/Banner';

const GraphQLErrors = ({ error, neutral }: { error: Error; neutral: boolean }) => {
  const graphQLErrors = _.get(error, 'graphQLErrors', []);
  const networkErrors = _.get(error, 'networkError.result.errors', []);
  const allErrors = _.compact(_.concat(graphQLErrors, networkErrors));

  sentry.sendLogs({ type: LOGS_TYPE.GRAPHQL, error, metadata: allErrors, message: null });

  return (
    <Banner bannerType={BannerType.Error} neutral={neutral}>
      <Body fontWeight="bold" color={'inherit'}>
        An error occured during the request:
      </Body>
      <Body fontWeight="light" color={'inherit'} style={{ wordBreak: 'break-all' }}>
        {_.map(allErrors, ({ message }, i) => {
          return (
            <Body key={i} style={{ margin: vars.space.px5, display: 'block' }} color={'inherit'}>
              {message}
            </Body>
          );
        })}
      </Body>
    </Banner>
  );
};

export { GraphQLErrors };

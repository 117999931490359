import { css } from '@emotion/css/macro';
import styled from '@emotion/styled/macro';
import { Box } from 'components';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { computeBackground } from 'theme/functions';

const CardContainer = styled(Box)(
  ({ theme, mt, mb }) => css`
    flex: 1;
    margin-bottom: ${!_.isUndefined(mb) ? mb : theme.cards.marginBottom};
    margin-top: ${!_.isUndefined(mt) ? mt : theme.cards.marginTop};
    background: ${computeBackground(theme.cards.colors.background)};
    border-radius: ${theme.cards.borderRadius};
    box-shadow: ${theme.cards.boxShadow};
    color: ${theme.cards.colors.text};
  `
);

const CardHeader = styled.div`
  font-size: 18px;
  border-bottom: 1px solid ${({ theme }) => theme.cards.colors.border};
  padding: 20px;
  padding-left: 30px;
`;

const CardBody = styled.div(
  ({ theme }) => css`
    position: relative;
    padding: ${theme.cards.padding};
  `
);

const Card = ({ headerComponent, children, ...props }) => {
  return (
    <CardContainer {...props}>
      {headerComponent && <CardHeader>{headerComponent}</CardHeader>}
      <CardBody withHeader={Boolean(headerComponent)}>{children}</CardBody>
    </CardContainer>
  );
};

Card.propTypes = {
  headerComponent: PropTypes.node,
};

export default Card;

export type Props = {
  fill: string;
};

export default (props: Props) => (
  <svg {...props} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="8" cy="7" r="2.5" stroke={props.fill} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.0003 11.9331C10.6662 11.6992 10.3012 11.5048 9.91363 11.355C9.30694 11.1205 8.6567 10.9999 8.00005 11C6.67395 11 5.40217 11.4917 4.46448 12.3668C3.52679 13.242 3 14.429 3 15.6667V16V17H4L9 17V16H4V15.6667C4 14.7173 4.40343 13.7917 5.1468 13.0979C5.8925 12.4019 6.91776 12 8.00005 12V11L8.00023 12C8.53474 11.9999 9.06261 12.0982 9.55313 12.2877C9.78642 12.3779 10.0086 12.4877 10.2171 12.6151C10.2961 12.5298 10.3785 12.447 10.4645 12.3668C10.6329 12.2096 10.812 12.0648 11.0003 11.9331Z"
      fill="white"
    />
    <circle cx="14.5" cy="8.5" r="2" stroke={props.fill} />
    <path
      d="M14.5 12.5V12L14.5001 12.5C15.0347 12.4999 15.5628 12.5909 16.0537 12.7666C16.5446 12.9423 16.9864 13.1983 17.3553 13.5171C17.7241 13.8358 18.012 14.2102 18.2068 14.6166C18.4014 15.0227 18.5001 15.4547 18.5 15.8888V15.8889V16.5H10.5V15.8889C10.5 15.0156 10.9008 14.1604 11.645 13.5173C12.3914 12.8723 13.4175 12.5 14.5 12.5Z"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

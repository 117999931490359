import type { ErrorLink } from '@apollo/client/link/error';
import type { AxiosError } from 'axios';
import sentry, { LOGS_LEVEL, LOGS_TYPE } from 'libs/sentry';

export function axiosMaintenanceRedirect(error: AxiosError) {
  if (error?.response?.status !== 307) throw error;

  const redirectURL = error?.response?.headers?.['x-redirect-url'];
  if (redirectURL) {
    window.location.assign(redirectURL);
    throw new RedirectMaintenanceError('Redirecting to maintenance page..');
  }

  sentry.sendLogs({
    error: new Error('axiosMaintenanceRedirect: No redirect URL in 307 error'),
    type: LOGS_TYPE.REACT,
    level: LOGS_LEVEL.ERROR,
  });
  window.location.assign('/');

  throw error;
}

export function apolloClientMaintenanceRedirect(networkError: NetworkError) {
  if (!networkError || !isServerError(networkError)) return;
  if (networkError.statusCode !== 307) return;

  const redirectURL = networkError?.response?.headers?.get('X-Redirect-URL');

  if (redirectURL) {
    window.location.assign(redirectURL);
    return;
  }

  sentry.sendLogs({
    error: new Error('apolloClientMaintenanceRedirect: No redirect URL in 307 error'),
    type: LOGS_TYPE.REACT,
    level: LOGS_LEVEL.ERROR,
  });

  window.location.assign('/');
}

type NetworkError = Parameters<ErrorLink.ErrorHandler>[0]['networkError'];
type ServerError = Extract<NetworkError, { statusCode: number }>;

function isServerError(error: NetworkError): error is ServerError {
  return typeof error === 'object' && 'statusCode' in error;
}

export class RedirectMaintenanceError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'RedirectError';
  }
}

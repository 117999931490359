export default (props) => (
  <svg viewBox="0 0 22 22" {...props}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <rect fillOpacity="0" fill="#FFFFFF" x="0" y="0" width="22" height="22" />
      <path
        d="M7.39644661,15.8964466 C7.20118446,16.0917088 7.20118446,16.4082912 7.39644661,16.6035534 C7.59170876,16.7988155 7.90829124,16.7988155 8.10355339,16.6035534 L14.6035534,10.1035534 C14.7988155,9.90829124 14.7988155,9.59170876 14.6035534,9.39644661 L11.1035534,5.89644661 C10.9082912,5.70118446 10.5917088,5.70118446 10.3964466,5.89644661 C10.2011845,6.09170876 10.2011845,6.40829124 10.3964466,6.60355339 L13.5428932,9.75 L7.39644661,15.8964466 Z"
        id="Path-26"
        fill="#FFFFFF"
        transform="translate(11.000000, 11.250000) scale(1, -1) rotate(-90.000000) translate(-11.000000, -11.250000) "
      />
    </g>
  </svg>
);

import { css } from '@emotion/css/macro';
import styled from '@emotion/styled/macro';
import { omit } from 'lodash';
import ReactTooltip from 'react-tooltip';

/**
 * Component you hover to display the tooltip (TooltipContent)
 */
const TooltipTrigger = (props) => {
  const { targetId, children } = props;
  return (
    <span data-tip data-for={targetId} {...omit(props, ['targetId'])} className="TooltipTrigger">
      {children}
    </span>
  );
};

/**
 * Content of the tooltip
 */
const TooltipContentContainer = styled('div')(
  ({ theme, maxWidth, bgColor, keepOnHover, textAlign }) => {
    const backgroundColor = bgColor || theme.primaryColor;

    return css`
      line-height: ${theme.paragraph2.lineHeight};
      pointer-events: ${keepOnHover ? 'auto' : 'none'};
      text-align: ${textAlign === 'left' ? 'left' : textAlign === 'right' ? 'right' : 'center'};
      z-index: 1001;

      .__react_component_tooltip.type-dark {
        pointer-events: ${keepOnHover ? 'auto' : 'none'};
        min-width: 230px;
        max-width: ${maxWidth};
        opacity: 1;
        box-shadow: 0 30px 20px rgba(0, 0, 0, 0.15);
        border-radius: 4px;
        padding: 18px 24px 15px 24px;
        background-color: ${backgroundColor};
        ${keepOnHover &&
        css`
          &:hover {
            visibility: visible !important;
            opacity: 1 !important;
          }
        `}
      }
      .__react_component_tooltip.type-dark.place-left::after {
        border-left: 8px solid ${backgroundColor};
      }

      .__react_component_tooltip.type-dark.place-right::after {
        border-right: 8px solid ${backgroundColor};
      }

      .__react_component_tooltip.type-dark.place-top::after {
        border-top: 8px solid ${backgroundColor};
      }

      .__react_component_tooltip.type-dark.place-bottom::after {
        border-bottom: 8px solid ${backgroundColor};
      }
    `;
  }
);
const TooltipContent = ({
  bgColor,
  children,
  id = null,
  keepOnHover = false,
  maxWidth = '250px',
  offset = {},
  place = 'top',
  textAlign = 'left',
}) => {
  return (
    <TooltipContentContainer
      maxWidth={maxWidth}
      bgColor={bgColor}
      keepOnHover={keepOnHover}
      textAlign={textAlign}
    >
      <ReactTooltip
        effect="solid"
        place={place}
        id={id}
        offset={offset}
        delayHide={keepOnHover ? 500 : 0}
      >
        {children}
      </ReactTooltip>
    </TooltipContentContainer>
  );
};

export default TooltipContent;
export { TooltipTrigger };

import type { CSSObject } from '@emotion/styled/macro';
import styled from '@emotion/styled/macro';
import type { Theme } from 'theme/theme';

// Paragraphs from largest font-size to smaller, from bold to normal font-weight
// All new paragraph should have line-height

const Paragraph1 = styled.div(({ theme }: { theme: Theme }) => theme.paragraph1 as CSSObject);

const Paragraph2 = styled.div(({ theme }: { theme: Theme }) => theme.paragraph2 as CSSObject);

const Paragraph3 = styled.div(({ theme }: { theme: Theme }) => theme.paragraph3 as CSSObject);

const Paragraph4 = styled.div(({ theme }: { theme: Theme }) => theme.paragraph4 as CSSObject);

const Paragraph5 = styled.div(({ theme }: { theme: Theme }) => theme.paragraph5 as CSSObject);

const Paragraph6 = styled.div(({ theme }: { theme: Theme }) => theme.paragraph6 as CSSObject);

const Paragraph7 = styled.div(({ theme }: { theme: Theme }) => theme.paragraph7 as CSSObject);

const ActionParagraph = styled(Paragraph3)`
  cursor: pointer;
  color: ${({ theme }) => theme.accentColor};

  &:hover {
    text-decoration: underline;
  }
`;

export {
  Paragraph1,
  Paragraph2,
  Paragraph3,
  ActionParagraph,
  Paragraph4,
  Paragraph5,
  Paragraph6,
  Paragraph7,
};

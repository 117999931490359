import { css } from '@emotion/css/macro';
import styled from '@emotion/styled/macro';
import { Paragraph2 } from 'components/text/Paragraph';
import _ from 'lodash';
import propTypes from 'prop-types';
import { useEffect } from 'react';

import wording from './wording.json';

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const Option = styled(Paragraph2)(
  ({ theme, isSelected }) => css`
    color: ${isSelected ? theme.mainColor : theme.mainColorLight};
    cursor: pointer;
    padding-bottom: 3px;
    border-bottom: ${isSelected ? `1px solid ${theme.mainColor}` : 'none'};
  `
);
const Separator = styled.div`
  height: 10px;
  width: 1px;
  background-color: ${({ theme }) => theme.mainColor};
  margin: 0 10px;
`;

const OnOffSwitch = ({ toggle, setOn, setOff, isOn }) => {
  useEffect(() => {
    if (!_.isNil(toggle) && (!_.isNil(setOn) || !_.isNil(setOff))) {
      throw new Error('OnOffSwitch : you must choose one between toggle and setOn/setOff');
    }
  }, [toggle, setOn, setOff]);

  const onOnClick = () => {
    if (toggle) toggle();
    if (setOn) setOn();
  };

  const onOffClick = () => {
    if (toggle) toggle();
    if (setOff) setOff();
  };

  return (
    <Container>
      <Option onClick={onOnClick} isSelected={isOn}>
        {wording.on}
      </Option>
      <Separator />
      <Option onClick={onOffClick} isSelected={!isOn}>
        {wording.off}
      </Option>
    </Container>
  );
};

OnOffSwitch.propTypes = {
  toggle: propTypes.func,
  setOn: propTypes.func,
  setOff: propTypes.func,
};

export default OnOffSwitch;

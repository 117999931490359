import commonComponentsValues from './componentsValues';
import commonValues from './values';

const common = {
  ...commonValues,
  ...commonComponentsValues,

  utils: {
    hideScrollBar: `
      scrollbar-width: none; /* Firefox */
      -ms-overflow-style: none; /* IE 10+ */
      &::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
      }
    `,
  },
};

export default common;

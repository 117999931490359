export type CloseAltProps = {
  fill: string;
  strokeWidth?: number;
};

export default (props: CloseAltProps) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    {...props}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 11L6 6M6 6L1 1M6 6L11 11M6 6L11 1"
      stroke={props.fill}
      strokeWidth={props.strokeWidth ? props.strokeWidth : '1.2'}
    />
  </svg>
);

import { setAnalytics } from 'libs/usage-analytics';
import _ from 'lodash';
import { createContext, useCallback, useEffect, useReducer, useState } from 'react';
import safeUseContext from 'shared/safeUseContext';

const MAX_COOKIES_PREFS_LIFETIME_MS = 1000 * 3600 * 24 * 365; // one year

const initialState = {
  analytics: null,
};

const CookiesPrefPanelContext = createContext();
const useCookiesPrefPanel = () =>
  safeUseContext('CookiesPrefPanelContext', CookiesPrefPanelContext);

const reducer = (state, updates) => {
  const newState = {
    ...state,
    ...updates,
    timeStamp: updates.timeStamp || state.timeStamp || new Date(),
  };
  localStorage.setItem('cookiesPrefs', JSON.stringify(newState));
  // update appropriate services
  if (!_.isNil(updates.analytics)) {
    setAnalytics(newState.analytics);
  }
  return newState;
};

const prefOutdated = (prefs) =>
  new Date().getTime() - new Date(prefs.timeStamp).getTime() > MAX_COOKIES_PREFS_LIFETIME_MS;

/**
 * Update the state of the panel
 */
const CookiesPrefPanelProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [cookiesPrefs, dispatchCookiesPrefs] = useReducer(reducer, initialState);

  const open = useCallback(() => setIsOpen(true), []);
  const close = useCallback(() => setIsOpen(false), []);

  useEffect(() => {
    const storedPrefs = localStorage.getItem('cookiesPrefs');
    const parsedPrefs = storedPrefs && JSON.parse(storedPrefs);
    if (_.isNil(parsedPrefs) || prefOutdated(parsedPrefs)) {
      open();
    } else {
      dispatchCookiesPrefs(parsedPrefs);
    }
  }, [open]);

  return (
    <CookiesPrefPanelContext.Provider
      value={{
        isOpen,
        open,
        close,
        cookiesPrefs,
        dispatchCookiesPrefs,
      }}
    >
      {children}
    </CookiesPrefPanelContext.Provider>
  );
};

export default useCookiesPrefPanel;
export { CookiesPrefPanelProvider };

import color from 'color';
import LABELS from 'routes/Projects/Project/DataViz/shared/labels';
import commonComponentsValues from 'theme/common/componentsValues';
import { lighten } from 'theme/functions';

import cobaltValues from './values';

export default {
  tabs: {
    height: '60px',
  },
  scrollbar: {
    ...commonComponentsValues.scrollbar,
    cursorColor: cobaltValues.primaryColorLight,
    backgroundColor: cobaltValues.primaryColorDarkUltra,
  },
  navLinearProgress: {
    ...commonComponentsValues.navLinearProgress,
    color: cobaltValues.primaryColorLight,
  },
  menu: {
    ...commonComponentsValues.menu,
    boxShadow: '20px 20px 40px rgba(7, 8, 33, 0.5)',
  },
  CookiesPrefPanel: {
    ...commonComponentsValues.CookiesPrefPanel,
    boxShadow: '-20px 20px 40px rgba(7, 8, 33, 0.5)',
    overlayBackground: 'rgba(7, 8, 33, 0.7)',
  },
  discovery: {
    ...commonComponentsValues.discovery,
    graph: {
      linksColorPrimary: 0x5f6b91,
      linksColorAccent: 0x595b85,
      linksOpacityDefault: 0.5,
      ...commonComponentsValues.discovery.graph,
      circleBackground: '#39F2CB',
      selectedItem: '#ff7f00',
      outsider: '#F95385',
      modeling: '#FFDD33',
    },
    viz: {
      ...commonComponentsValues.discovery.viz,
      discColor: 'white',
      pathStroke: 'white',
      gradientColors: {
        stop0: '#FF2365',
        stop1: '#D47CFF',
        stop2: '#5FBAFF',
      },
    },
    filters: {
      ...commonComponentsValues.discovery.filters,
    },
  },
  login: {
    background: cobaltValues.primaryColorDark,
    borderRadius: 0,
  },
  formGroup: {
    marginBottom: '30px',
  },
  labels: {
    fontSize: '11px',
    textTransform: 'uppercase',
    fontStyle: 'normal',
    letterSpacing: '0.82px',
    marginBottom: '5px',
    color: cobaltValues.mainColorLight,
  },
  inputs: {
    borderRadius: 0,
    padding: 0,
    fontSize: '13px',
    fontWeight: 400,
    height: '30px',
    border: 'none',
    borderBottom: '1px solid rgba(255, 255, 255, 0.2)',
    placeholder: {
      disabled: cobaltValues.mainColorLightDisabled,
      fontStyle: 'italic',
      text: cobaltValues.mainColorLight,
    },
    colors: {
      background: 'transparent',
      disabled: cobaltValues.mainColorLightDisabled,
      text: cobaltValues.mainColor,
    },
  },
  buttons: {
    primary: {
      color: cobaltValues.primaryColorDarkUltra,
      border: 'transparent',
      background: cobaltValues.secondaryColorLight,
      hover: {
        color: cobaltValues.oppositeColor,
        border: 'transparent',
        background: cobaltValues.secondaryColor,
      },
      active: {
        color: cobaltValues.oppositeColor,
        border: cobaltValues.oppositeColor,
        background: cobaltValues.secondaryColor,
      },
    },
    secondary: {
      color: cobaltValues.oppositeColor,
      border: 'transparent',
      background: cobaltValues.accentColorDark,
      hover: {
        color: cobaltValues.secondaryColorDark,
        border: 'transparent',
        background: cobaltValues.oppositeColor,
      },
      active: {
        color: cobaltValues.secondaryColorDark,
        border: cobaltValues.oppositeColor,
        background: cobaltValues.oppositeColor,
      },
    },
    secondaryLight: {
      color: color(cobaltValues.oppositeColor).alpha(cobaltValues.opacity).toString(),
      border: 'transparent',
      background: cobaltValues.primaryColor,
      hover: {
        color: color(cobaltValues.secondaryColorDark).alpha(cobaltValues.opacity).toString(),
        border: 'transparent',
        background: cobaltValues.oppositeColor,
      },
      active: {
        color: cobaltValues.secondaryColorDark,
        border: cobaltValues.oppositeColor,
        background: cobaltValues.oppositeColor,
      },
    },
    success: {
      color: '#ffffff',
      border: '#6bc996',
      background: '#6bc996',
      hover: {
        color: lighten('#ffffff', 5),
        border: lighten('#6bc996', 5),
        background: lighten('#6bc996', 5),
      },
      active: {
        color: lighten('#ffffff', -5),
        border: lighten('#6bc996', -5),
        background: lighten('#6bc996', -5),
      },
    },
    danger: {
      color: '#ffffff',
      border: '#f25757',
      background: '#f25757',
      hover: {
        color: lighten('#ffffff', 5),
        border: lighten('#f25757', 5),
        background: lighten('#f25757', 5),
      },
      active: {
        color: lighten('#ffffff', -5),
        border: lighten('#f25757', -5),
        background: lighten('#f25757', -5),
      },
    },
    info: {
      color: '#ffffff',
      border: '#515979',
      background: '#515979',
      hover: {
        color: lighten('#ffffff', 5),
        border: lighten('#515979', 5),
        background: lighten('#515979', 5),
      },
      active: {
        color: lighten('#ffffff', -5),
        border: lighten('#515979', -5),
        background: lighten('#515979', -5),
      },
    },
  },
  cards: {
    borderRadius: '2px',
    padding: 0,
    marginTop: 0,
    marginRight: 0,
    marginBottom: '15px',
    marginLeft: 0,
    boxShadow: 'none',
    header: {
      background: cobaltValues.primaryColorMedium,
    },
    colors: {
      text: cobaltValues.mainColor,
      border: cobaltValues.primaryColor,
      outerBorder: 'transparent',
      background: cobaltValues.primaryColorDark,
      hover: {
        border: cobaltValues.primaryColorLight,
        background: cobaltValues.primaryColorMedium,
      },
    },
  },
  header: {
    fontSize: '21px',
    lineHeight: '28px',
    fontWeight: 400,
    boxShadow: 'none',
    padding: '15px 30px',
    colors: {
      text: cobaltValues.oppositeColor,
      border: 'transparent',
      background: 'transparent',
    },
  },
  colors: {
    text: cobaltValues.mainColor,
    subText: cobaltValues.mainColorLight,
    background: cobaltValues.mainColor,
    alternateBackground: cobaltValues.primaryColor,
    dashboardFiltersBg: cobaltValues.primaryColorDark,
    spacerBorderColor: '#d9d9d9',
    spacerArrowColor: '#ffffff',
  },
  documentList: {
    colors: {
      border: 'rgba(255, 255, 255, 0.2)',
      text: cobaltValues.mainColor,
      subText: cobaltValues.mainColorLight,
      externalLink: cobaltValues.mainColorLight,
      arrow: cobaltValues.mainColor,
      childrenBackground: cobaltValues.primaryColorMedium,
      childrenHover: cobaltValues.primaryColorMedium,
      tableHeadBackground: cobaltValues.primaryColor,
      tableHead: cobaltValues.mainColor,
    },
  },
  charts: {
    colors: {
      label: '#ffffff',
      scale: [cobaltValues.secondaryColorLight, cobaltValues.secondaryColorDark],
      selected: 'rgba(45,33,68,.2)',
      bars: {
        background: color(cobaltValues.primaryColorMedium)
          .alpha(cobaltValues.lightOpacity)
          .toString(),
      },
      tickBackground: color(cobaltValues.primaryColorLight)
        .alpha(cobaltValues.superLightOpacity)
        .toString(),
      emotions: {
        negative: '#db5353',
        positive: '#447c5f',

        [LABELS.NOT_CLASSIFIED]: '#999999',
        confiance: '#BED09B',
        joie: '#FFE455',
        anticipation: '#F4D1BB',
        colère: '#FA9D74',
        déception: '#D3C9E6',
        tristesse: '#747A9C',
        peur: '#6E987E',
        surprise: '#BDDCEE',

        scales: {
          [LABELS.NOT_CLASSIFIED]: ['#586289', '#C2CBEB'],
          joie: ['#64a684', '#447c5f'],
          confiance: ['#64a684', '#447c5f'],
          anticipation: ['#dac9ff', '#9f76f3'],
          surprise: ['#dac9ff', '#9f76f3'],
          colère: ['#f89797', '#db5353'],
          déception: ['#f89797', '#db5353'],
          tristesse: ['#f89797', '#db5353'],
          peur: ['#f89797', '#db5353'],
        },
      },
    },
  },
  exportBtn: {
    colors: {
      border: cobaltValues.mainColorLight,
      icon: cobaltValues.mainColorLight,
    },
  },
  spiner: {
    color: cobaltValues.mainColorLight,
  },
  communitiesList: {
    expandedCommunitiesDefaultSeparatorColor: '#243188',
  },
  editor: {
    status: {
      valid: {
        color: '#78FF96',
        iconSize: 12,
      },
      invalid: {
        color: '#F95385',
        iconSize: 10,
      },
      duplicate: {
        color: '#FB6A57',
        iconSize: 12,
      },
      warning: {
        color: '#FFD569',
        iconSize: 15,
      },
      empty: {
        iconSize: 12,
      },
    },
  },
  multiSelect: {
    title: {
      color: '#9999B0',
    },
    value: {
      color: '#5F59FF',
    },
    option: {
      color: '#FFFFFF',
      fontSize: '14px',
      lineHeight: '17px',
      hoverBarColor: cobaltValues.accentColorDark,
      checkboxColor: cobaltValues.accentColorDark,
    },
  },
  contextMenu: {
    checkBoxItem: {
      hoverBarColor: cobaltValues.accentColorDark,
      checkBoxColor: cobaltValues.accentColorDark,
      disabledCheckBoxColor: cobaltValues.mainColorLightDisabled,
    },
  },
};

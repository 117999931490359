import styled from '@emotion/styled/macro';
import { Box, Icon } from 'components';
import type { FC } from 'react';

const Container = styled('div')({
  cursor: 'pointer',
  marginRight: 20,
  userSelect: 'none',
});

const IconWrapper = styled('div')({
  alignItems: 'center',
  border: '1px solid #BFBFBF',
  borderRadius: 16,
  display: 'flex',
  height: 30,
  justifyContent: 'center',
  width: 30,
});

type ExpandButtonProps = {
  toggleExpand: () => void;
  isExpanded: boolean;
};

const ExpandButton: FC<ExpandButtonProps> = ({ toggleExpand, isExpanded }) => {
  return (
    <Container>
      <IconWrapper onClick={toggleExpand}>
        <Box width={14} height={9} display="flex" alignItems="center" justifyContent="center">
          <Icon
            width={12}
            height={12}
            type={isExpanded ? 'arrow-up' : 'arrow-down'}
            color="#F3C600"
          />
        </Box>
      </IconWrapper>
    </Container>
  );
};

export default ExpandButton;

export default (props) => (
  <svg viewBox="0 0 22 22" {...props}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <rect id="Rectangle" fillOpacity="0" fill={props.fill} x="0" y="0" width="22" height="22" />
      <path
        d="M16.5,16.5 L16.5,14 C16.5,13.7238576 16.7238576,13.5 17,13.5 C17.2761424,13.5 17.5,13.7238576 17.5,14 L17.5,17 C17.5,17.2761424 17.2761424,17.5 17,17.5 L5,17.5 C4.72385763,17.5 4.5,17.2761424 4.5,17 L4.5,14 C4.5,13.7238576 4.72385763,13.5 5,13.5 C5.27614237,13.5 5.5,13.7238576 5.5,14 L5.5,16.5 L16.5,16.5 Z M11.5,12.7928932 L13.6464466,10.6464466 C13.8417088,10.4511845 14.1582912,10.4511845 14.3535534,10.6464466 C14.5488155,10.8417088 14.5488155,11.1582912 14.3535534,11.3535534 L11.3535534,14.3535534 C11.1582912,14.5488155 10.8417088,14.5488155 10.6464466,14.3535534 L7.64644661,11.3535534 C7.45118446,11.1582912 7.45118446,10.8417088 7.64644661,10.6464466 C7.84170876,10.4511845 8.15829124,10.4511845 8.35355339,10.6464466 L10.5,12.7928932 L10.5,4.5 C10.5,4.22385763 10.7238576,4 11,4 C11.2761424,4 11.5,4.22385763 11.5,4.5 L11.5,12.7928932 Z"
        fill={props.fill}
      />
    </g>
  </svg>
);

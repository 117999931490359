export default (props) => (
  <svg viewBox="0 0 22 22" {...props}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <rect fillOpacity="0" fill="#FFFFFF" x="0" y="0" width="22" height="22" />
      <path
        d="M10.7071068,11 L18,11 L18,12 L10.7071068,12 L14.8535534,16.1464466 C15.0488155,16.3417088 15.0488155,16.6582912 14.8535534,16.8535534 C14.6582912,17.0488155 14.3417088,17.0488155 14.1464466,16.8535534 L9.14644661,11.8535534 C8.95118446,11.6582912 8.95118446,11.3417088 9.14644661,11.1464466 L14.1464466,6.14644661 C14.3417088,5.95118446 14.6582912,5.95118446 14.8535534,6.14644661 C15.0488155,6.34170876 15.0488155,6.65829124 14.8535534,6.85355339 L10.7071068,11 Z M6,6 L6,17 L5,17 L5,6 L6,6 Z"
        id="Combined-Shape"
        fill="#FFFFFF"
      />
    </g>
  </svg>
);

export type Props = {
  stroke: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};
export default ({ stroke, ...props }: Props) => (
  <svg viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect
      x="10.4629"
      y="5.5"
      width="6.94764"
      height="6.94764"
      transform="rotate(135 10.4629 5.5)"
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

import LABELS from 'routes/Projects/Project/DataViz/shared/labels';
import commonComponentsValues from 'theme/common/componentsValues';
import { lighten } from 'theme/functions';

import lightValues from './values';

export default {
  tabs: {
    height: '60px',
  },
  scrollbar: {
    ...commonComponentsValues.scrollbar,
    cursorColor: lightValues.blueGrayColor,
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
  },
  navLinearProgress: {
    ...commonComponentsValues.navLinearProgress,
    color: '#3657CF',
  },
  menu: {
    ...commonComponentsValues.menu,
    boxShadow: '20px 20px 40px rgba(7, 8, 33, 0.5)',
  },
  discovery: {
    ...commonComponentsValues.discovery,
    filters: {
      ...commonComponentsValues.discovery.filters,
    },
  },
  dropdowns: {
    colors: {
      options: {
        label: '#8a8a8a',
        badge: '#8a8a8a',
      },
      selection: {
        label: '#8A8A8A',
        badge: '#8A8A8A',
      },
      placeholder: '#536f87',
      arrow: '#536f87',
    },
  },
  login: {
    background: 'white',
    borderRadius: 4,
  },
  formGroup: {
    marginBottom: '1.5rem',
  },
  labels: {
    fontSize: '13px',
    textTransform: 'inital',
    fontStyle: 'italic',
    letterSpacing: 0,
    marginBottom: '5px',
    color: lightValues.fontColor,
  },
  inputs: {
    borderRadius: '17.5px',
    padding: '10px 15px',
    fontSize: '14px',
    fontWeight: 400,
    height: '36px',
    border: '1px solid #d9d9d9',
    borderBottom: '1px solid #d9d9d9',
    placeholder: {
      text: lightValues.secondary,
      fontStyle: 'italic',
    },
    colors: {
      text: 'black',
      background: 'white',
      placeholder: '#536f87',
    },
  },
  cards: {
    borderRadius: '6px',
    padding: '20px',
    marginTop: '15px',
    marginRight: '15px',
    marginBottom: '15px',
    marginLeft: '15px',
    boxShadow: '0 1px 10px rgba(0, 0, 0, 0.1)',
    header: {
      marginBottom: '30px',
      background: 'none',
      textColor: '#c3c3c3',
    },
    colors: {
      text: '#030619',
      border: '#f2f2f2',
      outerBorder: '#f2f2f2',
      background: '#ffffff',
      hover: {
        border: '#f2f2f2',
        background: '#ffffff',
      },
    },
  },
  header: {
    fontSize: '24px',
    lineHeight: '28px',
    fontWeight: 700,
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.1)',
    padding: '16px',
    colors: {
      text: '#030619',
      border: '#d9d9d9',
      background: '#ffffff',
    },
  },
  buttons: {
    primary: {
      color: '#ffffff',
      border: '#f3c600',
      background: '#f3c600',
      hover: {
        color: lighten('#ffffff', 5),
        border: lighten('#f3c600', 5),
        background: lighten('#f3c600', 5),
      },
      active: {
        color: lighten('#ffffff', -5),
        border: lighten('#f3c600', -5),
        background: lighten('#f3c600', -5),
      },
    },
    secondary: {
      color: '#515979',
      border: '#d9d9d9',
      background: '#ffffff',
      hover: {
        color: lighten('#515979', 20),
        border: lighten('#d9d9d9', 5),
        background: lighten('#ffffff', 5),
      },
      active: {
        color: lighten('#515979', -5),
        border: lighten('#d9d9d9', -5),
        background: lighten('#ffffff', -5),
      },
    },
    success: {
      color: '#ffffff',
      border: '#6bc996',
      background: '#6bc996',
      hover: {
        color: lighten('#ffffff', 5),
        border: lighten('#6bc996', 5),
        background: lighten('#6bc996', 5),
      },
      active: {
        color: lighten('#ffffff', -5),
        border: lighten('#6bc996', -5),
        background: lighten('#6bc996', -5),
      },
    },
    danger: {
      color: '#ffffff',
      border: '#f25757',
      background: '#f25757',
      hover: {
        color: lighten('#ffffff', 5),
        border: lighten('#f25757', 5),
        background: lighten('#f25757', 5),
      },
      active: {
        color: lighten('#ffffff', -5),
        border: lighten('#f25757', -5),
        background: lighten('#f25757', -5),
      },
    },
    info: {
      color: '#ffffff',
      border: '#515979',
      background: '#515979',
      hover: {
        color: lighten('#ffffff', 5),
        border: lighten('#515979', 5),
        background: lighten('#515979', 5),
      },
      active: {
        color: lighten('#ffffff', -5),
        border: lighten('#515979', -5),
        background: lighten('#515979', -5),
      },
    },
  },
  charts: {
    colors: {
      label: '#030619',
      scale: ['#C2CBEB', '#586289', '#737373', '#969696', '#bdbdbd', '#d9d9d9', '#f0f0f0'],
      selected: 'rgba(45,33,68,.2)',
      bars: {
        background: '#F2F2F2',
      },
      tickBackground: 'rgba(255, 255, 255, 0.10)',
      emotions: {
        negative: '#fa6657',
        positive: '#bed09b',

        [LABELS.NOT_CLASSIFIED]: '#999999',
        confiance: '#BED09B',
        joie: '#FFE455',
        anticipation: '#F4D1BB',
        colère: '#FA9D74',
        déception: '#D3C9E6',
        tristesse: '#747A9C',
        peur: '#6E987E',
        surprise: '#BDDCEE',

        scales: {
          [LABELS.NOT_CLASSIFIED]: ['#586289', '#C2CBEB'],
          joie: ['#F7C400', '#FFF6C1'],
          confiance: ['#638531', '#E1EDCF'],
          anticipation: ['#EF986A', '#FDE8DA'],
          surprise: ['#598BA3', '#CDEBFA'],
          colère: ['#F84C00', '#FFC0A5'],
          déception: ['#8466B0', '#E4DBF2'],
          tristesse: ['#34395C', '#A8ADC5'],
          peur: ['#3F644F', '#9AC6AE'],
        },
      },
    },
  },
  documentList: {
    colors: {
      border: '#d9d9d9',
      text: '#030619',
      subText: '#536f87',
      externalLink: '#f8c32c',
      arrow: '#030619',
      childrenBackground: 'transparent',
      childrenHover: '#FFF9DB',
      tableHeadBackground: '#f5f5f5',
      tableHead: '#999999',
    },
  },
  pagination: {
    colors: {
      text: '#f3c600',
      background: '#ffffff',
      border: '#d9d9d9',
    },
  },
  select: {
    colors: {
      text: '#030619',
      arrow: '#f3c600',
      background: '#ffffff',
      border: '#d9d9d9',
    },
  },
  exportBtn: {
    colors: {
      border: '#bfbfbf',
      icon: '#f3c600',
    },
  },
  colors: {
    text: '#030619',
    subText: '#536f87',
    background: '#f5f5f5',
    alternateBackground: '#f5f5f5',
    dashboardFiltersBg: '#483464',
    spacerBorderColor: '#d9d9d9',
    spacerArrowColor: '#ffffff',
  },
  spiner: {
    color: '#9e9e9e',
  },
};

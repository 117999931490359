import amplitude from 'amplitude-js/amplitude';
import { getConfig } from 'config/config-service';
import hotjar from 'libs/hotjar';
import _ from 'lodash';

/** keep track of events fired prior to accept cookies and send them afterwards if the user accepts cookies */
let analyticsEventsStack = [];

let analyticsEnabled = false;
const setAnalytics = (enabled) => {
  analyticsEnabled = enabled;
  if (!enabled) {
    sendAnalyticsData('disabled analytics');
    return;
  }

  initAnalytics();
  setAnalyticsUserDevice();
  setAnalyticsUserId();
  setAnalyticsUserProperties();
  analyticsEventsStack.forEach(({ eventType, eventProperties }) =>
    sendAnalyticsData(eventType, eventProperties)
  );
  analyticsEventsStack = [];
};

const initAnalytics = () => {
  if (!analyticsEnabled) return;
  const config = getConfig();
  amplitude.getInstance().init(config.AMPLITUDE_ANALYTICS_API_KEY);

  if (config.HOTJAR_HJID && config.HOTJAR_HJSV) {
    hotjar.initialize(config.HOTJAR_HJID, config.HOTJAR_HJSV);
  }
};

let installationTokenMemo;
const setAnalyticsUserDevice = (installationToken = null) => {
  if (!_.isNil(installationToken)) installationTokenMemo = installationToken;
  if (!analyticsEnabled) return;

  const installationTokenData = _.isNil(installationToken)
    ? installationTokenMemo
    : installationToken;
  if (!_.isNil(installationTokenData)) amplitude.getInstance().setDeviceId(installationTokenData);
};

let userIdMemo;
const setAnalyticsUserId = (userId = null) => {
  if (!_.isNil(userId)) userIdMemo = userId;
  if (!analyticsEnabled) return;

  const userIdData = _.isNil(userId) ? userIdMemo : userId;
  if (!_.isNil(userIdData)) amplitude.getInstance().setUserId(userIdData);
};

let userPropertiesMemo;
const setAnalyticsUserProperties = (properties = null) => {
  if (!_.isNil(properties)) userPropertiesMemo = properties;
  if (!analyticsEnabled) return;

  const propertiesData = _.isNil(properties) ? userPropertiesMemo : properties;
  if (!_.isNil(propertiesData)) amplitude.getInstance().setUserProperties(propertiesData);
};

const sendAnalyticsData = (eventType, eventProperties = null) => {
  if (analyticsEnabled) {
    amplitude.getInstance().logEvent(eventType, eventProperties);
    return;
  }
  if (_.size(analyticsEventsStack) < 20) {
    analyticsEventsStack.push({ eventType, eventProperties });
  }
};

export {
  setAnalytics,
  setAnalyticsUserDevice,
  setAnalyticsUserId,
  setAnalyticsUserProperties,
  sendAnalyticsData,
};

import _ from 'lodash';

export const computeBackground = (bg) => {
  if (!bg || (_.isArray(bg) && _.size(bg) !== 3) || (!_.isString(bg) && !_.isArray(bg))) {
    throw new Error(
      `computeBackground only accepts a string OR an array of 3 entries, you passed ${JSON.stringify(
        bg
      )}.`
    );
  }

  return _.isArray(bg) ? `linear-gradient(${bg[0]}, ${bg[1]}, ${bg[2]})` : bg;
};

export const lighten = (color, percent) => {
  let num = parseInt(color.split('#')[1], 16),
    amt = Math.round(2.55 * percent),
    R = (num >> 16) + amt,
    B = ((num >> 8) & 0x00ff) + amt,
    G = (num & 0x0000ff) + amt;

  return (
    '#' +
    (
      0x1000000 +
      (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
      (B < 255 ? (B < 1 ? 0 : B) : 255) * 0x100 +
      (G < 255 ? (G < 1 ? 0 : G) : 255)
    )
      .toString(16)
      .slice(1)
  );
};

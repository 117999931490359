export default (props) => (
  <svg viewBox="0 0 27 11" {...props}>
    <g
      stroke="none"
      strokeWidth="1"
      fill={props.fill}
      fillRule="evenodd"
      transform="translate(0.000000, -3.000000)"
    >
      <path
        d="M11,8.5 C11,11.5375889 8.53758889,14 5.5,14 C2.46241111,14 0,11.5375889 0,8.5 C0,5.46241111 2.46241111,3 5.5,3 C8.53758889,3 11,5.46241111 11,8.5 Z"
        transform="translate(5.500000, 8.500000) rotate(-90.000000) translate(-5.500000, -8.500000) "
      />
      <path
        d="M27,8.5 C27,11.5375889 24.5375889,14 21.5,14 C18.4624111,14 16,11.5375889 16,8.5 C16,5.46241111 18.4624111,3 21.5,3 C24.5375889,3 27,5.46241111 27,8.5 Z"
        transform="translate(21.500000, 8.500000) rotate(-90.000000) translate(-21.500000, -8.500000) "
      />
      <path d="M8.5,8.5 L18.4997224,8.5" fill="none" stroke={props.fill} strokeLinecap="square" />
    </g>
  </svg>
);

export default (props) => (
  <svg {...props} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle stroke={props.fill} cx="13.4738" cy="13.6842" r="2.18421" />
    <path
      stroke={props.fill}
      d="M17.4474 21.4386V22.1316H9.5V21.4386C9.5 20.4754 9.90965 19.544 10.6515 18.8517C11.3945 18.1582 12.4091 17.7632 13.4737 17.7632V17.2632L13.4738 17.7632C14.0002 17.7631 14.5208 17.8598 15.0056 18.0472C15.4903 18.2345 15.9285 18.5083 16.2961 18.8514C16.6637 19.1945 16.9532 19.5998 17.15 20.0434C17.3468 20.4869 17.4475 20.9608 17.4474 21.4385V21.4386Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fill={props.fill}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.5999 3.96558V6.39989H13.9999C18.199 6.39989 21.5999 9.80081 21.5999 13.9999C21.5999 15.3102 21.2626 16.5397 20.6776 17.6119L19.7883 16.7226C20.1837 15.898 20.3999 14.9694 20.3999 13.9999C20.3999 10.469 17.5308 7.59989 13.9999 7.59989H13.5999V10.0342L10.5656 6.99989L13.5999 3.96558ZM6.32219 11.3879L7.21151 12.2772C6.81615 13.1018 6.5999 14.0303 6.5999 14.9999C6.5999 16.3059 6.99284 17.5222 7.6665 18.5356L6.96211 19.6177C5.98193 18.3379 5.3999 16.7376 5.3999 14.9999C5.3999 13.6896 5.73716 12.4601 6.32219 11.3879Z"
    />
  </svg>
);

import _ from 'lodash';
import { createContext, useCallback, useState } from 'react';
import safeUseContext from 'shared/safeUseContext';

const NOTIFICATION_TYPES = { GLOBAL_INFO: 'globalInfo', PRECISION_INFO: 'precisionInfo' };

const NotificationContext = createContext();
const useNotification = () => safeUseContext('Notification', NotificationContext);

const NotificationProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);

  const setNotification = useCallback(
    ({ type = NOTIFICATION_TYPES.GLOBAL_INFO, ...notification }) => {
      const notifs = [...notifications];
      const index = _.findIndex(notifs, { type });
      if (index !== -1) notifs[index] = { ...notification, type, timestamp: new Date() };
      else notifs.push({ ...notification, type, timestamp: new Date() });
      setNotifications(notifs);
    },
    [notifications]
  );

  const closeNotification = useCallback(
    (type) => {
      const notifs = _.filter(notifications, (notif) => notif.type !== type);
      setNotifications(notifs);
    },
    [notifications]
  );

  return (
    <NotificationContext.Provider
      value={{
        notifications,
        setNotification,
        closeNotification,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export default useNotification;
export { NotificationProvider, NOTIFICATION_TYPES };

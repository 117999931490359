export default (props) => (
  <svg viewBox="0 0 22 22" {...props} xmlns="http://www.w3.org/2000/svg">
    <circle stroke={props.fill} fill="none" cx="11.0529" cy="9.55262" r="2.18421" />
    <path
      opacity="0.4"
      d="M15.262 6.02364C15.9672 6.88818 16.3928 7.94672 16.4823 9.05878C16.5718 10.1708 16.321 11.2838 15.7631 12.25"
      stroke={props.fill}
      fill="none"
    />
    <path
      stroke={props.fill}
      fill="none"
      opacity="0.4"
      d="M6.73797 6.02364C6.0328 6.88818 5.60723 7.94672 5.51773 9.05878C5.42823 10.1708 5.67903 11.2838 6.23686 12.25"
    />
    <path
      stroke={props.fill}
      fill="none"
      d="M17.5868 4.12744C18.6766 5.46355 19.3343 7.09948 19.4726 8.81811C19.6109 10.5367 19.2233 12.2568 18.3612 13.75"
    />
    <path
      stroke={props.fill}
      fill="none"
      d="M4.41322 4.12744C3.32342 5.46355 2.66571 7.09948 2.5274 8.81811C2.38908 10.5367 2.77669 12.2568 3.63878 13.75"
    />
    <path
      d="M11.0528 13.6316H11.0529C11.5793 13.6315 12.0999 13.7282 12.5847 13.9156C13.0694 14.1029 13.5076 14.3767 13.8752 14.7198L14.2164 14.3543L13.8752 14.7198C14.2428 15.0629 14.5323 15.4682 14.7291 15.9118C14.9259 16.3553 15.0266 16.8292 15.0265 17.3069V17.307V18H7.0791V17.307C7.0791 16.3438 7.48875 15.4124 8.23057 14.7201C8.97356 14.0266 9.98819 13.6316 11.0528 13.6316Z"
      stroke={props.fill}
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

import * as d3 from 'd3-format';
import _ from 'lodash';

const getPrefixBasedOnOptions = ({ n, isEvolution }) => {
  const positivePrefix = isEvolution ? '+' : '';
  return n < 0 ? '−' : positivePrefix;
};

const getSuffixBasedOnOptions = ({ n, isPercentage, isPoint }) => {
  if (isPercentage) return '%';
  if (isPoint) return n >= -1 && n <= 1 ? ' point' : ' points';
  return '';
};

/**
 * will display the numbers in a pretty format
 * we detail positive numbers but negtives work the same with a '- ' prefix
 * will use SI format (16K) for numbers higher or equal than 10000
 * for numbers between 0 and 9999 we use standard format.
 * depending on the value of the number the formated version will adapt the precision
 * For example 100.5 can be formated to 100 but 1.02125 should be formated as 1.02
 *
 * if the number is invalid will return '-'
 * @param {number} n the number to format
 * @param {boolean} options.isTrueInteger Don't display last figures if they are zeros after a dot
 * @param {boolean} options.isPercentage Multiply by 100 and add a "%" at the end
 * @param {boolean} options.isEvolution Add a + or – in front of the value
 * @param {number} options.precision Specify for rounded values, if not specified comupted automatically
 *
 * @returns {string} the formated number
 * * @todo handle very very small figures if we need them one day
 */
const formatNumber = (
  n,
  {
    isTrueInteger = false,
    isPercentage = false,
    isEvolution = false,
    isPoint = false,
    precision: overwritePrecision,
  } = {}
) => {
  // stop if not a valid and finite number
  if (!_.isFinite(n)) {
    return '-';
  }

  // compute prefix and suffix before mutation
  const prefix = getPrefixBasedOnOptions({ n, isEvolution });
  const suffix = getSuffixBasedOnOptions({
    isPercentage,
    isPoint,
    n,
  });

  // do the work on positive values
  n = Math.abs(n);

  if (isPercentage) {
    n = n * 100;
  }

  const trail = isTrueInteger ? '~' : '';
  let type = 'f';
  let precision = 3;

  if (n >= 10000) {
    type = 's';
    precision = n > 99999 && n < 999999 ? 3 : 2;
    n = Math.round(n / 1000) * 1000;
  } else if (n >= 10) {
    precision = 0;
  } else if (n >= 1) {
    precision = 1;
  } else if (n < 1) {
    precision = 2;
  }

  const result = d3.format(`,.${overwritePrecision || precision}${trail}${type}`)(n);
  return `${prefix}${result}${suffix}`;
};

export { formatNumber };

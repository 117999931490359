export type Props = {
  fill: string;
};

export default (props: Props) => (
  <svg {...props} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 11C22 17.0748 17.0748 22 11 22C4.92525 22 0 17.0748 0 11C0 4.92525 4.92525 0 11 0C17.0748 0 22 4.92525 22 11ZM11.3942 8.12075C10.3244 8.56533 8.18583 9.48658 4.97933 10.8836C4.45867 11.0908 4.1855 11.2933 4.16075 11.4913C4.11858 11.8268 4.53842 11.9588 5.10858 12.1376C5.1865 12.1623 5.26717 12.1871 5.34967 12.2146C5.91158 12.397 6.66692 12.6106 7.05925 12.6188C7.41583 12.6262 7.81367 12.4795 8.25275 12.1788C11.2484 10.1558 12.7948 9.13367 12.892 9.11167C12.9608 9.09608 13.0561 9.07592 13.1202 9.13367C13.1844 9.1905 13.178 9.29867 13.1716 9.328C13.1294 9.50492 11.4849 11.0348 10.6324 11.8268C10.3666 12.0734 10.1787 12.2485 10.1402 12.2888C10.054 12.3778 9.966 12.463 9.88167 12.5446C9.35917 13.0469 8.96867 13.4246 9.90367 14.0406C10.3528 14.3367 10.7122 14.5814 11.0706 14.8253C11.462 15.092 11.8525 15.3578 12.3585 15.6897C12.4868 15.774 12.6097 15.8611 12.7298 15.9463C13.1853 16.2717 13.5951 16.5632 14.1011 16.5174C14.3944 16.4899 14.6987 16.214 14.8527 15.3899C15.2167 13.4411 15.9335 9.22075 16.0994 7.48092C16.1095 7.33646 16.1034 7.19133 16.0811 7.04825C16.0677 6.93269 16.0115 6.82635 15.9234 6.75033C15.7923 6.64308 15.5888 6.62017 15.4972 6.622C15.0838 6.62933 14.4494 6.85025 11.3942 8.12075Z"
      fill={props.fill}
    />
  </svg>
);

import styled from '@emotion/styled/macro';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { v4 as uuidv4 } from 'uuid';

import ExportCsvContent from './ExportCsvContent';

const CSV_FORMATS = {
  FR: {
    separator: ';',
    numbersComma: ',',
  },
  DEFAULT: {
    separator: ',',
    numbersComma: '.',
  },
};

const isFrench = (code) =>
  _.includes(['fr', 'fr-be', 'fr-ca', 'fr-fr', 'fr-lu', 'fr-mc', 'fr-ch'], _.toLower(code));

const CSVLinkStyled = styled(CSVLink)`
  text-decoration: none;
`;

const formatCellValue = ({ cellValue, csvFormat }) => {
  /** escapes " in strings with a second ". Because React-CSV in not able to escape " characters. See https://github.com/react-csv/react-csv/issues/28 */
  if (_.isPlainObject(cellValue) || _.isArray(cellValue))
    return JSON.stringify(cellValue).replace(/"/g, '""');
  if (_.isString(cellValue)) return cellValue.replace(/"/g, '""');
  if (_.isNumber(cellValue)) return cellValue.toString(10).replace(/[.]/g, csvFormat.numbersComma);
  return _.cloneDeep(cellValue);
};

/**
 * This function escapes " in strings with a second ". Because React-CSV in not able to escape " characters. See https://github.com/react-csv/react-csv/issues/28
 * @param {Object} data Data as array of objects
 * @returns {Object} A deep copy of the given object with " esacped
 */
const formatCellValues = ({ data, csvFormat }) =>
  _.map(data, (row) => _.mapValues(row, (cellValue) => formatCellValue({ cellValue, csvFormat })));

/**
 * Files with a title longer than 207 characters won't open in excel.
 * As per https://bloomsocialanalytics.atlassian.net/browse/RB-973,
 * this function should now return a string with length of no more than
 * 200 characters, including the name of the project and .csv extension.
 */
export const formatTitle = (title) => {
  return `Export-${_.truncate(title, { length: 189 })}.csv`;
};

const ExportCsv = ({ data, title, isCircled, mustInstantDowload, onClick, label }) => {
  const [automaticClickTriggered, setautomaticClickTriggered] = useState(false);
  const csvLinkRef = useRef();

  const csvFormat = useMemo(() => {
    const browserLanguage = navigator.language || navigator.userLanguage;
    return isFrench(browserLanguage) ? CSV_FORMATS.FR : CSV_FORMATS.DEFAULT;
  }, []);

  const escapedData = useMemo(() => formatCellValues({ data, csvFormat }), [data, csvFormat]);
  const formatedTitle = useMemo(() => formatTitle(title), [title]);

  /**
   * If we want a download on mout, trigger a click once and once only after mount
   * NOTE : we can't use { CSVDownload } from 'react-csv' because it doesn't take the title prop
   */
  useEffect(() => {
    if (mustInstantDowload && !_.isNil(csvLinkRef.current) && !automaticClickTriggered) {
      // the component needs a timeout to setup, not ideal...
      setTimeout(() => {
        csvLinkRef.current.link.click();
        setautomaticClickTriggered(true);
      });
    }
  });

  return (
    <CSVLinkStyled
      key={uuidv4()}
      data={escapedData}
      separator={csvFormat.separator}
      filename={formatedTitle}
      target="_blank"
      ref={csvLinkRef}
      onClick={onClick}
    >
      <ExportCsvContent label={label} isCircled={isCircled} />
    </CSVLinkStyled>
  );
};

ExportCsv.propTypes = {
  data: PropTypes.array,
  isCircled: PropTypes.bool, // wether the icon is in a circle
  label: PropTypes.string, // a label to display next to the icon
  mustInstantDowload: PropTypes.bool, // wether it should trigger the download when entering the DOM
  title: PropTypes.string.isRequired,
};

export default ExportCsv;
export { formatCellValues };

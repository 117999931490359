export type InfoProps = {
  fill: string;
};

export default (props: InfoProps) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    {...props}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 7C1 3.68817 3.68817 1 7 1C10.3118 1 13 3.68817 13 7C13 10.3118 10.3118 13 7 13C3.68817 13 1 10.3118 1 7ZM7 0C3.13588 0 0 3.13588 0 7C0 10.8641 3.13588 14 7 14C10.8641 14 14 10.8641 14 7C14 3.13588 10.8641 0 7 0ZM7.75 3V4.5H6.25V3H7.75ZM7.75 6V10.5H6.25V6H7.75Z"
      fill={props.fill}
    />
  </svg>
);

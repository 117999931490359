import axios from 'axios';
import { sendAnalyticsData } from 'libs/usage-analytics';
import _ from 'lodash';
import { axiosMaintenanceRedirect } from 'shared/maintenanceRedirect';

const auth = {
  signIn(data) {
    return axios
      .post('/api/login', data)
      .catch(axiosMaintenanceRedirect)
      .catch((error) => {
        const e = _.get(error, 'response.data.message', error);
        throw new Error(e);
      });
  },
  signOut({ onUserAction } = {}) {
    return axios
      .get('/api/logout')
      .then(() => {
        sendAnalyticsData('logout', { onUserAction });

        if (window.location.pathName !== '/login') {
          const params = onUserAction
            ? ''
            : `?redirectPath=${encodeURIComponent(
                window.location.pathname
              )}&redirectParams=${encodeURIComponent(window.location.search)}`;
          window.location.assign(`/login${params}`);
        }
      })
      .catch(axiosMaintenanceRedirect)
      .catch((error) => {
        const e = _.get(error, 'response.data.message', error);
        throw new Error(`Error while signOut: ${e}`);
      });
  },

  /** Facebook connection */
  fbSignIn() {
    return axios
      .get('/auth/facebook/auth-uri')
      .then((response) => {
        window.location = response.data.uri;
      })
      .catch(axiosMaintenanceRedirect);
  },
  fbSignOut() {
    return axios.post('/auth/facebook/sign-out', null).catch(axiosMaintenanceRedirect);
  },
};

export default auth;

export default (props) => (
  <svg {...props} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11 0C4.92507 0 0 4.92507 0 11C0 17.0749 4.92507 22 11 22C17.0749 22 22 17.0749 22 11C22 4.92507 17.0749 0 11 0ZM9.75247 17.2802C6.53541 17.2802 3.24722 15.7209 3.24722 13.157C3.24722 11.8163 4.09639 10.2663 5.55885 8.80338C7.51241 6.85117 9.78983 5.96149 10.6462 6.81876C11.0241 7.19642 11.0605 7.85 10.8179 8.63075C10.6912 9.02416 11.1868 8.80608 11.1868 8.80698C12.7659 8.14574 14.143 8.10725 14.646 8.82633C14.9143 9.20962 14.8889 9.74684 14.6413 10.37C14.5268 10.6566 14.6766 10.7016 14.895 10.7666C15.7844 11.0428 16.7745 11.7094 16.7745 12.8849C16.7742 14.8301 13.9693 17.2802 9.75247 17.2802ZM15.5758 9.12702C15.68 8.80563 15.6145 8.43945 15.3721 8.1705C15.1299 7.90245 14.7716 7.80027 14.4412 7.87026V7.86981C14.1657 7.92968 13.8941 7.75278 13.8358 7.47775C13.7759 7.20115 13.9524 6.92949 14.2283 6.87075C14.904 6.72739 15.6359 6.93625 16.1312 7.48563C16.6282 8.03546 16.7607 8.78447 16.5485 9.44121C16.4619 9.71016 16.174 9.85668 15.9055 9.77093C15.637 9.68338 15.49 9.39507 15.5767 9.12702H15.5758V9.12702ZM18.5538 10.0892C18.5538 10.0896 18.5538 10.091 18.5538 10.0914C18.4528 10.4027 18.1179 10.5733 17.8064 10.4724C17.4935 10.3718 17.3229 10.0376 17.424 9.72524L17.4238 9.72434C17.7337 8.76557 17.5372 7.67198 16.8139 6.87008C16.0896 6.06817 15.0223 5.76141 14.0359 5.97117C13.7149 6.03982 13.3999 5.83501 13.3312 5.51452C13.2626 5.19403 13.4672 4.87849 13.7879 4.80984H13.7883C15.1747 4.51523 16.6768 4.94533 17.6948 6.07448C18.7136 7.20205 18.9886 8.73968 18.5538 10.0892Z"
      fill={props.fill}
    />
    <path
      d="M9.77762 12.9241C9.62503 12.8633 9.43417 12.9369 9.3446 13.0868C9.25772 13.238 9.30566 13.4098 9.4587 13.4723C9.614 13.5365 9.81273 13.4627 9.90253 13.3087C9.98828 13.1543 9.93269 12.9808 9.77762 12.9241Z"
      fill={props.fill}
    />
    <path
      d="M9.15746 10.2584C6.59128 10.5125 4.64471 12.0839 4.81125 13.769C4.97803 15.4542 7.19423 16.6144 9.76063 16.3613C12.3275 16.1076 14.2732 14.536 14.1071 12.8498C13.9403 11.1648 11.725 10.0043 9.15746 10.2584ZM11.4097 14.233C10.886 15.4178 9.37938 16.0493 8.10079 15.6374C6.86699 15.2389 6.34461 14.0199 6.88499 12.9225C7.41524 11.846 8.79624 11.2374 10.0174 11.5555C11.2816 11.8823 11.9266 13.0749 11.4097 14.233Z"
      fill={props.fill}
    />
    <path
      d="M8.79807 13.3303C8.40038 13.164 7.88678 13.335 7.64169 13.7197C7.39322 14.1057 7.50957 14.5659 7.90434 14.7457C8.30473 14.9287 8.83633 14.755 9.08457 14.3588C9.32854 13.9585 9.1998 13.5016 8.79807 13.3303Z"
      fill={props.fill}
    />
  </svg>
);

import { css } from '@emotion/css/macro';
import styled from '@emotion/styled/macro';
import { ButtonSquare, Icon, Paragraph1, Title1, Title2 } from 'components';
import OnOffSwitch from 'components/OnOffSwitch';
import _ from 'lodash';
import { useEffect, useState } from 'react';

import useCookiesPrefPanel from './useCookiesPrefPanel';
import wording from './wording.json';

const DEFAULT_COOKIES_PREFS = {
  analytics: true,
};

const Overlay = styled.div(
  ({ theme, isOpen }) => css`
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: ${theme.CookiesPrefPanel.overlayBackground};
    z-index: 100;
    opacity: ${isOpen ? 1 : 0};
    pointer-events: ${isOpen ? 'unset' : 'none'};
    transition: opacity 0.5s ease-in;
  `
);

const Container = styled.div(
  ({ theme, isOpen }) => css`
    position: fixed;
    z-index: 101;
    top: 0;
    right: ${isOpen
      ? 0
      : `calc(-${theme.CookiesPrefPanel.width} - 50px)`}; /** the -50px is here to take the shadow into account */
    height: 100%;
    width: ${theme.CookiesPrefPanel.width};
    overflow: auto;
    background-color: ${theme.primaryColorDarkUltra};
    box-shadow: ${theme.CookiesPrefPanel.boxShadow};
    padding: 30px ${theme.gutter};
    transition: right 0.5s ease-in;
  `
);

const TitleContainer = styled.div`
  display: flex;
`;
const Title1Styled = styled(Title1)`
  flex: 1;
`;
const Title2Styled = styled(Title2)`
  flex: 1;
`;
const IconStyled = styled(Icon)(
  ({ theme }) => css`
    align-self: flex-start;
    margin-left: ${theme.col};
    cursor: pointer;
  `
);
const ParagraphStyled = styled(Paragraph1)`
  margin-top: 10px;
  color: ${({ theme }) => theme.mainColorLight};
`;
const ButtonStyled = styled(ButtonSquare)`
  margin-top: 30px;
`;
const SeparationLine = styled.div`
  height: 1px;
  background-color: ${({ theme }) => theme.mainColor};
  opacity: ${({ theme }) => theme.superLightOpacity};
  margin: 30px 0;
`;

const CookiesPrefPanel = () => {
  const { isOpen, close, cookiesPrefs, dispatchCookiesPrefs } = useCookiesPrefPanel();
  const [selectedPrefs, setSelectedPrefs] = useState(DEFAULT_COOKIES_PREFS);

  useEffect(() => {
    setSelectedPrefs(
      _.mapValues(DEFAULT_COOKIES_PREFS, (defaultValue, key) =>
        _.isNil(cookiesPrefs[key]) ? defaultValue : cookiesPrefs[key]
      )
    );
  }, [cookiesPrefs]);

  const closeWithSelectedPrefs = () => {
    dispatchCookiesPrefs(selectedPrefs);
    close();
  };

  const closeWithDefaultPrefs = () => {
    dispatchCookiesPrefs(DEFAULT_COOKIES_PREFS);
    close();
  };

  return (
    <>
      <Container isOpen={isOpen} data-testid="cookie-panel">
        <TitleContainer>
          <Title1Styled>{wording.title}</Title1Styled>
          <IconStyled
            onClick={closeWithSelectedPrefs}
            type="discovery-close"
            data-testid="cookie-close"
          />
        </TitleContainer>
        <ParagraphStyled>{wording.presentation}</ParagraphStyled>
        <ButtonStyled onClick={closeWithDefaultPrefs} color="secondary">
          {wording.accept}
        </ButtonStyled>
        <SeparationLine />
        <Title2>{wording.requiredTitle}</Title2>
        <ParagraphStyled>{wording.requiredPresentation}</ParagraphStyled>
        <SeparationLine />
        <TitleContainer>
          <Title2Styled>{wording.analyticsTitle}</Title2Styled>
          <OnOffSwitch
            isOn={selectedPrefs.analytics}
            setOn={() => setSelectedPrefs({ ...selectedPrefs, analytics: true })}
            setOff={() => setSelectedPrefs({ ...selectedPrefs, analytics: false })}
          />
        </TitleContainer>
        <ParagraphStyled>{wording.analyticsPresentation}</ParagraphStyled>
      </Container>
      <Overlay isOpen={isOpen} />
    </>
  );
};

export default CookiesPrefPanel;

export default (props) => (
  <svg viewBox="0 0 22 22" {...props} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill="none"
      stroke={props.fill}
      d="M16.625 7.5H17.875C18.2188 7.5 18.5 7.78125 18.5 8.125V17.5L16.5 15.375H9.125C8.78125 15.375 8.5 15.0938 8.5 14.75V13.5"
    />
    <path
      fill="none"
      stroke={props.fill}
      d="M7 11.75H6.79289L6.64645 11.8964L4.5 14.0429V4.75C4.5 4.61364 4.61364 4.5 4.75 4.5H14.5C14.6364 4.5 14.75 4.61364 14.75 4.75V11.5C14.75 11.6364 14.6364 11.75 14.5 11.75H7Z"
    />
  </svg>
);

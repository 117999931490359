import { Banner as BannerUi, BannerType } from '@bloom/ui';
import styled from '@emotion/styled';
import type { FC } from 'react';
import { useState } from 'react';

const NeutralError = styled.div`
  border: 1px solid transparent;
  background-color: transparent;
  color: white;
  padding: 12px 15px;
  font-size: 13px;
`;

export type BannerProps = {
  children: React.ReactNode;
  bannerType: BannerType;
  neutral?: boolean;
  isCloseable?: boolean;
};

const Banner: FC<BannerProps> = ({ children, bannerType, isCloseable = true, neutral = false }) => {
  const [showBanner, setShowBanner] = useState<boolean>(true);
  const onClose = () => setShowBanner(false);
  if (bannerType == BannerType.Error && neutral) {
    return <NeutralError>{children}</NeutralError>;
  }
  return (
    <>
      {showBanner && (
        <BannerUi type={bannerType} onClose={isCloseable ? onClose : undefined}>
          {children}
        </BannerUi>
      )}
    </>
  );
};

export default Banner;

export default (props) => (
  <svg viewBox="0 0 22 22" {...props} xmlns="http://www.w3.org/2000/svg">
    <circle fill={props.color} cx="14.5" cy="4.5" r="1.5" />
    <circle fill={props.color} cx="8.5" cy="11.5" r="1.5" />
    <circle fill={props.color} cx="4.5" cy="6.5" r="1.5" />
    <circle fill={props.color} cx="17.5" cy="14.5" r="1.5" />
    <path
      fill={props.color}
      opacity="0.4"
      d="M17.5 14.5L8.5 11.5M8.5 11.5L14.5 4.5M8.5 11.5L6.5 17.5M8.5 11.5L4.5 6.5"
      stroke="white"
    />
    <circle fill={props.color} cx="6.5" cy="17.5" r="1.5" />
  </svg>
);

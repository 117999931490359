type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

export default ({ fill, ...props }: Props) => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.4167 18.9048H5.58334C5.16907 18.9048 4.83334 18.569 4.83334 18.1548V3.15475C4.83334 2.74048 5.16907 2.40475 5.58334 2.40475H13.2929L17.1667 6.27853V18.1548C17.1667 18.569 16.8309 18.9048 16.4167 18.9048Z"
      stroke={fill}
    />
    <path d="M17.6667 6.07142H13.5V1.90475L17.6667 6.07142Z" fill={fill} />
  </svg>
);

import styled from '@emotion/styled/macro';
import PropTypes from 'prop-types';

const H2 = styled.h2`
  color: ${({ theme }) => theme.color};
  font-family: Lato;
  font-size: 65px;
  line-height: 65px;
  font-weight: 100;
  margin-bottom: 40px;
`;

const Light = styled.span`
  display: block;
`;

const Bold = styled.span`
  display: block;
  font-weight: bold;
  font-size: 46px;
  line-height: normal;
  max-width: 860px;
`;

const ChapterTitle = ({ light, bold }) => (
  <H2>
    <Light>{light}</Light>
    <Bold>{bold}</Bold>
  </H2>
);

ChapterTitle.propTypes = {
  /** Bold is the bottom part of the title */
  bold: PropTypes.string.isRequired,
  /** Light is the upper part */
  light: PropTypes.string.isRequired,
};

export default ChapterTitle;

export default (props) => (
  <svg {...props} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle stroke={props.fill} cx="21.0526" cy="14.5526" r="2.18421" />
    <path
      stroke={props.fill}
      d="M25.0262 22.307V23H17.0789V22.307C17.0789 21.3438 17.4885 20.4124 18.2303 19.7201C18.9733 19.0266 19.988 18.6316 21.0525 18.6316V18.1316L21.0526 18.6316C21.5791 18.6315 22.0997 18.7282 22.5844 18.9156C23.0691 19.1029 23.5074 19.3767 23.875 19.7198C24.2425 20.0629 24.5321 20.4682 24.7289 20.9118C24.9256 21.3553 25.0263 21.8292 25.0262 22.3069V22.307Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle stroke={props.fill} cx="6.47376" cy="14.5526" r="2.18421" />
    <path
      stroke={props.fill}
      d="M10.4474 22.307V23H2.5V22.307C2.5 21.3438 2.90965 20.4124 3.65147 19.7201C4.39445 19.0266 5.40909 18.6316 6.47368 18.6316V18.1316L6.47377 18.6316C7.00023 18.6315 7.52083 18.7282 8.00557 18.9156C8.49027 19.1029 8.92854 19.3767 9.29615 19.7198C9.66368 20.0629 9.95322 20.4682 10.15 20.9118C10.3468 21.3553 10.4475 21.8292 10.4474 22.3069V22.307Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle fill={props.fill} cx="19.5" cy="4.5" r="1.5" />
    <circle fill={props.fill} cx="13.5" cy="11.5" r="1.5" />
    <circle fill={props.fill} cx="9.5" cy="6.5" r="1.5" />
    <circle fill={props.fill} cx="13.5" cy="17.5" r="1.5" />
    <path
      stroke={props.fill}
      opacity="0.4"
      d="M13.5 17V11.5M13.5 11.5L19.5 4.5M13.5 11.5L9.5 6.5"
    />
  </svg>
);

import styled from '@emotion/styled/macro';
import _ from 'lodash';
import { Component } from 'react';
import ReactDOM from 'react-dom';

const Wrapper = styled('div')({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(7,18,64,0.6)',
  zIndex: 101,
});

class WrapEscape extends Component {
  componentDidMount() {
    document.body.addEventListener('keydown', this.onKeyDown);
  }

  componentWillUnmount() {
    document.body.removeEventListener('keydown', this.onKeyDown);
  }

  onKeyDown = (evt) => {
    if (evt.key === 'Escape') {
      evt.preventDefault();
      this.props.onEscape();
    }
  };

  render() {
    return this.props.children;
  }
}

class Modal extends Component {
  constructor(props) {
    super(props);
    this.el = document.createElement('div');
    this.modalRoot = null;
  }

  componentDidMount() {
    this.modalRoot = document.getElementById('modal-root');
    this.modalRoot.appendChild(this.el);
  }

  componentWillUnmount() {
    this.modalRoot.removeChild(this.el);
  }

  render() {
    const { onEscape = _.noop, children } = this.props;
    return ReactDOM.createPortal(
      <WrapEscape onEscape={onEscape}>
        <Wrapper>{children}</Wrapper>
      </WrapEscape>,
      this.el
    );
  }
}

export default Modal;

import styled from '@emotion/styled/macro';
import PropTypes from 'prop-types';
import { cloneElement } from 'react';

import Button from './Button';

const Wrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

const IconWrapper = styled('span')({
  width: 15,
  height: 15,
  marginRight: 8,
});

const TextWrapper = styled('span')({
  flex: 1,
  textAlign: 'center',
});

const ButtonWithIcon = ({ iconComponent, children, ...props }) => {
  return (
    <Button {...props} pl={15}>
      <Wrapper>
        <IconWrapper>{cloneElement(iconComponent, { width: 15, height: 15 })}</IconWrapper>
        <TextWrapper>{children}</TextWrapper>
      </Wrapper>
    </Button>
  );
};

ButtonWithIcon.propTypes = {
  iconComponent: PropTypes.element.isRequired,
  ...Button.propTypes,
};

ButtonWithIcon.displayName = 'ButtonWithIcon';

export default ButtonWithIcon;

import 'assets/fonts/geomanist/stylesheet.css';

import { ApolloProvider } from '@apollo/client';
import styled from '@emotion/styled/macro';
import Background from 'Background';
import { Loader } from 'components';
import { getConfig, initConfig } from 'config/config-service';
import CookiesPrefPanel from 'CookiesPrefPanel';
import { CookiesPrefPanelProvider } from 'CookiesPrefPanel/useCookiesPrefPanel';
import apolloClient from 'libs/apollo-client';
import sentry, { LOGS_TYPE } from 'libs/sentry';
import { sendAnalyticsData } from 'libs/usage-analytics';
import _ from 'lodash';
import { Component, useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import Notification from 'services/Notification';
import { NotificationProvider } from 'services/Notification/useNotification';
import { isDevEnv } from 'services/utils';
import BloomRouter from 'shared/BloomRouter';
import getOs from 'shared/getOs';
import { RedirectMaintenanceError } from 'shared/maintenanceRedirect';
import { ThemeProvider } from 'theme';

import appRoutes from './app-routes';
import GlobalStyles from './global-css';
import wording from './wording.json';

// import Inter font depending on OS
if (getOs() === 'Windows') {
  require('assets/fonts/inter-windows/inter.css');
} else {
  require('assets/fonts/inter/inter.css');
}

/** This component is displayed before anything is loaded that's why it embeds its values hardcoded */
const LoadingScreenContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #101350;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 21px;
`;

const Error = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

class AppWithConfig extends Component {
  state = {
    hasError: false,
  };

  constructor(props) {
    super(props);

    sentry.init();
  }

  componentDidCatch(error) {
    sentry.sendLogs({ type: LOGS_TYPE.REACT, error });
    sendAnalyticsData('error', { message: error.message });

    if (isDevEnv()) return;

    this.setState({ hasError: true });
  }

  render() {
    const { hasError } = this.state;
    return (
      <ApolloProvider client={apolloClient}>
        <CookiesPrefPanelProvider>
          <ThemeProvider>
            <GlobalStyles />
            <NotificationProvider>
              <Notification />
              <BrowserRouter>
                {hasError ? (
                  <Error>
                    <h1>{wording.defaultError}</h1>
                  </Error>
                ) : (
                  <BloomRouter routes={appRoutes} />
                )}
                <Background />
              </BrowserRouter>
            </NotificationProvider>
            <CookiesPrefPanel />
          </ThemeProvider>
        </CookiesPrefPanelProvider>
      </ApolloProvider>
    );
  }
}

/**
 * Load the config from the server and block the loading of the app until the config is loaded
 */
const App = () => {
  const [configIsSet, setConfigIsSet] = useState();

  useEffect(() => {
    initConfig()
      .then(() => {
        if (getConfig().APP_ENV === 'test') return;

        setConfigIsSet(true);
      })
      .catch((e) => {
        // if redirect, we wait for the redirect to happen
        if (e instanceof RedirectMaintenanceError) return;

        // else, its an error
        setConfigIsSet(false);
      });
  }, []);

  useEffect(() => {
    window.addEventListener('error', handleChunkLoadError);
    return () => {
      window.removeEventListener('error', handleChunkLoadError);
    };
  });

  if (_.isNil(configIsSet))
    return (
      <>
        <Loader />
        <Background />
      </>
    );

  if (!configIsSet)
    return <LoadingScreenContainer>Error while loading app configuration</LoadingScreenContainer>;

  return <AppWithConfig />;
};

function handleChunkLoadError(e) {
  if (/Loading chunk [\d]+ failed/.test(e.message) === false) return;

  // eslint-disable-next-line no-console
  console.log(
    `Failing to load a chunk: this could mean that your app is outdated and should be reloaded.`
  );

  // We do not want to reload the page when it is not visible
  if (document.visibilityState !== 'visible') return;
  // eslint-disable-next-line no-console
  console.log(`Reloading the page..`);
  window.location.reload();
}

export default App;

import { computeBackground } from 'theme/functions';

const fontFamily = 'Lato, arial, sans-serif';

export default {
  primary: '#5e4b8a',
  secondary: '#bfbfbf',
  success: '#6bc996',
  danger: '#f25757',
  info: '#515979',
  color: '#ffffff',
  subColor: '#536F87',
  fontColor: '#515979',
  placeholderFontColor: '#8A8A8A',
  blackFontColor: '#828282',
  disabledFontColor: '#b5bacd',
  gray: '#F4F4F4',
  darkGrayColor: '#E5E8F4',
  blueGrayColor: '#8e99c4',
  widthMargin: '40px',
  borderColor: '#3b2d58',
  borderRadioDisabbled: '#d9d9d9',
  backgroundRadioDisabled: '#f2f2f2',

  mainColor: '#ffffff',
  mainColorLight: 'rgba(255, 255, 255, 0.6)',
  oppositeColor: '#ffffff',
  oppositeColorLight: 'rgba(255, 255, 255, 0.6)',
  iconSize: 18,
  fontFamily,
  bodyBackgroundPrimary: computeBackground(['-45deg', '#231739', '#7f67ac']),
};

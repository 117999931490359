const SvgComponent = (props) => (
  <svg viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M0.75 2.58334H17.25V17.25H0.75V2.58334Z" stroke="white" strokeLinejoin="round" />
    <path d="M17.25 6.25001H0.75" stroke="white" strokeLinecap="round" />
    <path d="M4.41667 2.58333V0.75" stroke="white" strokeLinecap="round" />
    <path d="M13.5833 2.58333V0.75" stroke="white" strokeLinecap="round" />
  </svg>
);

export default SvgComponent;

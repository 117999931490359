import { withTheme } from '@emotion/react';
import Copy from 'components/Icon/assets/Copy';
import Emotions from 'components/Icon/assets/Emotions';
import Glossary from 'components/Icon/assets/Glossary';
import Opinions from 'components/Icon/assets/Opinions';
import Placeholder from 'components/Icon/assets/Placeholder';
import type { ComponentClass, FC } from 'react';
import { createElement } from 'react';
import type { Theme } from 'theme/theme';

import Account from './assets/Account';
import Add from './assets/Add';
import AddCircleOutline from './assets/AddCircleOutline';
import Analytics from './assets/Analytics';
import ArrowBack from './assets/ArrowBack';
import ArrowDown from './assets/ArrowDown';
import ArrowLeft from './assets/ArrowLeft';
import ArrowNext from './assets/ArrowNext';
import ArrowPrevious from './assets/ArrowPrevious';
import ArrowRight from './assets/ArrowRight';
import ArrowUp from './assets/ArrowUp';
import Bold from './assets/Bold';
import Calendar from './assets/Calendar';
import Check from './assets/Check';
import CheckMini from './assets/CheckMini';
import CircleChecked from './assets/CircleChecked';
import CircleUnchecked from './assets/CircleUnchecked';
import CloseAlt from './assets/CloseAlt';
import CloseCircleOutline from './assets/CloseCircleOutline';
import CloseMini from './assets/CloseMini';
import Crane from './assets/Crane';
import Dashboard from './assets/Dashboard';
import DirectionRight from './assets/DirectionRight';
import DirectLink from './assets/DirectLink';
import DiscoveryActorGraph from './assets/discovery/ActorGraph';
import DiscoveryActorInterests from './assets/discovery/ActorInterests';
import DiscoveryAdd from './assets/discovery/Add';
import DiscoveryArrowDown from './assets/discovery/ArrowDown';
import DiscoveryArrowLeft from './assets/discovery/ArrowLeft';
import DiscoveryArrowRight from './assets/discovery/ArrowRight';
import DiscoveryArrowUp from './assets/discovery/ArrowUp';
import DiscoveryCheck from './assets/discovery/Check';
import DiscoveryChevronDown from './assets/discovery/ChevronDown';
import DiscoveryChevronLeft from './assets/discovery/ChevronLeft';
import DiscoveryChevronRight from './assets/discovery/ChevronRight';
import DiscoveryChevronUp from './assets/discovery/ChevronUp';
import DiscoveryCircleColor from './assets/discovery/CircleColor';
import DiscoveryCircleFill from './assets/discovery/CircleFill';
import DiscoveryCircleSize from './assets/discovery/CircleSize';
import DiscoveryClose from './assets/discovery/Close';
import DiscoveryCloseCursor from './assets/discovery/CloseCursor';
import DiscoveryDate from './assets/discovery/Date';
import DiscoveryDelete from './assets/discovery/Delete';
import DiscoveryDouyin from './assets/discovery/Douyin';
import DiscoveryDownload from './assets/discovery/Download';
import DiscoveryEdit from './assets/discovery/Edit';
import DiscoveryEdit2 from './assets/discovery/Edit2';
import DiscoveryEmotionControverse from './assets/discovery/EmotionControverse';
import DiscoveryEmotionNegative from './assets/discovery/EmotionNegative';
import DiscoveryEmotionNegativeHigh from './assets/discovery/EmotionNegativeHigh';
import DiscoveryEmotionNeutral from './assets/discovery/EmotionNeutral';
import DiscoveryEmotionNone from './assets/discovery/EmotionNone';
import DiscoveryEmotionPositive from './assets/discovery/EmotionPositive';
import DiscoveryEmotionPositiveHigh from './assets/discovery/EmotionPositiveHigh';
import DiscoveryEvolution from './assets/discovery/Evolution';
import DiscoveryExploreGraph from './assets/discovery/ExploreGraph';
import DiscoveryExport from './assets/discovery/Export';
import DiscoveryExternalLink from './assets/discovery/ExternalLink';
import DiscoveryFacebook from './assets/discovery/Facebook';
import DiscoveryFailed from './assets/discovery/Failed';
import DiscoveryFinished from './assets/discovery/Finished';
import DiscoveryHelp from './assets/discovery/Help';
import DiscoveryHidePanel from './assets/discovery/HidePanel';
import DiscoveryHome from './assets/discovery/Home';
import DiscoveryInfos from './assets/discovery/Infos';
import DiscoveryInstagram from './assets/discovery/Instagram';
import DiscoveryKrilin from './assets/discovery/Krilin';
import DiscoveryLegendBase from './assets/discovery/LegendBase';
import DiscoveryLegendComparison from './assets/discovery/LegendComparison';
import DiscoveryLinkedin from './assets/discovery/Linkedin';
import DiscoveryLittleRedBook from './assets/discovery/LittleRedBook';
import DiscoveryMenu from './assets/discovery/Menu';
import DiscoveryMove from './assets/discovery/Move';
import DiscoveryOut from './assets/discovery/Out';
import DiscoveryParameters from './assets/discovery/Parameters';
import DiscoveryProgress from './assets/discovery/Progress';
import DiscoveryReddit from './assets/discovery/Reddit';
import ResetDiscovery from './assets/discovery/Reset';
import DiscoverySynchronization from './assets/discovery/Synchronization';
import DiscoveryTelegram from './assets/discovery/Telegram';
import DiscoveryTermGraph from './assets/discovery/TermGraph';
import DiscoveryTikTok from './assets/discovery/TikTok';
import DiscoveryTopDocuments from './assets/discovery/TopDocuments';
import DiscoveryTopInfluencers from './assets/discovery/TopInfluencers';
import DiscoveryTwitter from './assets/discovery/Twitter';
import DiscoveryVKontakte from './assets/discovery/VKontakte';
import DiscoveryWeb from './assets/discovery/Web';
import DiscoveryWechat from './assets/discovery/Wechat';
import DiscoveryWeibo from './assets/discovery/Weibo';
import DiscoveryYoutube from './assets/discovery/Youtube';
import Douyin from './assets/Douyin';
import Download from './assets/Download';
import DownloadFile from './assets/DownloadFile';
import Duplicate from './assets/Duplicate';
import Edit from './assets/Edit';
import EllipsisHorizontal from './assets/EllipsisHorizontal';
import EmptyDot from './assets/EmptyDot';
import Excel from './assets/Excel';
import ExternalLink from './assets/ExternalLink';
import Eye from './assets/Eye';
import EyeOff from './assets/EyeOff';
import Facebook from './assets/Facebook';
import File from './assets/File';
import Gear from './assets/Gear';
import Hashtag from './assets/Hashtag';
import Info from './assets/Info';
import Instagram from './assets/Instagram';
import Keyword from './assets/Keyword';
import Link from './assets/Link';
import LinkPost from './assets/LinkPost';
import LittleRedBook from './assets/LittleRedBook';
import Lozenge from './assets/Lozenge';
import Menu from './assets/Menu';
import Modeling from './assets/Modeling';
import More from './assets/More';
import Party from './assets/Party';
import PasteMulti from './assets/PasteMulti';
import Picture from './assets/Picture';
import Processing from './assets/Processing';
import RadioSelected from './assets/RadioSelected';
import RadioUnselected from './assets/RadioUnselected';
import Rectangle from './assets/Rectangle';
import Reddit from './assets/Reddit';
import Refresh from './assets/Refresh';
import Remove from './assets/Remove';
import ReturnMini from './assets/ReturnMini';
import Search from './assets/Search';
import SmallClose from './assets/SmallClose';
import Status from './assets/Status';
import SubstractMini from './assets/SubstractMini';
import Sub from './assets/Subtract';
import TikTok from './assets/TikTok';
import Trash from './assets/Trash';
import Twitter from './assets/Twitter';
import Typography from './assets/Typography';
import Underline from './assets/Underline';
import UnorderedList from './assets/UnorderedList';
import Warning from './assets/Warning';
import WarningNotification from './assets/WarningNotification';
import Admin from './Menu/Admin';
import AdminUsers from './Menu/AdminUsers';
import Classification from './Menu/Classification';
import Close from './Menu/Close';
import DataViz from './Menu/DataViz';
import FBAccess from './Menu/FBAccess';
import Graphs from './Menu/Graphs';
import Logout from './Menu/Logout';
import MenuArrowLeft from './Menu/MenuArrowLeft';
import PageAccess from './Menu/PageAccess';
import ProjectAdmin from './Menu/ProjectAdmin';
import ProjectConfig from './Menu/ProjectConfig';
import ProjectDashboard from './Menu/ProjectDashboard';
import ProjectGraphList from './Menu/ProjectGraphList';
import ProjectPublication from './Menu/ProjectPublication';
// minified-menu icons
import Projects from './Menu/Projects';
import Reset from './Menu/Reset';
import See from './Menu/See';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const mapping: Record<string, string | FC<any> | ComponentClass<any, any>> = {
  placeholder: Placeholder,
  modeling: Modeling,
  crane: Crane,
  emotions: Emotions,
  party: Party,
  glossary: Glossary,
  opinions: Opinions,
  'discovery-add': DiscoveryAdd,
  'discovery-arrow-right': DiscoveryArrowRight,
  'discovery-arrow-left': DiscoveryArrowLeft,
  'discovery-arrow-up': DiscoveryArrowUp,
  'discovery-arrow-down': DiscoveryArrowDown,
  'discovery-check': DiscoveryCheck,
  'discovery-chevron-right': DiscoveryChevronRight,
  'discovery-chevron-left': DiscoveryChevronLeft,
  'discovery-chevron-up': DiscoveryChevronUp,
  'discovery-chevron-down': DiscoveryChevronDown,
  'discovery-circle-color': DiscoveryCircleColor,
  'discovery-circle-fill': DiscoveryCircleFill,
  'discovery-circle-size': DiscoveryCircleSize,
  'discovery-close': DiscoveryClose,
  'discovery-close-cursor': DiscoveryCloseCursor,
  'discovery-date': DiscoveryDate,
  'discovery-download': DiscoveryDownload,
  'discovery-douyin': DiscoveryDouyin,
  'discovery-delete': DiscoveryDelete,
  'discovery-edit': DiscoveryEdit,
  'discovery-edit2': DiscoveryEdit2,
  'discovery-emotion-controverse': DiscoveryEmotionControverse,
  'discovery-emotion-negative': DiscoveryEmotionNegative,
  'discovery-emotion-negative-high': DiscoveryEmotionNegativeHigh,
  'discovery-emotion-neutral': DiscoveryEmotionNeutral,
  'discovery-emotion-none': DiscoveryEmotionNone,
  'discovery-emotion-positive': DiscoveryEmotionPositive,
  'discovery-emotion-positive-high': DiscoveryEmotionPositiveHigh,
  'discovery-evolution': DiscoveryEvolution,
  'discovery-explore-graph': DiscoveryExploreGraph,
  'discovery-export': DiscoveryExport,
  'discovery-external-link': DiscoveryExternalLink,
  'discovery-facebook': DiscoveryFacebook,
  'discovery-failed': DiscoveryFailed,
  'discovery-finished': DiscoveryFinished,
  'discovery-help': DiscoveryHelp,
  'discovery-out': DiscoveryOut,
  'discovery-progress': DiscoveryProgress,
  'discovery-hide-panel': DiscoveryHidePanel,
  'discovery-home': DiscoveryHome,
  'discovery-infos': DiscoveryInfos,
  'discovery-krilin': DiscoveryKrilin,
  'discovery-instagram': DiscoveryInstagram,
  'discovery-legend-base': DiscoveryLegendBase,
  'discovery-legend-comparison': DiscoveryLegendComparison,
  'discovery-menu': DiscoveryMenu,
  'discovery-move': DiscoveryMove,
  'discovery-parameters': DiscoveryParameters,
  'discovery-reset': ResetDiscovery,
  'discovery-synchronization': DiscoverySynchronization,
  'discovery-term-graph': DiscoveryTermGraph,
  'discovery-tiktok': DiscoveryTikTok,
  'discovery-reddit': DiscoveryReddit,
  'discovery-actor-graph': DiscoveryActorGraph,
  'discovery-actor-interests': DiscoveryActorInterests,
  'discovery-top-documents': DiscoveryTopDocuments,
  'discovery-top-influencers': DiscoveryTopInfluencers,
  'discovery-twitter': DiscoveryTwitter,
  'discovery-youtube': DiscoveryYoutube,
  'discovery-web': DiscoveryWeb,
  'discovery-weibo': DiscoveryWeibo,
  'discovery-wechat': DiscoveryWechat,
  'discovery-linkedin': DiscoveryLinkedin,
  'discovery-telegram': DiscoveryTelegram,
  'discovery-vkontakte': DiscoveryVKontakte,
  'discovery-littleredbook': DiscoveryLittleRedBook,
  analytics: Analytics,
  edit: Edit,
  more: More,
  gear: Gear,
  dashboard: Dashboard,
  'external-link': ExternalLink,
  'add-circle-outline': AddCircleOutline,
  'close-circle-outline': CloseCircleOutline,
  search: Search,
  warning: Warning,
  douyin: Douyin,
  twitter: Twitter,
  facebook: Facebook,
  instagram: Instagram,
  tiktok: TikTok,
  littleredbook: LittleRedBook,
  menu: Menu,
  'arrow-up': ArrowUp,
  'arrow-down': ArrowDown,
  'arrow-right': ArrowRight,
  'arrow-left': ArrowLeft,
  lozenge: Lozenge,
  download: Download,
  'download-file': DownloadFile,
  'arrow-back': ArrowBack,
  eye: Eye,
  'eye-off': EyeOff,
  refresh: Refresh,
  remove: Remove,
  reddit: Reddit,
  trash: Trash,
  add: Add,
  sub: Sub,
  keyword: Keyword,
  'circle-checked': CircleChecked,
  'circle-unchecked': CircleUnchecked,
  'radio-selected': RadioSelected,
  'radio-unselected': RadioUnselected,
  'arrow-previous': ArrowPrevious,
  'arrow-next': ArrowNext,
  calendar: Calendar,
  bold: Bold,
  link: Link,
  underline: Underline,
  picture: Picture,
  unorderedlist: UnorderedList,
  typography: Typography,
  'close-mini': CloseMini,
  processing: Processing,
  'return-mini': ReturnMini,
  'paste-multi': PasteMulti,
  'warning-notification': WarningNotification,
  'check-mini': CheckMini,
  'substract-mini': SubstractMini,
  projects: Projects,
  'project-publication': ProjectPublication,
  'project-graphList': ProjectGraphList,
  'project-config': ProjectConfig,
  'project-dashboard': ProjectDashboard,
  'project-admin': ProjectAdmin,
  'page-access': PageAccess,
  logout: Logout,
  graphs: Graphs,
  'fb-access': FBAccess,
  dataviz: DataViz,
  classification: Classification,
  admin: Admin,
  'admin-users': AdminUsers,
  close: Close,
  reset: Reset,
  see: See,
  'menu-arrow-left': MenuArrowLeft,
  excel: Excel,
  'close-alt': CloseAlt,
  info: Info,
  status: Status,
  check: Check,
  'small-close': SmallClose,
  duplicate: Duplicate,
  'ellipsis-horizontal': EllipsisHorizontal,
  'empty-dot': EmptyDot,
  account: Account,
  hashtag: Hashtag,
  'link-post': LinkPost,
  'direction-right': DirectionRight,
  'direct-link': DirectLink,
  file: File,
  copy: Copy,
  rectangle: Rectangle,
};

export type IconType = keyof typeof mapping;

export type IconProps = {
  color?: string;
  height?: number;
  theme: Theme;
  type: IconType;
  width?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

const Icon: FC<IconProps> = ({ theme, type, width, height, color, ...props }) => {
  if (!height && !width) height = theme.iconSize;
  color = color || theme.mainColor;

  if (mapping[type]) {
    return createElement(mapping[type], {
      fill: color,
      width,
      height,
      ...props,
    });
  }

  return null;
};

export default withTheme(Icon);

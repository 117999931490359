export default ({ color, ...props }) => (
  <svg viewBox="0 0 22 22" fill={color} {...props}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g>
        <rect
          stroke={props.stroke}
          strokeWidth="1.44727973"
          fill={props.color}
          x="3.72363987"
          y="3.72363987"
          width="12.4193869"
          height="12.4193869"
        />
        <rect
          stroke={props.stroke}
          strokeWidth="1.44727973"
          fill={props.color}
          x="5.8569732"
          y="5.8569732"
          width="12.4193869"
          height="12.4193869"
        />
        <circle fill={props.stroke} cx="15.2666667" cy="8.86666667" r="1.6" />
        <path
          d="M6.91944444,16.8128472 L16.2187567,16.8128472 C16.2187567,16.8128472 15.3697916,12.4255786 14.2648148,12.5903935 C13.159838,12.7552084 13.4587964,15.1130786 12.2043981,15.1130787 C10.9499999,15.1130788 11.41875,11.2074073 9.5193287,11.2074074 C7.61990742,11.2074075 6.91944444,16.8128472 6.91944444,16.8128472 Z"
          id="Path-360"
          fill={props.stroke}
        />
      </g>
    </g>
  </svg>
);

export default (props) => (
  <svg viewBox="0 0 22 22" {...props}>
    <g stroke="none" strokeWidth="1" fillRule="evenodd">
      <polygon
        id="Path"
        points="10.0010535 10.0010535 10 4.00210709 11 4 11.0010535 9.99894645 17 10 17 11 11.0010535 10.9989465 11 17 10 17.0021071 10.0010535 11.0010535 4 11 4 10"
      />
    </g>
  </svg>
);

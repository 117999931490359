export default (props) => (
  <svg viewBox="0 0 24 24" {...props}>
    <g fillRule="evenodd">
      <circle cx="12" cy="12" r="12" fill="#130829" fillOpacity=".8" />
      <g transform="translate(1 1)" fill="#fff" fillRule="nonzero">
        <rect width="22" height="22" fillOpacity="0" />
        <path d="m11 10.293l5.6464-5.6464c0.19526-0.19526 0.51184-0.19526 0.70711 0 0.19526 0.19526 0.19526 0.51184 0 0.70711l-5.6464 5.6464 5.6464 5.6464c0.19526 0.19526 0.19526 0.51184 0 0.70711-0.19526 0.19526-0.51184 0.19526-0.70711 0l-5.6464-5.6464-5.6464 5.6464c-0.19526 0.19526-0.51184 0.19526-0.70711 0-0.19526-0.19526-0.19526-0.51184 0-0.70711l5.6464-5.6464-5.6464-5.6464c-0.19526-0.19526-0.19526-0.51184 0-0.70711s0.51184-0.19526 0.70711 0l5.6464 5.6464z" />
      </g>
    </g>
  </svg>
);

import { css } from '@emotion/css';
import { withTheme } from '@emotion/react';
import styled from '@emotion/styled';
import type React from 'react';
import type { FocusEventHandler, ForwardedRef } from 'react';
import { useMemo } from 'react';
import type {
  ActionMeta,
  FormatOptionLabelMeta,
  GetOptionValue,
  GroupBase,
  OnChangeValue,
} from 'react-select';
import ReactSelect, { components as reactSelectComponents } from 'react-select';
import type { SelectComponents } from 'react-select/dist/declarations/src/components';
import type { Theme } from 'theme/theme';

import customStyles from './custom-styles';
import {
  GenericDropdownIndicator,
  GroupHeading,
  MultiValueContainerDefault,
  MultiValueLabelDefault,
  MultiValueRemoveDefault,
  PlaceholderDefault,
} from './CustomComponents';
import type { OptionType } from './option';

type ContainerProps = {
  noUnderline: boolean | undefined;
  isDisabled: boolean | undefined;
};
const Container = styled.div<ContainerProps>(({ theme, noUnderline, isDisabled = false }) => {
  return css`
    flex: auto;
    border-bottom: ${!noUnderline ? `1px solid ${theme.primaryColorLight};` : `none`};
    height: 100%;
    opacity: ${isDisabled ? 0.5 : 1};
  `;
});

// re-exporting to allow importing OptionType directly from Select.
// also useful for compatibility.

export * from './option';

export type SelectComponentType = SelectComponents<OptionType, boolean, GroupBase<OptionType>> & {
  inputRef: HTMLInputElement | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  customData: any;
};

export type SelectProps = {
  autoFocus?: boolean;
  components?: Partial<SelectComponentType>;
  customData?: unknown; // This will add a customData props in the selectProps. Useful to send data to custom "components"
  filterOption?: ((option: OptionType, rawInput: string) => boolean) | null | undefined;
  fontSizeValue?: string;
  formatOptionLabel?:
    | ((
        option: { label: string; value: string },
        labelMeta: FormatOptionLabelMeta<{ label: string; value: string }>
      ) => React.ReactNode)
    | undefined;
  getOptionValue?: GetOptionValue<OptionType>; // Define how to evaluate option `value` to avoid conflicts
  hideSelectedOptions?: boolean;
  inputRef?: ForwardedRef<HTMLInputElement> | null;
  isBoldValue?: boolean;
  isDisabled?: boolean;
  isHidden?: boolean;
  isMulti?: boolean;
  isSearchable?: boolean;
  menuHeight?: string; // Height of the menuList
  noUnderline?: boolean;
  onBlur?: FocusEventHandler | undefined;
  onChange?:
    | ((value: OnChangeValue<OptionType, boolean>, action: ActionMeta<OptionType>) => void)
    | undefined;
  onMenuClose?: (() => void) | undefined;
  onMenuOpen?: (() => void) | undefined;
  openOnTop?: 'top' | 'bottom';
  options?: OptionType[];
  placeholder?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  styles?: any;
  theme: Theme;
  value?: OptionType[] | OptionType | null | undefined;
  withParagraphOptions?: boolean; // allow multiline options text
  closeMenuOnSelect?: boolean;
  isClearable?: boolean;
};

const Select = ({
  autoFocus = false,
  components = {},
  customData,
  filterOption,
  fontSizeValue,
  formatOptionLabel,
  getOptionValue,
  hideSelectedOptions,
  inputRef = null,
  isBoldValue,
  isDisabled,
  isHidden = true,
  isMulti,
  isSearchable = false,
  menuHeight = '500px',
  noUnderline,
  onBlur,
  onChange,
  onMenuClose,
  onMenuOpen,
  openOnTop,
  options,
  placeholder,
  styles,
  theme,
  value,
  withParagraphOptions,
  closeMenuOnSelect,
  isClearable,
}: SelectProps) => {
  const SingleValue = components.SingleValue || reactSelectComponents.SingleValue;
  const MultiValueLabel = components.MultiValueLabel || MultiValueLabelDefault;
  const MultiValueContainer = components.MultiValueContainer || MultiValueContainerDefault;
  const MultiValueRemove = components.MultiValueRemove || MultiValueRemoveDefault;
  const Placeholder = components.Placeholder || PlaceholderDefault;
  const ValueContainer = components.ValueContainer || reactSelectComponents.ValueContainer;
  const IndicatorsContainer =
    components.IndicatorsContainer || reactSelectComponents.IndicatorsContainer;
  const DropdownIndicator = components.DropdownIndicator || GenericDropdownIndicator;
  const MenuList = components.MenuList || reactSelectComponents.MenuList;
  const Option = components.Option || reactSelectComponents.Option;
  const Menu = components.Menu || reactSelectComponents.Menu;
  const Input = components.Input || reactSelectComponents.Input;
  const NoOptionsMessage = components.NoOptionsMessage || reactSelectComponents.NoOptionsMessage;
  const Control = components.Control || reactSelectComponents.Control;
  const ClearIndicator = components.ClearIndicator || reactSelectComponents.ClearIndicator;

  const customStylesWithProps = useMemo(() => {
    return {
      ...customStyles(theme, withParagraphOptions),
      ...(styles || {}),
    };
  }, [styles, theme, withParagraphOptions]);

  return (
    <Container noUnderline={noUnderline} data-testid="component-select" isDisabled={isDisabled}>
      <ReactSelect
        components={
          {
            inputRef, // Doesn't seem to be supported
            NoOptionsMessage,
            Control,
            MenuList,
            Menu,
            SingleValue,
            DropdownIndicator,
            MultiValueLabel,
            MultiValueContainer,
            MultiValueRemove,
            Placeholder,
            ValueContainer,
            IndicatorsContainer,
            Option,
            Input,
            GroupHeading,
            ClearIndicator,
          } as Partial<SelectComponentType>
        }
        autoFocus={autoFocus}
        controlShouldRenderValue={true}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore - customData legacy prop that is not part of react-select (bloom custom data..)
        customData={customData}
        filterOption={filterOption}
        fontSizeValue={fontSizeValue}
        formatOptionLabel={formatOptionLabel}
        getOptionValue={getOptionValue}
        hideSelectedOptions={hideSelectedOptions}
        isBoldValue={isBoldValue}
        isDisabled={isDisabled}
        isHidden={isHidden}
        isMulti={isMulti}
        isSearchable={isSearchable}
        menuHeight={menuHeight}
        menuPlacement={openOnTop ? 'top' : 'bottom'}
        onBlur={onBlur}
        onChange={onChange}
        onMenuClose={onMenuClose}
        onMenuOpen={onMenuOpen}
        options={options}
        placeholder={placeholder}
        styles={customStylesWithProps}
        value={value}
        closeMenuOnSelect={closeMenuOnSelect}
        isClearable={isClearable}
      />
    </Container>
  );
};

export default withTheme(Select);

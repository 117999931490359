export default (props) => (
  <svg viewBox="0 0 22 22" {...props}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <rect fillOpacity="0" fill={props.fill} x="0" y="0" width="22" height="22" />
      <path
        d="M10.301396,3.84146111 L16.8414611,3.84146111 C17.3937459,3.84146111 17.8414611,4.28917636 17.8414611,4.84146111 L17.8414611,16.8414611 C17.8414611,17.3937459 17.3937459,17.8414611 16.8414611,17.8414611 L10.301396,17.8414611 C10.0094711,17.8414611 9.73212163,17.7138984 9.54213943,17.4922525 L4.39928229,11.4922525 C4.07829109,11.1177628 4.07829109,10.5651595 4.39928229,10.1906697 L9.54213943,4.19066974 C9.73212163,3.96902384 10.0094711,3.84146111 10.301396,3.84146111 Z M10.301396,4.84146111 L5.15853889,10.8414611 L10.301396,16.8414611 L16.8414611,16.8414611 L16.8414611,4.84146111 L10.301396,4.84146111 Z"
        fill={props.fill}
        transform="translate(11.000000, 10.841461) rotate(-270.000000) translate(-11.000000, -10.841461) "
      />
    </g>
  </svg>
);

import { lazy } from 'react';

// code coming from this gist
// https://gist.github.com/raphael-leger/4d703dea6c845788ff9eb36142374bdb

const LOCAL_STORAGE_REFRESH_KEY = 'force-refreshed';

export const lazyWithRetry = (componentImport) =>
  lazy(async () => {
    const pageHasAlreadyBeenForceRefreshed =
      getLocalStorageItem(LOCAL_STORAGE_REFRESH_KEY) || false;

    try {
      const component = await componentImport();

      window.localStorage.setItem(LOCAL_STORAGE_REFRESH_KEY, 'false');

      return component;
    } catch (error) {
      if (!pageHasAlreadyBeenForceRefreshed) {
        // Assuming that the user is not on the latest version of the application.
        // Let's refresh the page immediately.
        window.localStorage.setItem(LOCAL_STORAGE_REFRESH_KEY, 'true');
        return window.location.reload();
      }

      // The page has already been reloaded
      // Assuming that user is already using the latest version of the application.
      // Let's let the application crash and raise the error.
      throw error;
    }
  });

function getLocalStorageItem(key) {
  try {
    return JSON.parse(window.localStorage.getItem(key));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    return null;
  }
}

import { css } from '@emotion/css/macro';
import styled from '@emotion/styled/macro';
import PropTypes from 'prop-types';
import { Children, cloneElement, useEffect, useRef, useState } from 'react';

const Container = styled.div(
  ({ zIndex }) => css`
    position: sticky;
    top: 0px;
    z-index: ${zIndex};
  `
);

/**
 * This component is sticky and passes down the isStuck property to all its children
 * - isStuck = true means that the component reached its top position
 * - isStuck = false means that the component is not yet at its top position
 *
 * the method used here relies on scroll listeners, it would probably be better to do it with this method :
 * https://developers.google.com/web/updates/2017/09/sticky-headers
 *
 * By default we assume that the parent that scrolls is the window.
 * if parentIsWindow = false we assume its the first parent of [StickyContainer]
 * we could enrich that to allow passing the parent scroller ref as a prop
 */

const StickyContainer = ({ children, top = 0, zIndex = 0, parentIsWindow = true }) => {
  const [isStuck, setStuck] = useState(false);
  const ref = useRef();

  useEffect(() => {
    const cachedRef = ref.current;
    if (!cachedRef) return;

    const scroller = parentIsWindow ? window : cachedRef.parentElement;

    const onScroll = () => {
      const elementDistanceToParentTop = parentIsWindow
        ? cachedRef.getBoundingClientRect().y
        : cachedRef.getBoundingClientRect().y - scroller.getBoundingClientRect().y;
      const newIsStuck = Math.floor(elementDistanceToParentTop) <= top;

      if (isStuck !== newIsStuck) setStuck(newIsStuck);
    };

    scroller.addEventListener('scroll', onScroll, true);
    return function () {
      scroller.removeEventListener('scroll', onScroll);
    };
  }, [top, isStuck, parentIsWindow]);
  return (
    <Container ref={ref} zIndex={zIndex}>
      {Children.map(children, (child) => {
        return cloneElement(child, {
          isStuck,
        });
      })}
    </Container>
  );
};

StickyContainer.propTypes = {
  children: PropTypes.node,
  top: PropTypes.number,
  zIndex: PropTypes.number,
  parentIsWindow: PropTypes.bool,
};

export default StickyContainer;

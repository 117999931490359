import { Icon, IconName } from '@bloom/ui';
import { css } from '@emotion/css/macro';
import styled from '@emotion/styled/macro';
import { Paragraph2 } from 'components/text/Paragraph';
import PropTypes from 'prop-types';

const hover = ({ theme }) => css`
  &:hover {
    cursor: pointer;
    div {
      color: ${theme.mainColor};
    }
    svg {
      * {
        fill: ${theme.mainColor};
      }
    }
  }
`;

const IconCircle = styled.div`
  cursor: pointer;
  border: 1px solid ${(props) => props.theme.exportBtn.colors.border};
  border-radius: 12px;
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${hover};
`;
const IconStandard = styled.div`
  display: flex;
  align-items: center;
  opacity: 1;
  color: white;
  ${hover};
`;

const Label = styled(Paragraph2)`
  color: ${({ theme }) => theme.mainColorLight};
  margin-left: 5px;
`;

const ExportCsvContent = ({ onClick, isCircled = false, label }) => {
  let IconWraper = isCircled ? IconCircle : IconStandard;

  return (
    <IconWraper className={'export-icon'} onClick={onClick}>
      <Icon name={IconName.Download} />
      {label && <Label>{label}</Label>}
    </IconWraper>
  );
};

ExportCsvContent.propTypes = {
  isCircled: PropTypes.bool, // wether the icon is in a circle
  label: PropTypes.string, // a label to display next to the icon
  onClick: PropTypes.func,
};

export default ExportCsvContent;

import { css } from '@emotion/css/macro';
import styled from '@emotion/styled/macro';

const arrowWidth = 3.5;
const arrowBase = 2.5;

const AxisLine = styled.path(
  ({ theme, isDetailed }) => css`
    stroke: ${isDetailed ? theme.primaryHighContrast : theme.mainColor};
    opacity: ${theme.lightOpacity};
  `
);

const AxisArrow = styled.path(
  ({ theme, isDetailed }) => css`
    fill: ${isDetailed ? theme.primaryHighContrast : theme.mainColor};
    opacity: ${theme.lightOpacity};
  `
);

const Text = styled.text(
  ({ theme, anchor }) => css`
    font-size: ${theme.paragraph6.fontSize};
    font-weight: ${theme.paragraph6.fontWeight};
    fill: ${theme.mainColor};
    opacity: ${theme.lightOpacity};
    text-anchor: ${anchor || 'start'};
  `
);

const XAxis = ({ x, y, width, min, max, isDetailed }) => {
  const textOffset = 15;

  return (
    <g>
      <Text x={x} y={y} dy={textOffset}>
        {min}
      </Text>
      <Text x={width} y={y} anchor="end" dy={textOffset}>
        {max}
      </Text>
      <AxisLine d={`M ${x} ${y - 0.5} H ${width}`} isDetailed={isDetailed} />
      <AxisArrow
        isDetailed={isDetailed}
        d={`
          M ${width} ${y - arrowBase - 0.5}
          l ${arrowWidth} ${arrowBase}
          l ${-arrowWidth} ${arrowBase} z
        `}
      />
    </g>
  );
};

const YAxis = ({ x, y, height, min, max, isDetailed }) => {
  return (
    <g>
      <Text x={x - 5} y={height} anchor="end">
        {min}
      </Text>
      <Text x={x} y={y - 7.5} anchor="middle">
        {max}
      </Text>
      <AxisLine d={`M ${x - 0.5} ${y} V ${height}`} isDetailed={isDetailed} />
      <AxisArrow
        isDetailed={isDetailed}
        d={`
          M ${x - arrowBase - 0.5} ${y}
          h ${arrowBase * 2}
          l ${-arrowBase} ${-arrowWidth} z
        `}
      />
    </g>
  );
};

export { YAxis, XAxis };

import styled from '@emotion/styled/macro';
import { computeBackground } from 'theme/functions';

const Item = styled.div`
  margin: 15px 0;
  padding: 8px 20px;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  background: ${({ theme }) => computeBackground(theme.cards.colors.background)};
  color: ${({ theme }) => theme.cards.colors.text};
`;

const ListItem = ({ children }) => <Item>{children}</Item>;

export default ListItem;

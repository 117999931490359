import { css, withTheme } from '@emotion/react';
import styled from '@emotion/styled/macro';
import Icon from 'components/Icon';
import { Paragraph3 } from 'components/text/Paragraph';
import type { FC } from 'react';
import type { Theme } from 'theme/theme';

const Container = styled.div<{ disabled?: boolean; isBackLink?: boolean }>(
  ({ disabled = false, isBackLink = false }) => css`
    display: flex;
    align-items: center;
    flex-direction: ${isBackLink ? 'row-reverse' : 'row'};
    cursor: ${disabled ? 'initial' : 'pointer'};
  `
);

const backLinkColor = (theme: Theme, isBackLink: boolean) => {
  return isBackLink ? theme.accentColor : theme.mainColor;
};
const Title = styled(Paragraph3)<{ disabled: boolean; isBackLink?: boolean }>(
  ({ disabled = false, isBackLink = false, theme }) => css`
    color: ${disabled ? theme.gray400 : backLinkColor(theme, isBackLink)};
    flex-grow: 1;
    margin-left: ${isBackLink ? '10px' : '0px'};
    text-decoration: ${isBackLink ? 'underline' : 'none'};
  `
);

export type TitledLinkProps = {
  title: string;
  disabled?: boolean;
  testId?: string; // id for e2e tests.
  onClick(): void;
  theme: Theme;
  isBackLink?: boolean;
};
const TitledLink: FC<TitledLinkProps> = ({
  disabled = false,
  testId,
  title,
  onClick,
  theme,
  isBackLink = false,
}) => {
  return (
    <Container
      data-testid={`${testId}-wrapper`}
      onClick={disabled ? () => null : onClick}
      isBackLink={isBackLink}
      disabled={disabled}
    >
      <Title disabled={disabled} data-testid={`${testId}-title`} isBackLink={isBackLink}>
        {title}
      </Title>
      <Icon
        width={12}
        height={12}
        type={isBackLink ? 'arrow-left' : 'arrow-right'}
        color={disabled ? theme.gray400 : theme.mainColor}
      />
    </Container>
  );
};

export default withTheme(TitledLink);

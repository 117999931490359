//////// fonts values ////////
const title0 = {
  fontWeight: 300,
  fontSize: '31px',
  lineHeight: '28px',
};
const title1 = {
  fontWeight: 400,
  fontSize: '21px',
  lineHeight: '28px',
};
const title2 = {
  fontWeight: 400,
  fontSize: '18px',
  lineHeight: '24px',
};
const title3 = {
  fontWeight: 100,
  fontSize: '38px',
  lineHeight: '44px',
};
const title4 = {
  fontWeight: 300,
  fontSize: '18px',
  lineHeight: '24px',
};
const title5 = {
  fontSize: '16px',
  fontWeight: 700,
  lineHeight: '24px',
  margin: 0,
};
const title6 = {
  fontSize: '14px',
  fontWeight: 700,
  lineHeight: '17px',
  margin: 0,
};
const paragraph1 = {
  fontWeight: 300,
  fontSize: '15px',
  lineHeight: '22px',
};
const paragraph2 = {
  fontWeight: 500,
  fontSize: '13px',
  lineHeight: '19.5px',
};
const paragraph3 = {
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '19.5px',
};
const paragraph4 = {
  fontWeight: 400,
  fontSize: '13px',
  lineHeight: '19.5px',
  fontStyle: 'italic',
};
const paragraph5 = {
  fontWeight: 700,
  fontSize: '11px',
  lineHeight: '13px',
};
const paragraph6 = {
  fontWeight: 400,
  fontSize: '11px',
  lineHeight: '13px',
};
const paragraph7 = {
  fontWeight: 700,
  fontSize: '12px',
  lineHeight: '16px',
};
const label1 = {
  textTransform: 'uppercase',
  fontWeight: 400,
  fontSize: '13px',
  letterSpacing: '1.47px',
  lineHeight: 'normal',
};
const label2 = {
  textTransform: 'uppercase',
  fontWeight: 400,
  fontSize: '11px',
  letterSpacing: '1px',
  lineHeight: 'normal',
};
const label3 = {
  textTransform: 'uppercase',
  fontWeight: 400,
  fontSize: '9px',
  letterSpacing: '0.82px',
  lineHeight: '11px',
};
const label4 = {
  color: '#9999B0',
  fontSize: '12px',
  fontWeight: 400,
  letterSpacing: '0.818182px',
  lineHeight: '15px',
  textTransform: 'uppercase',
};
const tabActive = {
  fontWeight: 500,
  fontSize: '11px',
  lineHeight: 'normal',
};
const tabInactive = {
  fontWeight: 400,
  fontSize: '11px',
  lineHeight: 'normal',
};
const bigFigureSize = '36px';

export default {
  title0,
  title1,
  title2,
  title3,
  title4,
  title5,
  title6,
  paragraph1,
  paragraph2,
  paragraph3,
  paragraph4,
  paragraph5,
  paragraph6,
  paragraph7,
  label1,
  label2,
  label3,
  label4,
  tabActive,
  tabInactive,
  bigFigureSize,
};

export default (props) => (
  <svg {...props} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="11" cy="11" r="11" fill={props.fill} />
    <path
      d="M9.25 4.875C6.35025 4.875 4 6.83413 4 9.25C4 10.643 4.78575 11.8785 6.00462 12.6783L5.3125 14.0625L7.19812 13.2549C7.60238 13.3984 8.02238 13.5165 8.47125 13.5716C8.41 13.3048 8.375 13.0309 8.375 12.75C8.375 10.3376 10.7296 8.375 13.625 8.375C13.8901 8.375 14.1491 8.39687 14.4046 8.42837C13.9426 6.40537 11.812 4.875 9.25 4.875ZM7.5 8.15625C7.13775 8.15625 6.84375 7.86225 6.84375 7.5C6.84375 7.13775 7.13775 6.84375 7.5 6.84375C7.86225 6.84375 8.15625 7.13775 8.15625 7.5C8.15625 7.86225 7.86225 8.15625 7.5 8.15625ZM11 8.15625C10.6378 8.15625 10.3438 7.86225 10.3438 7.5C10.3438 7.13775 10.6378 6.84375 11 6.84375C11.3622 6.84375 11.6562 7.13775 11.6562 7.5C11.6562 7.86225 11.3622 8.15625 11 8.15625Z"
      fill="#000039"
    />
    <path
      d="M18 12.75C18 10.8171 16.0409 9.25 13.625 9.25C11.2091 9.25 9.25 10.8171 9.25 12.75C9.25 14.6829 11.2091 16.25 13.625 16.25C14.0223 16.25 14.4003 16.194 14.7669 16.1144L17.125 17.125L16.3095 15.4949C17.3315 14.8544 18 13.8691 18 12.75ZM12.3125 12.5312C11.9502 12.5312 11.6562 12.2373 11.6562 11.875C11.6562 11.5127 11.9502 11.2188 12.3125 11.2188C12.6748 11.2188 12.9688 11.5127 12.9688 11.875C12.9688 12.2373 12.6748 12.5312 12.3125 12.5312ZM14.9375 12.5312C14.5753 12.5312 14.2812 12.2373 14.2812 11.875C14.2812 11.5127 14.5753 11.2188 14.9375 11.2188C15.2997 11.2188 15.5938 11.5127 15.5938 11.875C15.5938 12.2373 15.2997 12.5312 14.9375 12.5312Z"
      fill="#000039"
    />
  </svg>
);

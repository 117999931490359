import { Roles } from '@bloom-hub/shared';
import { lazyWithRetry } from 'shared/lazyWithRetry';

const LazyLogin = lazyWithRetry(() => import('routes/Login'));
const LazyConnectedApp = lazyWithRetry(() => import('routes/ConnectedApp'));

const appRoutes = {
  routes: [
    {
      path: '/login',
      exact: true,
      Component: LazyLogin,
    },
    {
      path: '',
      roles: Roles.ALL,
      Component: LazyConnectedApp,
    },
  ],
  defaultPath: '/projects',
};

export default appRoutes;

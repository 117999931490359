export default (props) => (
  <svg
    {...props}
    width="12"
    height="9"
    viewBox="0 0 12 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1 4.65387L3.90906 7.5L11 0.5" strokeWidth="1.3" strokeLinejoin="round" />
  </svg>
);

import { cache } from '@emotion/css/macro';
import { CacheProvider, ThemeProvider as EmotionThemeProvider } from '@emotion/react';
import { createContext, useReducer } from 'react';

import cobalt from './cobalt';
import dark from './dark';
import light from './light';

export const THEME_VALUES = {
  COBALT: 'cobalt',
  DARK: 'dark',
  LIGHT: 'light',
};

const theme = {
  light,
  dark,
  cobalt,
};

export const listOfUrlsOnCobaltTheme = [
  '/communities',
  '/legal',
  '/profile',
  '/configuration',
  '/scope',
  '/inauthentic-behavior-detector',
];
export const listOfStrictUrlsOnCobaltTheme = ['/projects', '/projects/', '/', '/projects/new'];
export const listOfUrlsOnLightTheme = [
  'data-viz/content-analysis',
  'data-viz/actor-analysis',
  'data-viz/communities-profiling',
];

const initialTheme = theme.cobalt;

export const ThemeContext = createContext(initialTheme);

const reducer = (state, newColor) => {
  if (state.name === newColor) return state;
  return {
    ...theme[newColor],
    name: newColor,
  };
};

const ThemeProvider = ({ children }) => {
  const [activeTheme, switchTo] = useReducer(reducer, initialTheme);
  const { name } = activeTheme;

  return (
    <EmotionThemeProvider theme={activeTheme}>
      <CacheProvider value={cache}>
        <ThemeContext.Provider value={{ name, switchTo }}>{children}</ThemeContext.Provider>
      </CacheProvider>
    </EmotionThemeProvider>
  );
};

const ThemeOverride = ({ theme: themeName, children }) => (
  <EmotionThemeProvider
    theme={{
      ...theme[themeName],
      name: theme,
    }}
  >
    {children}
  </EmotionThemeProvider>
);

export { ThemeProvider, ThemeOverride };

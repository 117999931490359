import { Button } from '@bloom/ui';
import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';
import { forwardRef } from 'react';

import Icon from '../Icon';

const StatusIcon = styled(Icon)(
  () => css`
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  `
);

export enum STATUS {
  READY = 'ready',
  RUNNING = 'running',
  STOPPED = 'stopped',
  FINISHED = 'finished',
}

const colors: Record<STATUS, string> = {
  [STATUS.READY]: '#FFFFFF',
  [STATUS.RUNNING]: '#FFD569',
  [STATUS.STOPPED]: '#FA444B',
  [STATUS.FINISHED]: '#78FF96',
};

type ButtonWithStatusProps = {
  status?: STATUS;
  counter?: number;
  showCounter?: boolean;
  label?: string;
};

const ButtonWithStatus = forwardRef<HTMLAnchorElement, ButtonWithStatusProps>(
  (
    { status = STATUS.READY, counter = 0, label = 'button', showCounter = false, ...otherProps },
    ref
  ) => (
    <span ref={ref} {...otherProps}>
      <Button
        leftComponent={<StatusIcon width={22} height={22} type="status" color={colors[status]} />}
      >
        {label} {showCounter ? `(${counter})` : ''}
      </Button>
    </span>
  )
);

export default ButtonWithStatus;

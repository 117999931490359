import {
  differenceInDays,
  differenceInMonths,
  differenceInQuarters,
  differenceInWeeks,
  format,
  getISOWeek,
  getQuarter,
  isEqual,
  isFirstDayOfMonth,
  isMonday,
  parseISO,
  startOfQuarter,
  subDays,
} from 'date-fns';
import _ from 'lodash';
import { computePeriodTimeSpan } from 'routes/Projects/shared/timePeriodUtils';

import wording from './wording.json';

const DEFAULT_RULES = {
  disabled: null,
  warning: null,
};

const PERIOD_TYPES_ORDER = ['custom', 'weekly', 'monthly', 'quarterly'];

const computeTimePeriodLabel = ([fromDate, toDate]) => {
  const fromDateIso = typeof fromDate === 'string' ? parseISO(fromDate) : fromDate;
  const toDateIso = typeof toDate === 'string' ? parseISO(toDate) : toDate;
  const diffInDays = differenceInDays(toDateIso, fromDateIso);

  if (diffInDays === 1) return format(fromDateIso, 'dd MMM yyyy');
  if (
    differenceInWeeks(toDateIso, fromDateIso) === 1 &&
    isMonday(fromDateIso) &&
    isMonday(toDateIso)
  )
    return `${format(fromDateIso, 'dd MMM yyyy')} (week ${getISOWeek(fromDateIso)})`;
  if (
    differenceInMonths(toDateIso, fromDateIso) === 1 &&
    isFirstDayOfMonth(fromDateIso) &&
    isFirstDayOfMonth(toDateIso)
  )
    return format(fromDateIso, 'MMMM yyyy');
  if (
    differenceInQuarters(toDateIso, fromDateIso) === 1 &&
    isEqual(startOfQuarter(fromDateIso), fromDateIso) &&
    isEqual(startOfQuarter(toDateIso), toDateIso)
  )
    return `Q${getQuarter(fromDateIso)} ${format(fromDateIso, 'yyyy')}`;

  const period = `${format(fromDateIso, 'dd MMM yyyy')} - ${format(
    subDays(toDateIso, 1),
    'dd MMM yyyy'
  )}`;
  const timeSpan = computePeriodTimeSpan({ fromDateIso, toDateIso, diffInDays });
  return `${period} ${timeSpan}`;
};

/**
 * Takes the options object returned by the API and transforms it into the structure needed by the front
 * source structure :
  [
    {
      fromDate: '2019-07-05T05:52:44',
      toDate: '2019-07-12T05:52:44',
      type: 'weekly',
      status: 'done',
      id: 0
    },
    {
      fromDate: '2019-02-21T13:43:37',
      toDate: '2019-02-28T13:43:37',
      type: 'weekly',
      status: 'done',
      id: 1
    },
  ]
 * target structure :
  [
    {
      id: 'weekly',
      label: 'weekly',
      options: [
        {
          type: 'weekly',
          value: 0,
          label: '05 Jul 2019 - 12 Jul 2019',
        },
        {
          type: 'weekly',
          value: 1,
          label: '21 Feb 2019 - 28 Feb 2019',
        },
      ],
    },
  ],
 * @param {Object} timePeriodOptions the options to format
 * @returns {Object} the formated options
 */
const formatTimePeriodsFromQuery = (timePeriodOptions) => {
  const types = timePeriodOptions.reduce((result, timePeriod) => {
    if (!_.includes(result, timePeriod.type)) result.push(timePeriod.type);
    return result;
  }, []);
  const timePeriodsFormated = types.map((type) => ({
    id: type,
    label: wording.timePeriodTypes[type],
    options: _(timePeriodOptions)
      .filter({ type })
      .orderBy('fromDate', 'desc')
      .map((timePeriod) => ({
        type: type,
        value: [timePeriod.fromDate, timePeriod.toDate].join(','),
        label: computeTimePeriodLabel([timePeriod.fromDate, timePeriod.toDate]),
      }))
      .value(),
  }));
  return _.sortBy(timePeriodsFormated, (timePeriod) =>
    _.indexOf(PERIOD_TYPES_ORDER, timePeriod.id)
  );
};

/**
 * filter timePeriodOptions to keep in a simple array the options that belong to
 * basePeriod type and that are older than basePeriod. Add a null entry at the end of the list
 * @param {Array} options.timePeriodOptions
 * @param {string} options.basePeriod
 * @returns {Array}
 */
const getComparisonTimePeriodOptions = ({ timePeriodOptions, basePeriod }) => {
  const basePeriodTypeEntry = _.filter(
    timePeriodOptions,
    (option) => option.id === basePeriod.type
  );
  const basePeriodTypeOptions = _.get(basePeriodTypeEntry, '[0].options', []);
  const basePeriodIndice = _.findIndex(basePeriodTypeOptions, basePeriod);
  const basePeriodTypeOptionsTruncated = _.slice(
    basePeriodTypeOptions,
    basePeriodIndice + 1,
    _.size(basePeriodTypeOptions)
  );
  return [
    ...basePeriodTypeOptionsTruncated,
    {
      value: null,
      label: wording.emptyTimePeriodLabel,
    },
  ];
};

/**
 * Return the default option
 * @param {Object} timePeriodOptions
 * @returns {Object} the default option object
 */
const getDefaultBasePeriod = (timePeriodOptions) => {
  return _.get(timePeriodOptions, '[0].options[0]');
};

/**
 * Return the default option
 * @param {*} timePeriodOptions
 * @returns {Object} the default option object
 */
const getDefaultComparisonPeriod = (comparisonTimePeriodOptions) => {
  return _.first(comparisonTimePeriodOptions);
};

/**
 * For each rule returns the given one or the default if no given one
 * @param {Object} rules a set of rules
 * @returns {Object} the rules with defaults [DEFAULT_RULES]
 */
const getDefaultTimePeriodRules = (rules) => ({
  disabled: _.get(rules, 'disabled', DEFAULT_RULES.disabled),
  warning: _.get(rules, 'warning', DEFAULT_RULES.warning),
});

const formatTimePeriodsForServer = ({ timePeriods, skipComparisonPeriod }) => {
  const basePeriod = _.get(timePeriods, 'basePeriod.value');
  const comparisonPeriod = skipComparisonPeriod
    ? undefined
    : _.get(timePeriods, 'comparisonPeriod.value');
  return {
    basePeriod: basePeriod && basePeriod.split(','),
    comparisonPeriod: comparisonPeriod && comparisonPeriod.split(','),
  };
};

const formatTimePeriodsForUrl = (timePeriods) => {
  const { basePeriod, comparisonPeriod } = timePeriods;
  return {
    basePeriod: basePeriod && basePeriod.value,
    comparisonPeriod: comparisonPeriod && comparisonPeriod.value,
  };
};

const parseTimePeriodFromUrl = (timePeriod) => {
  if (!timePeriod) return undefined;
  return {
    value: timePeriod,
    label: computeTimePeriodLabel(timePeriod.split(',')),
  };
};

const parseTimePeriodsFromUrl = (queryParams) => {
  return {
    basePeriod: parseTimePeriodFromUrl(queryParams.basePeriod),
    comparisonPeriod: parseTimePeriodFromUrl(queryParams.comparisonPeriod),
  };
};

const getPeriodFromOptions = ({ period, timePeriodOptions }) => {
  for (const timeFrame of timePeriodOptions) {
    const resultPeriod = _.find(timeFrame.options, { value: period.value });
    if (resultPeriod) return resultPeriod;
  }
  return period;
};

export {
  computeTimePeriodLabel,
  formatTimePeriodsForServer,
  formatTimePeriodsForUrl,
  formatTimePeriodsFromQuery,
  getComparisonTimePeriodOptions,
  getDefaultBasePeriod,
  getDefaultComparisonPeriod,
  getDefaultTimePeriodRules,
  getPeriodFromOptions,
  parseTimePeriodsFromUrl,
};

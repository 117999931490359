import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';

// NOTE: Why is it not a `<Button>` modifier?

/**
 * A button with a hover opacity effect
 */
const ButtonNude = styled.button(
  ({ theme, disabled }) => css`
    border: none;
    background-color: transparent;
    cursor: ${disabled ? 'not-allowed' : 'pointer'};
    opacity: ${disabled ? theme.opacity : 1};
    &:hover {
      opacity: ${disabled ? theme.opacity : 1};
    }
  `
);

export default ButtonNude;

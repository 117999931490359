import LABELS from 'routes/Projects/Project/DataViz/shared/labels';
import commonComponentsValues from 'theme/common/componentsValues';
import { lighten } from 'theme/functions';

import darkValues from './values';

export default {
  tabs: {
    height: '60px',
  },
  scrollbar: {
    ...commonComponentsValues.scrollbar,
    cursorColor: darkValues.primary,
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
  },
  navLinearProgress: {
    ...commonComponentsValues.navLinearProgress,
    color: '#3657CF',
  },
  menu: {
    ...commonComponentsValues.menu,
    boxShadow: '20px 20px 40px rgba(7, 8, 33, 0.5)',
  },
  dropdowns: {
    colors: {
      options: {
        label: '#8A8A8A',
        badge: '#8A8A8A',
      },
      selection: {
        label: '#ffffff',
        badge: '#aba4bf',
      },
      placeholder: '#aba4bf',
      arrow: '#ffffff',
    },
  },
  login: {
    background: 'white',
    borderRadius: 4,
  },
  formGroup: {
    marginBottom: '1.5rem',
  },
  labels: {
    fontSize: '13px',
    textTransform: 'inital',
    fontStyle: 'italic',
    letterSpacing: 0,
    marginBottom: '5px',
    color: darkValues.fontColor,
  },
  inputs: {
    borderRadius: '17.5px',
    padding: '10px 15px',
    fontSize: '14px',
    fontWeight: 400,
    height: '36px',
    border: '1px solid rgba(255,255,255,.4)',
    borderBottom: '1px solid rgba(255,255,255,.4)',
    placeholder: {
      text: '#aba4bf',
      fontStyle: 'italic',
    },
    colors: {
      text: '#ffffff',
      background: 'transparent',
    },
  },
  cards: {
    borderRadius: '6px',
    padding: '20px',
    marginTop: '15px',
    marginRight: '15px',
    marginBottom: '15px',
    marginLeft: '15px',
    boxShadow: '0 1px 10px rgba(0, 0, 0, 0.1)',
    header: {
      marginBottom: '30px',
      background: 'none',
      textColor: '#c3c3c3',
    },
    colors: {
      text: '#ffffff',
      border: 'rgba(255, 255, 255, 0.2)',
      outerBorder: 'rgba(255, 255, 255, 0.2)',
      background: ['-45deg', '#32254C', '#493869'],
      hover: {
        border: 'rgba(255, 255, 255, 0.2)',
        background: ['-45deg', '#32254C', '#493869'],
      },
    },
  },
  header: {
    fontSize: '24px',
    lineHeight: '28px',
    fontWeight: 700,
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.1)',
    padding: '16px',
    colors: {
      text: '#ffffff',
      border: '#473769',
      background: '#4c3970',
    },
  },
  buttons: {
    primary: {
      color: '#ffffff',
      border: '#5e4b8a',
      background: '#5e4b8a',
      hover: {
        color: lighten('#ffffff', 5),
        border: lighten('#5e4b8a', 5),
        background: lighten('#5e4b8a', 5),
      },
      active: {
        color: lighten('#ffffff', -5),
        border: lighten('#f3c600', -5),
        background: lighten('#f3c600', -5),
      },
    },
    secondary: {
      color: '#aba4bf',
      border: 'rgba(255,255,255,0.28)',
      background: 'transparent',
      hover: {
        color: '#ffffff',
        border: '#ffffff',
        background: 'transparent',
      },
      active: {
        color: '#ffffff',
        border: '#ffffff',
        background: 'transparent',
      },
    },
    success: {
      color: '#ffffff',
      border: '#6bc996',
      background: '#6bc996',
      hover: {
        color: lighten('#ffffff', 5),
        border: lighten('#6bc996', 5),
        background: lighten('#6bc996', 5),
      },
      active: {
        color: lighten('#ffffff', -5),
        border: lighten('#6bc996', -5),
        background: lighten('#6bc996', -5),
      },
    },
    danger: {
      color: '#ffffff',
      border: '#f25757',
      background: '#f25757',
      hover: {
        color: lighten('#ffffff', 5),
        border: lighten('#f25757', 5),
        background: lighten('#f25757', 5),
      },
      active: {
        color: lighten('#ffffff', -5),
        border: lighten('#f25757', -5),
        background: lighten('#f25757', -5),
      },
    },
    info: {
      color: '#ffffff',
      border: '#515979',
      background: '#515979',
      hover: {
        color: lighten('#ffffff', 5),
        border: lighten('#515979', 5),
        background: lighten('#515979', 5),
      },
      active: {
        color: lighten('#ffffff', -5),
        border: lighten('#515979', -5),
        background: lighten('#515979', -5),
      },
    },
  },
  charts: {
    colors: {
      label: '#ffffff',
      scale: ['#dac9ff', '#9f76f3', '#737373', '#969696', '#bdbdbd', '#d9d9d9', '#f0f0f0'],
      selected: 'rgba(45,33,68,.2)',
      bars: {
        background: 'rgba(45,33,68,0.2)',
      },
      tickBackground: 'rgba(255, 255, 255, 0.10)',
      emotions: {
        negative: '#db5353',
        positive: '#447c5f',

        [LABELS.NOT_CLASSIFIED]: '#999999',
        confiance: '#BED09B',
        joie: '#FFE455',
        anticipation: '#F4D1BB',
        colère: '#FA9D74',
        déception: '#D3C9E6',
        tristesse: '#747A9C',
        peur: '#6E987E',
        surprise: '#BDDCEE',

        scales: {
          [LABELS.NOT_CLASSIFIED]: ['#586289', '#C2CBEB'],
          joie: ['#64a684', '#447c5f'],
          confiance: ['#64a684', '#447c5f'],
          anticipation: ['#dac9ff', '#9f76f3'],
          surprise: ['#dac9ff', '#9f76f3'],
          colère: ['#f89797', '#db5353'],
          déception: ['#f89797', '#db5353'],
          tristesse: ['#f89797', '#db5353'],
          peur: ['#f89797', '#db5353'],
        },
      },
    },
  },
  documentList: {
    colors: {
      border: 'rgba(255, 255, 255, 0.2)',
      text: '#ffffff',
      subText: '#aba4bf',
      externalLink: '#aba4bf',
      arrow: '#ffffff',
      childrenBackground: '#524175',
      childrenHover: '#524175',
      tableHeadBackground: '#56437a',
      tableHead: '#ffffff',
    },
  },
  pagination: {
    colors: {
      text: '#ffffff',
      background: 'transparent',
      border: 'rgba(255, 255, 255, 0.25)',
    },
  },
  select: {
    colors: {
      text: '#ffffff',
      arrow: '#ffffff',
      background: 'transparent',
      border: 'rgba(255, 255, 255, 0.25)',
    },
  },
  exportBtn: {
    colors: {
      border: '#ffffff',
      icon: '#ffffff',
    },
  },
  colors: {
    text: '#ffffff',
    subText: '#aba4bf',
    background: ['-45deg', '#231739', '#7f67ac'],
    alternateBackground: '#56437a',
    dashboardFiltersBg: '#4c3970',
    spacerBorderColor: 'rgba(255, 255, 255, 0.25)',
    spacerArrowColor: '#544274',
  },
  spiner: {
    color: '#9e9e9e',
  },
};

export default (props) => (
  <svg
    width="9"
    height="10"
    viewBox="0 0 9 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0.5 9L4.5 5M4.5 5L0.5 1M4.5 5L8.5 9M4.5 5L8.5 1"
      stroke={props.fill}
      strokeWidth="1.2"
    />
  </svg>
);

export default (props) => (
  <svg viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g opacity="0.6">
      <rect width="21.7128" height="22" fill={props.color} fillOpacity="0.01" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.3494 13.2929L15.4417 9.14645C15.6344 8.95118 15.9469 8.95118 16.1396 9.14645C16.3323 9.34171 16.3323 9.65829 16.1396 9.85355L11.2049 14.8536C11.0122 15.0488 10.6997 15.0488 10.507 14.8536L5.57227 9.85355C5.37956 9.65829 5.37956 9.34171 5.57227 9.14645C5.76498 8.95118 6.07743 8.95118 6.27014 9.14645L10.3625 13.2929V4H11.3494V13.2929ZM5.42773 18H16.2841V19H5.42773V18Z"
        fill={props.color}
      />
    </g>
  </svg>
);

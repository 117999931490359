export default (props) => (
  <svg {...props} viewBox="0 0 26 26">
    <path
      fill="#515979"
      fillRule="evenodd"
      d="M20.6428571,29 L3.92857143,29 C3.416,29 3,28.584 3,28.0714286 L3,11.3571429 C3,10.8445714 3.416,10.4285714 3.92857143,10.4285714 L20.6428571,10.4285714 C21.1554286,10.4285714 21.5714286,10.8445714 21.5714286,11.3571429 L21.5714286,28.0714286 C21.5714286,28.584 21.1554286,29 20.6428571,29 Z M4.85714286,27.1428571 L19.7142857,27.1428571 L19.7142857,12.2857143 L4.85714286,12.2857143 L4.85714286,27.1428571 Z M24.3571429,25.2857143 C23.8445714,25.2857143 23.4285714,24.8697143 23.4285714,24.3571429 L23.4285714,8.57142857 L7.64285714,8.57142857 C7.13028571,8.57142857 6.71428571,8.15542857 6.71428571,7.64285714 C6.71428571,7.13028571 7.13028571,6.71428571 7.64285714,6.71428571 L24.3571429,6.71428571 C24.8697143,6.71428571 25.2857143,7.13028571 25.2857143,7.64285714 L25.2857143,24.3571429 C25.2857143,24.8697143 24.8697143,25.2857143 24.3571429,25.2857143 Z M28.0714286,21.5714286 C27.5588571,21.5714286 27.1428571,21.1554286 27.1428571,20.6428571 L27.1428571,4.85714286 L11.3571429,4.85714286 C10.8445714,4.85714286 10.4285714,4.44114286 10.4285714,3.92857143 C10.4285714,3.416 10.8445714,3 11.3571429,3 L28.0714286,3 C28.584,3 29,3.416 29,3.92857143 L29,20.6428571 C29,21.1554286 28.584,21.5714286 28.0714286,21.5714286 Z"
      transform="translate(-3 -3)"
    />
  </svg>
);

type Props = {
  color: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

export default ({ color, ...props }: Props) => (
  <svg viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 15H12V16H0V15ZM11.4596 7.5404C11.7135 7.79424 11.7135 8.2058 11.4596 8.45964L6.45962 13.4596C6.33772 13.5815 6.17239 13.65 6 13.65C5.82761 13.65 5.66228 13.5815 5.54038 13.4596L0.54038 8.45964C0.28654 8.2058 0.28654 7.79424 0.54038 7.5404C0.794221 7.28656 1.20578 7.28656 1.45962 7.5404L5.35 11.4308L5.35 1.00002C5.35 0.641036 5.64102 0.350021 6 0.350021C6.35899 0.350021 6.65 0.641036 6.65 1.00002L6.65 11.4308L10.5404 7.5404C10.7942 7.28656 11.2058 7.28656 11.4596 7.5404Z"
      fill={color}
    />
  </svg>
);

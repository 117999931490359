export type LinkProps = {
  fill: string;
};

export default ({ fill, ...props }: LinkProps) => (
  <svg viewBox="0 0 17 16" fill={fill} {...props}>
    <path
      fill={fill}
      d="M6.143 7.529c.755-.756 2.073-.756 2.828 0L9.442 8l.943-.943-.471-.471a3.31 3.31 0 0 0-2.357-.977 3.31 3.31 0 0 0-2.357.977L3.786 8a3.339 3.339 0 0 0 1.08 5.437c.405.167.839.253 1.276.252a3.32 3.32 0 0 0 2.358-.975l.47-.471-.942-.943-.471.471a2.005 2.005 0 0 1-2.829 0 2.003 2.003 0 0 1 0-2.828l1.415-1.414Z"
    />
    <path
      fill={fill}
      d="m8.5 3.286-.472.471.943.943.471-.471a2.005 2.005 0 0 1 2.829 0 2.003 2.003 0 0 1 0 2.828l-1.415 1.414c-.755.756-2.072.756-2.828 0L7.557 8l-.943.943.472.471a3.31 3.31 0 0 0 2.356.977 3.31 3.31 0 0 0 2.357-.977L13.214 8a3.339 3.339 0 0 0 0-4.714 3.337 3.337 0 0 0-4.714 0Z"
    />
  </svg>
);

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

export default ({ fill, ...props }: Props) => (
  <svg fill="none" viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fill={fill}
      fillRule="evenodd"
      d="M6 4.438C6 4.196 6.196 4 6.438 4h9.566v12.128H6V4.438Zm0 12.69h10.004v.876H6.438A.438.438 0 0 1 6 17.566v-.438ZM6.438 3C5.644 3 5 3.644 5 4.438v13.128c0 .794.644 1.438 1.438 1.438h10.566V3H6.438Zm.913 2.813a.5.5 0 0 0-.5.5v2.813a.5.5 0 0 0 .5.5h7.405a.5.5 0 0 0 .5-.5V6.313a.5.5 0 0 0-.5-.5H7.35Zm.5 2.813V6.813h6.405v1.813H7.85Z"
      clipRule="evenodd"
    />
  </svg>
);

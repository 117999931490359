import axios from 'axios';
import { axiosMaintenanceRedirect } from 'shared/maintenanceRedirect';

let config;

const initConfig = () =>
  axios
    .get('/hub-api/react-config')
    .then((response) => {
      config = response.data;
    })
    .catch(axiosMaintenanceRedirect);

const getConfig = () => config;

export { getConfig, initConfig };

import type { StylesConfig } from 'react-select';
import type { Theme } from 'theme/theme';

import type { OptionType } from './option';

/**
 * Returns the color of the option border depending on the option state
 */
const computeOptionBorderColor = (
  /**
   * the css theme
   */
  theme: Theme,
  /**
   * the state of the option
   */
  state: { isSelected: boolean; isFocused: boolean }
): string => {
  if (state.isSelected) {
    return theme.accentColorDark;
  } else if (state.isFocused) {
    return theme.accentColor;
  } else {
    return 'transparent';
  }
};

type AcceptableOptionType = OptionType & {
  isFixed?: boolean;
  options?: unknown;
};

/**
 * Update Custom styles for the differents units of the component.
 *
 * NOTE: Here, we have CSSObject types because this type is expected by styles props of
 * react-select. string (template string like css``) is not accepted by styles. It caused
 * the component to not properly display the styles.
 */
const customStyles = (
  theme: Theme,
  withParagraphOptions: boolean | undefined
): StylesConfig<AcceptableOptionType, boolean> => {
  const baseOptions: StylesConfig<AcceptableOptionType, boolean> = {
    container: (base) => {
      return {
        ...base,
        height: '100%',
        pointerEvents: 'unset',
      };
    },
    placeholder: (base) => {
      return {
        ...base,
        color: theme.mainColorLight,
      };
    },
    singleValue: (base, state) => {
      return {
        color: theme.mainColor,
        fontSize: theme.paragraph2.fontSize,
        lineHeight: theme.paragraph2.lineHeight,
        opacity: state.selectProps.isDisabled ? theme.lightOpacity : 1,
        padding: `${theme.select.internalMargin} ${theme.col}`,
        width: '100%',
      };
    },
    multiValue: (base) => {
      return {
        ...base,
        alignItems: 'center',
        backgroundColor: 'transparent',
      };
    },
    multiValueRemove: (base, state) => {
      return {
        ...base,
        display: state.data.isFixed ? 'none' : 'flex',
        padding: 0,
        ':hover': {
          backgroundColor: 'transparent',
        },
      };
    },
    indicatorSeparator: () => {
      return {};
    },
    valueContainer: (base, state) => {
      return {
        ...base,
        ...(state.isMulti
          ? {
              height: '100%',
              overflowY: 'auto',
              overflowX: 'hidden',
              minWidth: 'min-content',
              padding: `${theme.select.internalMargin} ${theme.col} 0 0`,
            }
          : {
              flexWrap: 'nowrap',
              padding: 0,
            }),
      };
    },
    menu: (base, state) => {
      const openOnTop = state.selectProps.menuPlacement === 'top';
      return {
        ...(openOnTop
          ? {
              bottom: '100%',
            }
          : {
              top: '100%',
            }),
        background: theme.primaryColor,
        left: 0,
        position: 'absolute',
        width: '100%',
        zIndex: 999,
      };
    },
    input: (base) => {
      return {
        ...base,
        color: theme.mainColor,
        fontSize: theme.paragraph2.fontSize,
        marginLeft: theme.select.internalMargin,
        /**
         * there is also a margin depending on the isMulti prop that is set in the [control]
         * component because isMulti is not accessible here
         * margin-bottom: state.selectProps.isMulti ? theme.select.internalMargin : 'Opx';
         */
      };
    },
    menuList: (base, state) => {
      return {
        maxHeight: state.selectProps.maxMenuHeight,
        overflow: 'auto',
        paddingBottom: '5px',
        paddingTop: '5px',
      };
    },
    control: (base, state) => {
      return {
        alignItems: 'center',
        cursor: state.selectProps.isDisabled ? 'not-allowed' : 'pointer',
        display: 'flex',
        height: '100%',
        justifyContent: 'space-between',
        padding: 0,

        input: {
          marginBottom: state.selectProps.isMulti ? theme.select.internalMargin : 'Opx',
        },
      };
    },
    option: (base, state) => {
      const hasGroup = state.options && state.options[0].options;
      return {
        color: theme.mainColor,
        cursor: state.isDisabled ? 'not-allowed' : 'pointer',
        fontSize: '13px',
        lineHeight: '18px',
        opacity: state.isDisabled ? 0.3 : 1,
        overflow: 'hidden',
        padding: `2px ${theme.select.internalMargin} 2px calc(${theme.col} * 2)`,
        position: 'relative',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',

        '&:before': {
          display: 'block',
          content: '""',
          top: '4px',
          bottom: '3px',
          left: theme.col,
          position: 'absolute',
          width: '3px',
          backgroundColor: computeOptionBorderColor(theme, state),
        },
        '&:first-of-type': {
          paddingTop: hasGroup ? 0 : theme.col,
          ':before': {
            top: hasGroup ? '4px' : '18px',
          },
        },
        '&:last-of-type': {
          paddingBottom: hasGroup ? 0 : theme.col,
          ':before': {
            bottom: hasGroup ? '3px' : '16px',
          },
        },
      };
    },
  };

  if (withParagraphOptions) {
    baseOptions['option'] = (base, state) => {
      return {
        cursor: state.isDisabled ? 'not-allowed' : 'pointer',
        padding: `8px ${theme.select.internalMargin} 8px calc(${theme.col} * 2)`,
        fontSize: '13px',
        lineHeight: '18px',
        color: theme.mainColor,
        opacity: state.isDisabled ? 0.3 : 1,
        position: 'relative',

        '&:before': {
          display: 'block',
          content: '""',
          top: '4px',
          bottom: '3px',
          left: theme.col,
          position: 'absolute',
          width: '3px',
          backgroundColor: computeOptionBorderColor(theme, state),
        },
      };
    };

    baseOptions['menu'] = (base, state) => {
      const openOnTop = state.selectProps.menuPlacement === 'top';
      return {
        position: 'absolute',
        ...(openOnTop
          ? {
              bottom: '100%',
            }
          : {
              top: '100%',
            }),
        left: 0,
        zIndex: 999,
        width: '100%',
        background: theme.primaryColor,
        padding: '20px 22px 20px 0',
      };
    };
  }

  return baseOptions;
};

export default customStyles;

export default (props) => (
  <svg fill="none" viewBox="0 0 160 160" {...props}>
    <path
      fill="#5F59FF"
      fillOpacity=".2"
      d="M30.25 93.25V88H25.5v7.5c0 1.25 1 2.5 2.25 2.5 4 0 7 3.25 7 7.5s-3.25 7.5-7 7.5-7-3.25-7-7.5c0-1.25-1-2.5-2.25-2.5s-2.25 1-2.25 2.5c0 6.75 5.25 12.25 11.75 12.25s11.75-5.5 11.75-12.25c-.25-6.25-4.25-11.25-9.5-12.25ZM145 20H29.75L15 50h130v-7.5h-5.25l5.25-9.75V20ZM26.5 42.5l5.75-12 6.25 12h-12Zm11.25-15h10.5L43 37.25l-5.25-9.75Zm9.25 15 5.25-9.75 5.25 9.75H47Zm9.25-15h10.5l-5.25 9.75-5.25-9.75Zm9.25 15 5.25-9.75L76 42.5H65.5Zm9.25-15h10.5L80 37.25l-5.25-9.75Zm9.25 15 5.25-9.75 5.25 9.75H84Zm51.75-5.25-5.25-9.75H141l-5.25 9.75Z"
    />
    <path fill="#5F59FF" fillOpacity=".2" d="M25.25 47.5h5v25h-5v-25Z" />
    <path fill="#101350" d="M32 70h-8.25l-8.5 10h25L32 70Z" />
    <path
      fill="#5F59FF"
      fillOpacity=".2"
      d="m15.25 80 8.5 10H32l8.25-10h-25ZM123.5 72.5V80l-22-7.5H95V155h6.5v-15l22 7.5-22 7.5H130V72.5h-6.5Zm0 67.5-22-7.5 22-7.5v15Zm-22-15v-15l22 7.5-22 7.5Zm22-15-22-7.5 22-7.5v15Zm-22-15V80l22 7.5-22 7.5Z"
    />
    <path
      fill="#101350"
      d="M135 68c0 3.75-3 7-6.5 7h-32C93 75 90 71.75 90 68V12c0-3.75 3-7 6.5-7h32.25C132 5 135 8.25 135 12v56Z"
    />
    <path
      fill="#5F59FF"
      fillOpacity=".2"
      d="M97.25 38c0 2.5 1.75 4.5 3.75 4.5h22.5c2 0 3.75-2 3.75-4.5V17c0-2.5-1.75-4.5-3.75-4.5H101c-2 0-3.75 2-3.75 4.5v21ZM127.25 62.5c0 2.75-2 5-4.25 5h-21.5c-2.25 0-4.25-2.25-4.25-5v-10c0-2.75 2-5 4.25-5H123c2.25 0 4.25 2.25 4.25 5v10Z"
    />
  </svg>
);

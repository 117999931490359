import styled from '@emotion/styled/macro';
import { Icon } from 'components';
import PropTypes from 'prop-types';

import wording from './wording.json';

const CountBadge = styled.span`
  font-size: 13px;
  letter-spacing: 0.2px;
  border-radius: 10px;
  color: ${({ theme, isSelected }) =>
    theme.dropdowns.colors[isSelected ? 'selection' : 'options'].badge};
`;

const FilterValue = styled.span`
  margin-left: 5px;
  font-weight: 700;
  text-transform: capitalize;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: ${({ theme, isSelected }) =>
    theme.dropdowns.colors[isSelected ? 'selection' : 'options'].label};
`;

const FilterItemContainer = styled('div')(({ theme, disabled }) => ({
  fontSize: '14px',
  letterSpacing: '0.2px',
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  padding: '5px 5px',
  overflow: 'hidden',
  color: theme.dropdowns.colors.options.label,
  label: {
    cursor: disabled ? 'not-allowed' : 'pointer',
    flex: '1 0',
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
  },

  input: {
    margin: 0,
  },

  '.icon': {
    display: 'flex',
  },

  '.info': {
    overflow: 'hidden',
  },
}));

const FilterItem = ({ filter, disabled, onChange, isSelected = false, ...props }) => {
  const label = filter.label ? filter.label : filter.value;
  const formattedLabel = wording.filters.source[label] || label;
  return (
    <FilterItemContainer disabled={disabled} {...props}>
      <label>
        <div className="icon">
          <input
            style={{ display: 'none' }}
            type="checkbox"
            value={filter.value}
            disabled={disabled}
            checked={filter.checked}
            onChange={onChange}
          />
          <Icon type={filter.checked ? 'circle-checked' : 'circle-unchecked'} />
        </div>
        <div className="info">
          <FilterValue isSelected={isSelected} title={formattedLabel}>
            {formattedLabel}
          </FilterValue>
          &nbsp;
          <CountBadge isSelected={isSelected}>({filter.doc_count})</CountBadge>
        </div>
      </label>
    </FilterItemContainer>
  );
};

FilterItem.propTypes = {
  filter: PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string,
    checked: PropTypes.bool.isRequired,
    doc_count: PropTypes.number,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  isSelected: PropTypes.bool,
};

export default FilterItem;

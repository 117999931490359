export default (props) => (
  <svg {...props} viewBox="3 3 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="22" height="22" fill={props.fill} fillOpacity="0.01" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25 14.0672C25 7.95496 20.0751 3 14 3C7.92486 3 3 7.95496 3 14.0672C3 19.5912 7.02254 24.1697 12.2812 25V17.2663H9.48828V14.0672H12.2812V11.629C12.2812 8.85525 13.9235 7.32313 16.4361 7.32313C17.6396 7.32313 18.8984 7.53929 18.8984 7.53929V10.2629H17.5114C16.1449 10.2629 15.7188 11.116 15.7188 11.9912V14.0672H18.7695L18.2818 17.2663H15.7188V25C20.9775 24.1697 25 19.5912 25 14.0672"
      fill={props.fill}
    />
  </svg>
);

export default (props) => (
  <svg viewBox="0 0 22 22" {...props}>
    <g fill="#fff" fillRule="evenodd">
      <rect width="22" height="22" fillOpacity="0" />
      <path
        d="m4.0709 10c-0.046717 0.3266-0.070889 0.66048-0.070889 1 0 0.51487 0.055588 1.0168 0.16109 1.5h3.8916c-0.034563-0.48588-0.052648-0.98731-0.052648-1.5 0-0.33866 0.0078912-0.67241 0.02321-1h-3.9523zm0.21899-1h3.8046c0.19625-2.0333 0.68475-3.7538 1.339-4.824-2.4619 0.56282-4.4292 2.4236-5.1436 4.824zm13.639 1h-3.9523c0.015319 0.32759 0.02321 0.66134 0.02321 1 0 0.51269-0.018086 1.0141-0.052648 1.5h3.8916c0.1055-0.48323 0.16109-0.98513 0.16109-1.5 0-0.33952-0.024172-0.6734-0.070889-1zm-0.21899-1c-0.7144-2.4004-2.6817-4.2612-5.1436-4.824 0.65422 1.0702 1.1427 2.7907 1.339 4.824h3.8046zm-8.6846 1c-0.016693 0.32625-0.025533 0.6601-0.025533 1 0 0.51573 0.020351 1.0175 0.057605 1.5h3.8848c0.037254-0.48249 0.057605-0.98427 0.057605-1.5 0-0.3399-0.0088397-0.67375-0.025532-1h-3.9489zm0.077327-1h3.7943c-0.30226-2.9078-1.2097-5-1.8971-5s-1.5949 2.0922-1.8971 5zm-4.6432 4.5c0.82486 2.1567 2.6858 3.801 4.9738 4.324-0.59988-0.98134-1.0604-2.5094-1.2841-4.324h-3.6898zm13.081 0h-3.6898c-0.22367 1.8147-0.68421 3.3427-1.2841 4.324 2.2881-0.52309 4.149-2.1674 4.9738-4.324zm-8.3786 0c0.34844 2.6446 1.1909 4.5 1.8383 4.5s1.4898-1.8554 1.8383-4.5h-3.6765zm1.8383 5.5c-4.4183 0-8-3.5817-8-8s3.5817-8 8-8 8 3.5817 8 8-3.5817 8-8 8z"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

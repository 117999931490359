// styleguide : https://zeroheight.com/85e3d2296/p/082c41
import type { CSSObject } from '@emotion/styled/macro';
import styled from '@emotion/styled/macro';
import type { Theme } from 'theme/theme';

const Title0 = styled.div(({ theme }: { theme: Theme }) => theme.title0 as CSSObject);

const Title1 = styled.div(({ theme }: { theme: Theme }) => theme.title1 as CSSObject);

const Title2 = styled.div(({ theme }: { theme: Theme }) => theme.title2 as CSSObject);

const Title3 = styled.div(({ theme }: { theme: Theme }) => theme.title3 as CSSObject);

const Title4 = styled.div(({ theme }: { theme: Theme }) => theme.title4 as CSSObject);

const Title5 = styled.h5(({ theme }: { theme: Theme }) => theme.title5 as CSSObject);

const Title6 = styled.h6(({ theme }: { theme: Theme }) => theme.title6 as CSSObject);

export { Title0, Title1, Title2, Title3, Title4, Title5, Title6 };

export default (props) => (
  <svg viewBox="0 0 22 22" {...props}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <rect id="Rectangle" fillOpacity="0" fill="#FFFFFF" x="0" y="0" width="22" height="22" />
      <path
        d="M13.2928932,11 L8.64644661,15.6464466 C8.45118446,15.8417088 8.45118446,16.1582912 8.64644661,16.3535534 C8.84170876,16.5488155 9.15829124,16.5488155 9.35355339,16.3535534 L14.3535534,11.3535534 C14.5488155,11.1582912 14.5488155,10.8417088 14.3535534,10.6464466 L9.35355339,5.64644661 C9.15829124,5.45118446 8.84170876,5.45118446 8.64644661,5.64644661 C8.45118446,5.84170876 8.45118446,6.15829124 8.64644661,6.35355339 L13.2928932,11 Z"
        id="Path-26"
        fill="#FFFFFF"
        transform="translate(11.500000, 11.000000) scale(-1, 1) translate(-11.500000, -11.000000) "
      />
    </g>
  </svg>
);

import { css } from '@emotion/css/macro';
import { withTheme } from '@emotion/react';
import styled from '@emotion/styled/macro';
import { scaleTime } from 'd3-scale';
import { differenceInMonths, format } from 'date-fns';
import _ from 'lodash';
import { arrayOf, bool, func, number, object, shape } from 'prop-types';
import { useState } from 'react';
import { formatNumber } from 'shared/formatters';

import { XAxis, YAxis } from './Axis';
import Hover from './Hover';
import { Area, Line } from './Shapes';

const Container = styled.div(
  () => css`
    position: relative;
  `
);

const LineChart = ({
  theme,
  width,
  height,
  data,
  boundaries,
  highlightedPeriod,
  highlightedPeriod2,
  isDetailed,
  hasHover,
  onPeriodClick = _.noop(),
}) => {
  // Generate a unique ID for each chart
  // This will allow us to use the id (like css ID) based gradient and clipth path elements
  const [uniqueId] = useState(() => _.uniqueId('id-'));
  const idGrandient = `area-gradient-line-chart-${uniqueId}`;
  const idGrandient2 = `area-gradient2-line-chart-${uniqueId}`;
  const idClipPath = `highlighted-period-${uniqueId}`;
  const idClipPath2 = `highlighted-period2-${uniqueId}`;

  // Padding of the line chart in the avalaible space
  const padding = {
    top: isDetailed ? 20 : 10,
    bottom: isDetailed ? 20 : 10,
    left: isDetailed ? 15 : 10,
    right: 2.5,
  };

  const chartWidth = width - padding.right;
  const chartHeight = height - padding.bottom;

  const firstDate = boundaries.from;
  const lastDate = boundaries.to;

  const xScale = scaleTime().domain([firstDate, lastDate]).range([padding.left, chartWidth]);

  const valueMin = 0;
  const valueMax = _.get(
    _.maxBy(data, (item) => item.value),
    'value',
    0
  );

  const yScale = scaleTime()
    .domain([valueMin, valueMax || chartHeight])
    .range([chartHeight, padding.top]);

  const maskStart = xScale(highlightedPeriod.from);
  const maskEnd = xScale(highlightedPeriod.to);

  const maskStart2 = highlightedPeriod2 && xScale(highlightedPeriod2.from);
  const maskEnd2 = highlightedPeriod2 && xScale(highlightedPeriod2.to);

  // If the max and min dates are in the same month
  // we adapt the formating of the date on the axis
  const isPeriodSameMonth = differenceInMonths(lastDate, firstDate) === 0;
  const xAxisDateFormat = isPeriodSameMonth ? 'dd MMM yyyy' : 'MMM yyyy';

  return (
    <Container>
      <svg width={width} height={height}>
        <defs>
          <linearGradient id={idGrandient} gradientTransform="rotate(90)">
            <stop offset="0%" stopOpacity={0.4} stopColor={theme.lineChart.gradientStart} />
            <stop offset="100%" stopOpacity={0} stopColor={theme.lineChart.gradientEnd} />
          </linearGradient>
          {highlightedPeriod2 && (
            <linearGradient id={idGrandient2} gradientTransform="rotate(90)">
              <stop offset="0%" stopOpacity={0.4} stopColor={theme.lineChart.gradient2Start} />
              <stop offset="100%" stopOpacity={0} stopColor={theme.lineChart.gradient2End} />
            </linearGradient>
          )}
          <clipPath id={idClipPath}>
            <rect x={maskStart} y="0" width={maskEnd - maskStart} height={height} />
          </clipPath>
          {highlightedPeriod2 && (
            <clipPath id={idClipPath2}>
              <rect x={maskStart2} y="0" width={maskEnd2 - maskStart2} height={height} />
            </clipPath>
          )}
        </defs>
        <Line opacify data={data} xScale={xScale} yScale={yScale} />
        <Area
          data={data}
          xScale={xScale}
          yScale={yScale}
          idClipPath={idClipPath}
          idGrandient={idGrandient}
          withBounds={isDetailed}
          maskStart={maskStart}
          maskEnd={maskEnd}
          maskStart2={maskStart2}
          maskEnd2={maskEnd2}
          idClipPath2={idClipPath2}
          idGrandient2={idGrandient2}
          y={padding.top}
          height={chartHeight}
        />
        <XAxis
          x={padding.left}
          y={chartHeight}
          width={chartWidth}
          isDetailed={isDetailed}
          min={isDetailed && format(firstDate, xAxisDateFormat)}
          max={isDetailed && format(lastDate, xAxisDateFormat)}
        />
        {isDetailed && (
          <YAxis
            x={padding.left}
            y={padding.top}
            height={chartHeight}
            isDetailed={isDetailed}
            min={isDetailed && formatNumber(valueMin, { isTrueInteger: true })}
            max={isDetailed && formatNumber(valueMax, { isTrueInteger: true })}
          />
        )}
      </svg>
      {(isDetailed || hasHover) && (
        <Hover
          width={chartWidth}
          data={data}
          boundaries={boundaries}
          xScale={xScale}
          yScale={yScale}
          onPeriodClick={onPeriodClick}
        />
      )}
    </Container>
  );
};

LineChart.propTypes = {
  /** Width in pixel */
  width: number.isRequired,
  /** Height in pixel */
  height: number.isRequired,
  /** The dataset to display */
  data: arrayOf(
    shape({
      from: object.isRequired,
      to: object.isRequired,
      date: object.isRequired,
      value: number.isRequired,
    })
  ).isRequired,
  boundaries: shape({
    from: object.isRequired,
    to: object.isRequired,
  }).isRequired,
  /** if set, it will focus the attention on a part of the graph */
  highlightedPeriod: shape({
    from: object.isRequired,
    to: object.isRequired,
  }),
  /** if set, it will focus the attention on a part of the graph */
  highlightedPeriod2: shape({
    from: object.isRequired,
    to: object.isRequired,
  }),
  /** Display more details on the axis and allow interactions */
  isDetailed: bool,
  hasHover: bool,
  onPeriodClick: func,
};

export default withTheme(LineChart);

export type LinkProps = {
  fill: string;
};

export default ({ fill, ...props }: LinkProps) => (
  <svg fill="none" viewBox="0 0 23 22" {...props}>
    <path fill={fill} d="M3.5 13.831h1v2.077h14v-2.077h1v3.115h-16v-3.115Z" />
    <path
      fill={fill}
      d="M10.5 13.866H9.487v-.897h-.025C9.022 13.657 8.372 14 7.515 14c-.63 0-1.124-.151-1.483-.453-.355-.303-.532-.704-.532-1.204 0-1.071.696-1.694 2.09-1.87l1.897-.239c0-.974-.435-1.462-1.305-1.462-.761 0-1.45.236-2.064.706v-.94C6.741 8.179 7.458 8 8.27 8c1.487 0 2.23.713 2.23 2.138v3.728Zm-1.013-2.9-1.527.19c-.47.06-.824.167-1.063.32-.24.15-.358.416-.358.8 0 .28.11.509.327.688.223.175.518.264.884.264.232.006.464-.034.678-.116.215-.082.407-.205.565-.36.329-.321.494-.726.494-1.214v-.571ZM12.57 13.048h-.026v.865H11.5v-8.86h1.044v3.928h.025c.514-.794 1.266-1.192 2.255-1.192.837 0 1.491.269 1.962.806.476.533.714 1.251.714 2.151 0 1.001-.266 1.804-.797 2.408-.53.6-1.257.9-2.179.9-.86 0-1.512-.335-1.954-1.006Zm-.026-2.413v.836c0 .495.174.915.521 1.262.354.342.8.514 1.338.514.634 0 1.128-.222 1.485-.667.36-.444.541-1.06.541-1.851 0-.667-.167-1.188-.503-1.567-.335-.378-.79-.566-1.364-.566-.606 0-1.094.194-1.464.583-.37.387-.554.87-.554 1.456Z"
    />
  </svg>
);

import { ApolloClient, HttpLink, split } from '@apollo/client';
import { InMemoryCache, defaultDataIdFromObject } from '@apollo/client/cache';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import sentry, { LOGS_LEVEL, LOGS_TYPE } from 'libs/sentry';
import _ from 'lodash';
import auth from 'services/authentication';
import { apolloClientMaintenanceRedirect } from 'shared/maintenanceRedirect';

const errorLink = onError(({ networkError }) => {
  if (_.get(networkError, 'statusCode') === 401) {
    auth.signOut();
    return;
  }

  apolloClientMaintenanceRedirect(networkError);
});

const authLink = setContext((__, { headers }) => {
  return {
    headers: {
      ...headers,
    },
  };
});

const httpLink = new HttpLink({
  uri: '/api/graphql',
  credentials: 'same-origin',
});

const wsProtocol = window.location.protocol === 'http:' ? 'ws' : 'wss';
const wsLink = new WebSocketLink({
  uri: `${wsProtocol}://${document.location.host}/api/graphql`,
  options: {
    reconnect: true,
  },
});

// The split function takes three parameters:
//
// * A function that's called for each operation to execute
// * The Link to use for an operation if the function returns a "truthy" value
// * The Link to use for an operation if the function returns a "falsy" value
const link = split(
  // split based on operation type
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return kind === 'OperationDefinition' && operation === 'subscription';
  },
  wsLink,
  httpLink
);

const createCacheKey = (name, params) => {
  if (params.includes(undefined)) {
    const error = new Error(
      `Wrong params in cache for "${name}" oject, incoherent results can appear`
    );
    sentry.sendLogs({ error, type: LOGS_TYPE.REACT, level: LOGS_LEVEL.FATAL });
  }
  return [name, ...params].join(':');
};

const apolloClient = new ApolloClient({
  link: authLink.concat(errorLink).concat(link),
  cache: new InMemoryCache({
    dataIdFromObject: (object) => {
      switch (object.__typename) {
        case 'WorkingVersionGlobalChanges':
          return createCacheKey('WorkingVersionGlobalChanges', [object.projectKey]);
        case 'DiscoveryTimePeriod':
        case 'CommunityDetails':
        case 'Document':
        case 'DiscoveryFilter':
        case 'Node':
        case 'NodeSet':
          return null;
        default:
          return defaultDataIdFromObject(object);
      }
    },
  }),
});

export default apolloClient;

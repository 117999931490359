export default (props) => (
  <svg viewBox="0 0 22 22" {...props}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M11,19 C6.581722,19 3,15.418278 3,11 C3,6.581722 6.581722,3 11,3 C15.418278,3 19,6.581722 19,11 C19,15.418278 15.418278,19 11,19 Z M11,18 C14.8659932,18 18,14.8659932 18,11 C18,7.13400675 14.8659932,4 11,4 C7.13400675,4 4,7.13400675 4,11 C4,14.8659932 7.13400675,18 11,18 Z M14.9250923,13.2375769 L14.3515159,14.0567289 L10.5,11.3598684 L10.5,10.8400296 L10.5,5.5 L11.5,5.5 L11.5,10.8393014 L14.9250923,13.2375769 Z"
        id="Combined-Shape"
        fill={props.fill}
        fillRule="nonzero"
      />
    </g>
  </svg>
);

import styled from '@emotion/styled';
import ExternalLinkModal from 'components/ExternalLink/ExternalLinkModal';
import { type FC, type ReactNode, useState } from 'react';

const LinkTxt = styled.span`
  cursor: pointer;
`;

type ExternalLinkConfirmProps = {
  children: ReactNode;
  href: string;
};

const ExternalLinkConfirm: FC<ExternalLinkConfirmProps> = ({ children, href }) => {
  const [showPopup, setShowPopup] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
    setShowPopup(true);
  };

  return (
    <>
      <LinkTxt onClick={handleClick}>{children}</LinkTxt>
      <ExternalLinkModal href={href} showPopup={showPopup} onClose={() => setShowPopup(false)} />
    </>
  );
};

export default ExternalLinkConfirm;

type Props = {
  fill: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

export default ({ fill, ...props }: Props) => (
  <svg viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg" {...props} fill="none">
    <rect x="4.66602" y="4.08333" width="7" height="8.16667" stroke={fill} strokeLinejoin="round" />
    <path
      d="M2.33398 9.91667C2.33398 9.91667 2.33398 2.33335 2.33398 1.75002H9.33398"
      stroke={fill}
      strokeLinecap="round"
    />
  </svg>
);

import { css, withTheme } from '@emotion/react';
import styled from '@emotion/styled/macro';
import { Toggle } from 'components';
import { Paragraph3 } from 'components/text/Paragraph';
import type React from 'react';
import type { FC, FocusEvent } from 'react';
import type { Theme } from 'theme/theme';

const Container = styled.div<{ disabled: boolean }>(
  ({ disabled = false, theme }) => css`
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: ${theme.col};
    width: 100%;

    &:hover {
      // no need to add more opacity in disabled, we do it in the Title
      .react-switch-bg {
        opacity: ${disabled ? theme.fullOpacity : theme.mediumDarkOpacity};
      }
      .react-switch-handle {
        opacity: ${disabled ? theme.fullOpacity : theme.mediumDarkOpacity};
      }
    }
    & > :hover {
      cursor: ${disabled ? 'default' : 'pointer'};
    }
  `
);

const Title = styled(Paragraph3)<{ opacity: number }>(
  ({ opacity = 1, theme }) => css`
    display: flex;
    justify-content: space-between;
    color: ${theme.mainColor};
    flex-grow: 1;
    opacity: ${opacity};
    div:last-child {
      // to override the default opacity for disabled = 0.5
      // in react-switch
      opacity: 1 !important;
    }
  `
).withComponent('label');

export type TitledSwitchProps = {
  checked: boolean;
  children: React.ReactNode;
  disabled?: boolean;
  onBlur?: (event: FocusEvent<HTMLElement>) => void; // Callback when toggle is blurred.
  onChange?: (
    checked: boolean,
    event: React.SyntheticEvent<MouseEvent | KeyboardEvent> | MouseEvent,
    id: string
  ) => void; //  (checked: boolean) => void ?
  onColor?: string; // Color of the toggle when it is in "ON" state.
  testId?: string; // id for the toggle, e2e tests.
  theme: Theme;
};
const TitledSwitch: FC<TitledSwitchProps> = ({
  checked,
  children,
  disabled = false,
  onBlur,
  onChange,
  onColor,
  testId,
  theme,
}) => {
  const getOpacity = () => {
    if (disabled) {
      return theme.clearerOpacity;
    }
    if (!checked && !disabled) {
      return theme.mediumDarkOpacity;
    }
    return theme.fullOpacity;
  };
  return (
    <Container data-testid={`${testId}-wrapper`} disabled={disabled}>
      <Title opacity={getOpacity()} data-testid={`${testId}-title`}>
        <div>{children}</div>
        <Toggle
          checked={checked}
          disabled={disabled}
          onBlur={onBlur}
          onChange={onChange}
          onColor={onColor}
          testId={testId}
        />
      </Title>
    </Container>
  );
};

export default withTheme(TitledSwitch);

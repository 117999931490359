import { css, keyframes } from '@emotion/css/macro';
import { withTheme } from '@emotion/react';
import { Box } from 'components';
import { bool, number, string } from 'prop-types';
import useTimeout from 'shared/useTimeout';
const rotation = keyframes({
  '0%': {
    transform: 'rotate(0deg)',
  },
  '100%': {
    transform: 'rotate(270deg)',
  },
});

const turn = (dashLength) =>
  keyframes({
    '0%': {
      strokeDashoffset: dashLength,
    },

    '50%': {
      strokeDashoffset: dashLength / 4,
      transform: 'rotate(135deg)',
    },

    '100%': {
      strokeDashoffset: dashLength,
      transform: 'rotate(450deg)',
    },
  });

const circle = (radius, theme) => {
  const dashLength = 2 * Math.PI * radius;
  return css({
    stroke: theme?.spiner?.color || null,
    strokeDasharray: dashLength,
    strokeDashoffset: 0,
    transformOrigin: 'center',
    animation: `${turn(dashLength)} 1.35s ease-in-out infinite`,
  });
};

const spinner = css({
  animation: `${rotation} 1.35s linear infinite`,
});

const CenterPageWrapper = ({ center, children }) =>
  center ? (
    <Box
      flexDirection="column"
      display="flex"
      height="100%"
      justifyContent="center"
      alignItems="center"
    >
      {children}
    </Box>
  ) : (
    <>{children}</>
  );

const Loader = ({ theme, width = 45, center = true, loadingMessage, delay = 200 }) => {
  const ready = useTimeout(delay);
  if (!ready) return null;

  const strokeWidth = width < 30 ? 4 : 6;
  const circleRadius = width - strokeWidth * 2;

  return (
    <CenterPageWrapper center={center}>
      <Box>
        <svg
          className={spinner}
          width={width}
          height={width}
          viewBox={`0 0 ${circleRadius * 2 + strokeWidth} ${circleRadius * 2 + strokeWidth}`}
        >
          <circle
            className={circle(circleRadius, theme)}
            fill="none"
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            cx={circleRadius + strokeWidth / 2}
            cy={circleRadius + strokeWidth / 2}
            r={circleRadius}
          />
        </svg>
      </Box>
      {loadingMessage && (
        <Box width="100%" mt={10} textAlign="center">
          {loadingMessage}
        </Box>
      )}
    </CenterPageWrapper>
  );
};

Loader.propTypes = {
  width: number,
  center: bool,
  delay: number,
  loadingMessage: string,
};

export default withTheme(Loader);

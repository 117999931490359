//////// Style constants ////////

const fullOpacity = 1;
const darkOpacity = 0.8;
const mediumDarkOpacity = 0.6;
const opacity = 0.5;
const disabledOpacityRatio = 1.5;
const lightOpacity = 0.4;
const clearerOpacity = 0.3;
const superLightOpacity = 0.15;
const borderRadius = '2px';
const iconSizeSmall = 14;
const iconSizeMedium = 16;
const iconSize = 22;
const iconSizeLarge = 32;
const colValue = 15;
const gutterValue = colValue * 2;

//////// Fonts ////////
const fontFamily = 'Inter, arial, sans-serif';

//////// Basic colors ////////

// base colors
const white = '#ffffff';
const whiteDisabled = `rgba(255, 255, 255, ${1 / disabledOpacityRatio})`;
const whiteLighter = `rgba(255, 255, 255, 0.6)`;
const whiteLight = `rgba(255, 255, 255, ${opacity})`;
const whiteLightDisabled = `rgba(255, 255, 255, ${opacity / disabledOpacityRatio})`;

// primary colors
const blueLight = '#3657CF';
const blue = '#2541A3';
const blue2 = '#252970';
const blueMedium = '#193081';
const blueDark = '#102262';
const blueDarkUltra = '#101350';
const deepBlue = '#000039';
const highContrast = '#365AD8';

// secondary colors
const turquoiseLightExtra = '#5CFFF5';
const turquoiseLight = '#1BBBCF';
const turquoise = '#209BB2';
const turquoiseMedium = '#227D96';
const turquoiseDark = '#21627A';
const turquoiseDarkUltra = '#1D4C5D';

// accent colors
const purpleLight = '#94ACFF';
const purpleBright = '#7772FF';
const purple = '#847fff';
const purpleDark = '#5F59FF';
const electricBlue = '#0678CA';

const orange = '#ffd569';

const red = '#F95385';
const green = '#69eecd';

const linkColorDisabled = 'rgba(132, 127, 255, 0.6)';
// overlay
const overlay = 'rgba(0,0,57, 0.7)';

// gray colors
const gray400 = 'rgba(156, 163, 175)';

const emotionsTypeColors = {
  positive: '#61C391',
  neutral: '#878787',
  negative: red,
  positiveDark: '#307e56',
  neutralDark: '#a67dff',
  negativeDark: '#9e0000',
};

// shadows
const boxShadow = '0 20px 40px 0 rgba(22,11,46,0.50)';
const boxShadowBottom = '0 0 5px rgba(7, 8, 33, 0.3)';

// gradients
const bodyBackgroundBlue = `${blueDarkUltra} radial-gradient(120% 100% ellipse at 50% -40%, rgba(37,111,138,0.50) 50%, rgba(65,44,126,0.00) 100%)`;
const bodyBackgroundPurple = `${blueDarkUltra} radial-gradient(120% 100% ellipse at 50% -40%, rgba(65, 44, 126, 1) 50%, rgba(65,44,126,0.00) 100%)`;
const buttonBackgroundElectricBlue = `linear-gradient(180deg, #49A8FF 0%, ${electricBlue} 100%)`;
const buttonBackgroundElectricBlueLight = `linear-gradient(180deg, #6DB9FF 0%, #2093E7 100%)`;
const mediumBlueGradientBackground = `linear-gradient(180deg, ${blue} 0%, #142B79 99.75%);`;
const headerBackgroundBlue = `radial-gradient(circle at center right, rgba(54,90,216,0.2) 0%, rgba(54,90,216,0) 70%)`;
const bodyGradientBackgroundDarker =
  'radial-gradient(50% 33.08% at 50% 0%, #163e65 0%, #101350 100%)';

// business colors
const indicatorsColors = {
  activeActors: {
    color: 'rgb(255, 220, 95)',
    // @todo Remove abacus: the following keys can be deleted when the abacus will be remove
    border: 'rgba(255, 220, 95,0.40)',
    background: 'rgba(255, 220, 95, 0.2)',
  },
  motivation: {
    color: 'rgb(111, 238, 147)',
    border: 'rgba(111, 238, 147,0.50)',
    background: 'rgba(111, 238, 147,0.25)',
  },
  engagementRatio: {
    color: 'rgb(92, 196, 255)',
    border: 'rgba(92, 196, 255,0.60)',
    background: 'rgba(92, 196, 255,0.30)',
  },
  emotionIndex: {
    color: 'rgb(230, 187, 237)',
    border: 'rgba(230, 187, 237,0.30)',
    background: 'rgba(230, 187, 237,0.15)',
  },
};

//////// spaces and gutters ////////

/**
 * map the cobalt theme with standard keys
 */
const cobaltValues = {
  fullOpacity,
  darkOpacity,
  mediumDarkOpacity,
  opacity,
  lightOpacity,
  clearerOpacity,
  superLightOpacity,
  borderRadius,
  iconSizeSmall,
  iconSizeMedium,
  iconSize,
  iconSizeLarge,

  mainColor: white,
  mainColorDisabled: whiteDisabled,
  mainColorLight: whiteLight,
  mainColorLightDisabled: whiteLightDisabled,
  oppositeColor: white,
  oppositeColorLight: whiteLight,

  primaryColorLight: blueLight,
  primaryColor: blue,
  primaryColor2: blue2,
  primaryColorMedium: blueMedium,
  primaryColorDark: blueDark,
  primaryColorDarkUltra: blueDarkUltra,
  primaryDeepBlue: deepBlue,
  primaryHighContrast: highContrast,

  secondaryColorLightUltra: turquoiseLightExtra,
  secondaryColorLight: turquoiseLight,
  secondaryColor: turquoise,
  secondaryColorMedium: turquoiseMedium,
  secondaryColorDark: turquoiseDark,
  secondaryColorDarkUltra: turquoiseDarkUltra,

  accentColor: purple,
  accentColorLight: purpleLight,
  accentColorBright: purpleBright,
  accentColorDark: purpleDark,
  accentColorElectricBlue: electricBlue,

  warningColor: orange,
  danger: red,
  succes: green,

  linkColorDisabled,

  overlay,

  gray400,

  emotionsTypeColors,
  boxShadow,
  boxShadowBottom,

  indicatorsColors,

  colValue: colValue,
  gutterValue: gutterValue,
  col: `${colValue}px`,
  gutter: `${gutterValue}px`,

  fontFamily,
  bodyBackgroundPrimary: bodyBackgroundBlue,
  bodyBackgroundAccent: bodyBackgroundPurple,
  buttonBackgroundAccent: buttonBackgroundElectricBlue,
  buttonBackgroundAccentLight: buttonBackgroundElectricBlueLight,
  mediumBlueGradientBackground: mediumBlueGradientBackground,
  headerBackgroundBlue: headerBackgroundBlue,
  bodyGradientBackgroundDarker,

  whiteLighter,
};

export default cobaltValues;

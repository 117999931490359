export default (props) => (
  <svg {...props} viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path
        d="M12.4167 17.4043H1.58337C0.892957 17.4043 0.333374 16.8447 0.333374 16.1543V1.1543C0.333374 0.46388 0.892957 -0.0957031 1.58337 -0.0957031H9.50004L13.6667 4.07096V16.1543C13.6667 16.8447 13.1071 17.4043 12.4167 17.4043Z"
        fill="#00AC47"
      />
      <path d="M13.6667 4.07096H9.5V-0.0957031L13.6667 4.07096Z" fill="#00832D" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.619 7H3V12.7143H10.619V7ZM9.66667 7.95238H7.30952V9.35715H9.66667V7.95238ZM9.66667 10.3571H7.30952V11.7619H9.66667V10.3571ZM6.30952 9.35715V7.95238H3.95238V9.35715H6.30952ZM3.95238 10.3571H6.30952V11.7619H3.95238V10.3571Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="14" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const SvgComponent = (props) => (
  <svg viewBox="0 0 16.98 12.99" {...props} width="16.98" height="12.99">
    <path
      d="M15.97 7.5H3.62l.11.11 3.3 3.44a1.13 1.13 0 0 1 0 1.6 1.08 1.08 0 0 1-1.57 0L.32 7.3a1.17 1.17 0 0 1 0-1.61L5.46.33a1.06 1.06 0 0 1 1.55 0 1.17 1.17 0 0 1 0 1.61L3.73 5.35c-.06.07-.09.14-.15.2L16 5.46a1 1 0 0 1 1 1 1 1 0 0 1-1.03 1.04z"
      fillRule="evenodd"
    />
  </svg>
);

export default SvgComponent;

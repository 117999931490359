export type Props = {
  fill: string;
};

export default (props: Props) => (
  <svg viewBox="0 0 22 22" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fill={props.fill}
      d="M16 17.712V3.288A.287.287 0 0 0 15.715 3H5.285A.286.286 0 0 0 5 3.288v12.649c0 .018.002.037.005.055v.001a.29.29 0 0 0 .034.086.288.288 0 0 0 .044.061l1.752 1.776a.286.286 0 0 0 .043.036l.024.012.025.014.03.01c.008.002.015.005.023.006a.277.277 0 0 0 .05.005l.006.001h8.68c.157 0 .284-.13.284-.288ZM5.972 16.224h.78v.79l-.78-.79Zm9.111.942H7.75v-1.25c0-.16-.301-.417-.458-.417H5.917V3.833h9.166v13.334Z"
    />
    <path fill={props.fill} d="M8 8h3v1H8zM8 10h4v1H8zM8 12h5v1H8z" />
  </svg>
);

export type Props = {
  fill: string;
};

export default (props: Props) => (
  <svg {...props} viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
    <path
      fill={props.fill}
      fillRule="evenodd"
      d="M8.72 8.534 9.371 5l1.076.162-.622 3.372h3.259L13.734 5l1.076.162-.621 3.372H17v.986h-2.992l-.546 2.96H17v.986h-3.72L12.629 17l-1.076-.162.622-3.372H8.916L8.266 17l-1.077-.162.622-3.372H5v-.986h2.992l.546-2.96H5v-.986h3.72Zm.924.986-.545 2.96h3.257l.546-2.96H9.643Z"
      clipRule="evenodd"
    />
  </svg>
);

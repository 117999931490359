export default (props) => (
  <svg viewBox="0 0 22 22" {...props}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <rect fillOpacity="0" fill="#FFFFFF" x="0" y="0" width="22" height="22" />
      <g transform="translate(1.000000, 5.000000)">
        <polygon
          stroke="#FFFFFF"
          opacity="0.599934896"
          points="2.62011173 9.5 5.62011173 1.5 15.1201117 7"
        />
        <circle fill="#FFFFFF" cx="15" cy="7" r="5" />
        <circle fill="#FFFFFF" cx="2.5" cy="9.5" r="2.5" />
        <circle fill="#FFFFFF" cx="5.5" cy="1.5" r="1.5" />
      </g>
    </g>
  </svg>
);

export type Props = {
  fill: string;
};

export default (props: Props) => (
  <svg {...props} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11 0.440002C5.1678 0.440002 0.440002 5.1678 0.440002 11C0.440002 16.8322 5.1678 21.56 11 21.56C16.8322 21.56 21.56 16.8322 21.56 11C21.56 5.1678 16.8322 0.440002 11 0.440002ZM8.415 15.3769H6.2766V8.4953H8.415V15.3769ZM7.3326 7.6505C6.6572 7.6505 6.2205 7.172 6.2205 6.5802C6.2205 5.9763 6.6704 5.5121 7.3601 5.5121C8.0498 5.5121 8.4722 5.9763 8.4854 6.5802C8.4854 7.172 8.0498 7.6505 7.3326 7.6505ZM16.225 15.3769H14.0866V11.5632C14.0866 10.6755 13.7764 10.0727 13.0031 10.0727C12.4124 10.0727 12.0615 10.4808 11.9064 10.8735C11.8492 11.0132 11.8349 11.2112 11.8349 11.4081V15.3758H9.6954V10.6898C9.6954 9.8307 9.6679 9.1124 9.6393 8.4942H11.4972L11.5951 9.4501H11.638C11.9196 9.0013 12.6093 8.3391 13.7632 8.3391C15.1701 8.3391 16.225 9.2818 16.225 11.308V15.3769Z"
      fill={props.fill}
    />
  </svg>
);

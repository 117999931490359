import { withTheme } from '@emotion/react';
import _ from 'lodash';
import type { FC, FocusEvent } from 'react';
import type React from 'react';
import Switch from 'react-switch';
import type { Theme } from 'theme/theme';

export type ToggleProps = {
  checked: boolean;
  disabled?: boolean;
  id?: string;
  onBlur?: (event: FocusEvent<HTMLElement>) => void; // Callback when toggle is blurred.
  onChange?: (
    checked: boolean,
    event: React.SyntheticEvent<MouseEvent | KeyboardEvent> | MouseEvent,
    id: string
  ) => void;
  onColor?: string;
  testId?: string;
  theme: Theme;
  handleDiameter?: number;
  height?: number;
  width?: number;
};

const ToggleWithoutTheme: FC<ToggleProps> = ({
  onChange,
  checked,
  theme,
  onColor,
  disabled,
  testId,
  ...props
}) => {
  const ReactSwitchdefaultProps = {
    uncheckedIcon: false,
    checkedIcon: false,
    handleDiameter: 20,
    height: 24,
    width: 48,
  };

  return (
    <Switch
      {...ReactSwitchdefaultProps}
      {...props}
      onColor={onColor ? onColor : theme.succes}
      onChange={onChange ? onChange : _.noop}
      checked={checked}
      disabled={disabled || !onChange}
      data-testid={testId}
    />
  );
};

const Toggle = withTheme(ToggleWithoutTheme);

export default Toggle;

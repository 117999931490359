import { css } from '@emotion/css/macro';
import styled from '@emotion/styled/macro';
import _ from 'lodash';

const StyledButtonGroup = styled.span(({ theme }) => {
  const secondary = _.get(theme, 'buttons.secondary');
  return css`
    display: flex;
    margin: 25px 0;
    margin-right: 40px;

    &:last-child {
      margin-right: 0;
    }

    a,
    button {
      text-decoration: none;
      color: ${secondary.color};
      border: 1px solid ${secondary.border};
      background-color: ${secondary.background};
      font-size: 14px;
      padding: 7px 17px 8px;
      margin-right: -1px;
      cursor: pointer;
      display: flex;
      border-radius: 0;

      svg {
        height: 16px;
        margin-right: 5px;
        fill: ${secondary.color};
      }

      &:hover:not(:disabled) {
        color: ${secondary.hover.color};
        border: 1px solid ${secondary.hover.border};
        background-color: ${secondary.hover.background};
      }

      &.active {
        color: ${secondary.active.color};
        border: 1px solid ${secondary.active.border};
        background-color: ${secondary.active.background};
      }

      &:last-child {
        border-radius: 0 20px 20px 0;
        padding: 7px 20px 8px 17px;
      }

      &:first-child {
        border-radius: 20px 0 0 20px;
        padding: 7px 17px 8px 20px;
        margin-right: -1px;
      }

      &:only-child {
        border-radius: 20px;
        padding: 7px 20px 8px;
      }
    }
  `;
});

/**
 * You can wrap some `Buttons` with a `ButtonGroup` component to get the style of grouped buttons.
 */
const ButtonGroup = ({ children, className }) => {
  return <StyledButtonGroup className={className}>{children}</StyledButtonGroup>;
};

export default ButtonGroup;

export default (props) => (
  <svg {...props} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM3.5 4H6.36364L8.47618 6.7544L10.925 4H12.0091L8.97855 7.4088L12.5 12H9.63636L7.38145 9.0604L4.76818 12H3.68409L6.87909 8.4056L3.5 4ZM5.95455 4.8H5.13636L10.0455 11.2H10.8636L5.95455 4.8Z"
      fill={props.fill}
    />
  </svg>
);

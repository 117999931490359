export default (props) => (
  <svg viewBox="0 0 22 22" {...props}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <rect fillOpacity="0" fill="#FFFFFF" x="0" y="0" width="22" height="22" />
      <g transform="translate(1.000000, 6.000000)" fill="#FFFFFF">
        <polygon opacity="0.302664621" points="3 10 15 10 14 0 2 5" />
        <circle cx="15" cy="5" r="5" />
        <circle cx="2.5" cy="7.5" r="2.5" />
      </g>
    </g>
  </svg>
);

import { css } from '@emotion/css/macro';
import { withTheme } from '@emotion/react';
import styled from '@emotion/styled/macro';
import { Box, Icon } from 'components';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import FilterItem from 'routes/Projects/Project/DataViz/shared/FilterItem';

const DropdownBox = styled.div`
  position: absolute;
  border-radius: 3px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
  z-index: 100;
  background: white;
  min-width: 300px;
`;

const DropDownHeader = styled.div`
  border-bottom: 1px solid rgba(217, 217, 217, 1);
  padding: 10px;
`;

const DropDownBody = styled.div`
  padding: 10px 5px;
  max-height: 200px;
  overflow: auto;
`;

const InputWrapper = styled.div(({ noMaxWidth, disabled, theme }) => {
  const { inputs } = theme;
  return css`
    position: relative;
    max-width: ${noMaxWidth ? 'none' : '400px'};
    cursor: ${disabled ? 'not-allowed' : 'pointer'};
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    margin-bottom: 10px;
    width: 100%;
    border-radius: 18px;
    padding: 4px 10px;
    color: ${inputs.colors.text};
    border: ${inputs.border};
    background-color: ${inputs.colors.background};
  `;
});

const Input = styled.input`
  flex: 1;
  outline: none;
  border: none;
  background-color: transparent;
  height: 35px;
`;

const Action = styled.span`
  cursor: pointer;
  text-decoration: underline;
`;

const ActionsContainer = styled.div`
  text-align: right;
  font-size: 12px;
  color: #8a8a8a;
`;

const FilterContainer = styled.div`
  flex: 1;
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
`;

const Title = styled.div`
  color: #9ca1b8;
  font-size: 14px;
  font-style: italic;
  text-transform: capitalize;
  font-weight: normal;
`;

const Placeholder = styled.span(({ theme }) => {
  const { dropdowns } = theme;
  return css`
    color: ${dropdowns.colors.placeholder};
    margin-left: 5px;
    letter-spacing: 0.2px;
    font-size: 15px;
  `;
});

const SelectedFilterItems = styled.div`
  display: flex;
  overflow: hidden;
  position: absolute;
  left: 0;
  right: 22px;
  margin: 0 15px;
`;

const Arrow = styled(Icon)`
  position: absolute;
  right: 12px;
`;

const Actions = ({ canSelectAll, onSelectAll, onUnSelectAll, disabled }) => (
  <ActionsContainer>
    {canSelectAll && (
      <span>
        <Action onClick={disabled ? null : onSelectAll}>All</Action>
        {' | '}
      </span>
    )}
    <Action onClick={disabled ? null : onUnSelectAll}>None {canSelectAll ? '' : 'filtre'}</Action>
  </ActionsContainer>
);

const Dropdown = ({
  title,
  filters,
  onChange,
  canSelectAll = false,
  placeholder,
  disabled,
  noMaxWidth,
  theme,
}) => {
  const [search, setSearch] = useState('');
  const [searchFocused, setSearchFocused] = useState(false);
  const inputRef = useRef(null);
  const wrapperRef = useRef(null);

  const onSelectAll = () => {
    const updatedFilters = filters.map((f) => ({
      ...f,
      checked: true,
    }));
    onChange(updatedFilters);
  };

  const onUnSelectAll = () => {
    const updatedFilters = filters.map((f) => ({
      ...f,
      checked: false,
    }));
    onChange(updatedFilters);
  };

  const onInputChange = (e) => {
    const { checked, value } = e.target;
    const updatedFilters = filters.map((f) => {
      if (f.value === value) {
        return {
          ...f,
          checked,
        };
      }
      return f;
    });
    onChange(updatedFilters);
  };

  const updateSearch = (e) => setSearch(e.target.value);

  const activateFocus = () => {
    setSearchFocused(true);
  };

  useEffect(() => {
    const clickOutside = (e) => {
      if (!_.get(wrapperRef, 'current') || wrapperRef.current.contains(e.target)) return;

      setSearchFocused(false);
      clearEventListeners();
    };

    const clearEventListeners = () => {
      document.body.removeEventListener('click', clickOutside);
    };

    const addEventListener = () => {
      document.body.addEventListener('click', clickOutside);
    };

    if (searchFocused) {
      inputRef.current.focus();
      addEventListener();
    }

    return () => {
      clearEventListeners();
    };
  }, [searchFocused]);

  if (!filters) return null;

  const checkedFilters = filters.filter((f) => f.checked);
  const searchFilters = filters.filter((f) => {
    if (!search) return true;
    return f.value.toLowerCase().indexOf(search.toLowerCase()) >= 0;
  });

  return (
    <FilterContainer ref={wrapperRef}>
      {title && (
        <TitleContainer>
          <Title>{title}</Title>
        </TitleContainer>
      )}
      <Box mb={15}>
        <InputWrapper
          noMaxWidth={noMaxWidth}
          disabled={disabled}
          onClick={disabled ? null : activateFocus}
        >
          {searchFocused && (
            <Input
              ref={inputRef}
              type="text"
              value={search}
              disabled={disabled}
              onChange={updateSearch}
            />
          )}
          {!searchFocused && (
            <SelectedFilterItems>
              {_.size(checkedFilters) ? (
                checkedFilters.map((filter) => (
                  <div key={filter.value}>
                    <FilterItem
                      isSelected
                      disabled={disabled}
                      filter={filter}
                      onChange={onInputChange}
                    />
                  </div>
                ))
              ) : (
                <Placeholder>{placeholder}</Placeholder>
              )}
            </SelectedFilterItems>
          )}
          <Arrow
            width={12}
            height={12}
            type={searchFocused ? 'arrow-up' : 'arrow-down'}
            color={theme.dropdowns.colors.arrow}
          />
        </InputWrapper>
        {searchFocused && (
          <DropdownBox>
            <DropDownHeader>
              <Actions
                disabled={disabled}
                canSelectAll={canSelectAll}
                onSelectAll={onSelectAll}
                onUnSelectAll={onUnSelectAll}
              />
            </DropDownHeader>
            <DropDownBody>
              {searchFilters.map((filter) => (
                <FilterItem filter={filter} key={filter.value} onChange={onInputChange} />
              ))}
            </DropDownBody>
          </DropdownBox>
        )}
      </Box>
    </FilterContainer>
  );
};

Dropdown.propTypes = {
  title: PropTypes.string,
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any.isRequired,
      doc_count: PropTypes.number.isRequired,
      checked: PropTypes.bool.isRequired,
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  canSelectAll: PropTypes.bool,
  placeholder: PropTypes.string,
};

export default withTheme(Dropdown);

export default (props) => (
  <svg viewBox="0 0 22 22" {...props}>
    <path
      d="M10.5,16 C13.5375661,16 16,13.5375661 16,10.5 C16,7.46243388 13.5375661,5 10.5,5 C7.46243388,5 5,7.46243388 5,10.5 C5,13.5375661 7.46243388,16 10.5,16 Z M10.5,15 C8.01471863,15 6,12.9852814 6,10.5 C6,8.01471863 8.01471863,6 10.5,6 C12.9852814,6 15,8.01471863 15,10.5 C15,12.9852814 12.9852814,15 10.5,15 Z"
      fill={props.fill}
    />
    <polygon
      fill={props.fill}
      points="13.2928932 14 17.3635848 18.0706916 18.0706916 17.3635848 14 13.2928932"
    />
  </svg>
);

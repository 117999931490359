import { css } from '@emotion/css';
import styled from '@emotion/styled/macro';
import { Link } from 'components/text/Link';
import type { ButtonHTMLAttributes, ForwardedRef, ReactNode } from 'react';
import { forwardRef } from 'react';
import type { LinkProps } from 'react-router-dom';
import type { Buttons as ThemeButtonsType } from 'theme/cobalt/types';

export type SquareButtonStyleProps = {
  color: Exclude<keyof ThemeButtonsType, 'secondaryLight'>;
};

export const SquareButtonStyle = styled.button<SquareButtonStyleProps>(
  ({ theme, color, disabled }) => css`
    background-color: ${theme.buttons[color]?.background};
    border-radius: ${theme.borderRadius};
    font-weight: ${theme.paragraph3.fontWeight};
    font-size: ${theme.paragraph3.fontSize};
    line-height: 30px;
    padding: 0 15px;
    color: ${theme.buttons[color]?.color};
    text-decoration: none;
    cursor: ${disabled ? 'not-allowed' : 'pointer'};
    border: none;
    outline: none;
    opacity: ${disabled ? theme.opacity : 1};

    ${!disabled &&
    css`
      &:focus {
        box-shadow: 0px 0px 0px 2px ${theme.buttons[color]?.active.border};
      }

      &:hover {
        color: ${theme.buttons[color]?.hover.color};
        background-color: ${theme.buttons[color]?.hover.background};
      }
    `}
  `
);

export type ButtonSquareProps = {
  to?: LinkProps['to'];
  testId?: string;
  children: ReactNode;
} & SquareButtonStyleProps &
  ButtonHTMLAttributes<Element>;

const ButtonSquare = forwardRef<HTMLButtonElement | HTMLAnchorElement, ButtonSquareProps>(
  (
    {
      to,
      color = 'primary',
      children,
      type = 'button',
      disabled = false,
      className,
      onClick,
      testId = null,
    },
    ref
  ) => {
    if (!to)
      return (
        <SquareButtonStyle
          ref={ref as ForwardedRef<HTMLButtonElement>}
          color={color}
          type={type}
          disabled={disabled}
          className={className}
          onClick={onClick}
          data-testid={testId}
        >
          {children}
        </SquareButtonStyle>
      );
    return (
      <Link to={to} className={className} ref={ref as ForwardedRef<HTMLAnchorElement>}>
        <SquareButtonStyle color={color} type={type} data-testid={testId}>
          {children}
        </SquareButtonStyle>
      </Link>
    );
  }
);

export default ButtonSquare;

export default (props) => (
  <svg viewBox="0 0 22 22" {...props}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <rect fill="#FFFFFF" opacity="0" x="0" y="0" width="26" height="20" />
      <rect fill="#FFFFFF" opacity="0.30359468" x="0" y="8" width="2" height="3" />
      <rect fill="#FFFFFF" opacity="0.30359468" x="24" y="8" width="2" height="3" />
      <rect fill="#FFFFFF" opacity="0.30359468" x="6" y="7" width="2" height="5" />
      <rect fill="#FFFFFF" opacity="0.30359468" x="19" y="7" width="2" height="5" />
      <rect fill="#FFFFFF" x="12" y="4" width="3" height="11" />
    </g>
  </svg>
);

import { getConfig } from 'config/config-service';
import _ from 'lodash';
export { isUrl, isDevEnv, isE2eEnv, isProdEnv };

/**
 * isUrl
 * @param {string} url - The value of the url
 * @returns {boolean}
 */
function isUrl(url) {
  // check value include http or https
  const regex = /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/; // eslint-disable-line
  if (regex.test(url) && typeof url === 'string') {
    return true;
  }

  return false;
}

function isDevEnv(config) {
  if (!config) config = getConfig();
  return config && _.includes(config.APP_ENV, 'dev');
}

function isProdEnv(config) {
  if (!config) {
    config = getConfig();
  }
  return config && config.APP_ENV === 'production';
}

function isE2eEnv(config) {
  if (!config) {
    config = getConfig();
  }

  return config && config.APP_ENV === 'e2e';
}

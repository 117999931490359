export default () => (
  <svg
    width="160"
    height="160"
    viewBox="0 0 160 160"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M128.75 152H31.25C29.5728 152 28 150.585 28 148.571V11.4286C28 9.41523 29.5728 8 31.25 8H96.6863L99.9999 11.3136V40H128.686L132 43.3137V126.343L110.285 104.628C115.855 97.8062 119.197 89.0925 119.197 79.5984C119.197 57.7288 101.468 40 79.5984 40C57.7288 40 40 57.7288 40 79.5984C40 101.468 57.7288 119.197 79.5984 119.197C89.0925 119.197 97.8062 115.855 104.628 110.285L130.172 135.828C130.696 136.353 131.33 136.701 132 136.874V148.571C132 150.585 130.427 152 128.75 152ZM99.9999 0H100L140 40L140 40V148.571C140 154.884 134.964 160 128.75 160H31.25C25.0362 160 20 154.884 20 148.571V11.4286C20 5.11619 25.0362 0 31.25 0H99.9999ZM99.5677 104.088L102.056 102.056L104.088 99.5677C108.535 94.1221 111.197 87.1818 111.197 79.5984C111.197 62.1471 97.0497 48 79.5984 48C62.1471 48 48 62.1471 48 79.5984C48 97.0497 62.1471 111.197 79.5984 111.197C87.1818 111.197 94.1221 108.535 99.5677 104.088ZM66.1716 71.8284L74.3431 80L66.1716 88.1716L71.8284 93.8284L80 85.6569L88.1716 93.8284L93.8284 88.1716L85.6569 80L93.8284 71.8284L88.1716 66.1716L80 74.3431L71.8284 66.1716L66.1716 71.8284Z"
      fill="#5F59FF"
      fillOpacity="0.2"
    />
  </svg>
);

import styled from '@emotion/styled/macro';

const BackgroundContainer = styled.div`
  z-index: -1;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(
    50deg,
    #0b0f23 0%,
    #0b0f2a 40%,
    #0b0f29 50%,
    #0c0e28 60%,
    #090918 100%
  );
`;

const Background: React.FC = () => <BackgroundContainer />;

export default Background;

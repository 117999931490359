const SvgComponent = (props) => (
  <svg viewBox="0 0 17.01 12.99" width="17.01" height="12.99" {...props}>
    <path
      d="M16.69 7.3l-5.15 5.35a1 1 0 0 1-1.54 0 1.15 1.15 0 0 1 0-1.6l3.28-3.41c.06-.07.09-.14.15-.2L.98 7.53a1 1 0 0 1-1-1 1 1 0 0 1 1-1h12.38l-.12-.12L9.93 2a1.15 1.15 0 0 1 0-1.6 1.11 1.11 0 0 1 .83-.35 1 1 0 0 1 .78.34l5.15 5.35a1.15 1.15 0 0 1 0 1.56z"
      fillRule="evenodd"
    />
  </svg>
);

export default SvgComponent;

import cobaltValues from 'theme/cobalt/values';
import common from 'theme/common';

import darkComponentsValues from './componentsValues';
import fontFace from './fontFace';
import darkValues from './values';

//////// spaces and gutters ////////

export default {
  ...common,
  ...cobaltValues, // temprarily contain cobalt theme for compatibility with non uptodate components
  ...darkValues,
  ...darkComponentsValues,
  fontFace,
};

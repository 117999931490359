export default (props) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.93058 6.38041L5.43189 2.88173C5.35055 2.80035 5.25397 2.73579 5.14768 2.69174C5.04138 2.6477 4.92745 2.62503 4.81239 2.62503C4.69733 2.62503 4.5834 2.6477 4.4771 2.69174C4.3708 2.73579 4.27423 2.80035 4.19289 2.88173L0.694201 6.38041C0.612819 6.46175 0.548262 6.55833 0.504216 6.66463C0.46017 6.77092 0.4375 6.88485 0.4375 6.99992C0.4375 7.11498 0.46017 7.22891 0.504216 7.3352C0.548262 7.4415 0.612819 7.53808 0.694201 7.61942L2.13401 9.05967L1.31239 9.88085L1.93101 10.4999L2.75308 9.67829L4.19289 11.1181C4.27423 11.1995 4.3708 11.264 4.4771 11.3081C4.5834 11.3521 4.69733 11.3748 4.81239 11.3748C4.92745 11.3748 5.04138 11.3521 5.14768 11.3081C5.25397 11.264 5.35055 11.1995 5.43189 11.1181L6.41889 10.1311L5.79939 9.51204L4.81151 10.4995L1.31283 7.00079L4.81151 3.50035L8.31195 7.00079L7.65614 7.65617L8.2752 8.27523L8.93058 7.61942C9.01196 7.53808 9.07652 7.4415 9.12056 7.3352C9.16461 7.22891 9.18728 7.11498 9.18728 6.99992C9.18728 6.88485 9.16461 6.77092 9.12056 6.66463C9.07652 6.55833 9.01196 6.46175 8.93058 6.38041Z"
        fill={props.fill}
      />
      <path
        d="M13.3056 6.38041L11.8658 4.94017L12.6874 4.11898L12.0688 3.49991L11.2471 4.32154L9.80689 2.88173C9.72555 2.80035 9.62897 2.73579 9.52268 2.69174C9.41638 2.6477 9.30245 2.62503 9.18739 2.62503C9.07233 2.62503 8.9584 2.6477 8.8521 2.69174C8.7458 2.73579 8.64923 2.80035 8.56789 2.88173L7.58089 3.86873L8.19995 4.48735L9.18651 3.50035L12.687 7.00079L9.18651 10.4995L5.68783 7.00079L6.34408 6.3441L5.72458 5.7246L5.0692 6.38041C4.98782 6.46175 4.92326 6.55833 4.87922 6.66463C4.83517 6.77092 4.8125 6.88485 4.8125 6.99992C4.8125 7.11498 4.83517 7.22891 4.87922 7.3352C4.92326 7.4415 4.98782 7.53808 5.0692 7.61942L8.56789 11.1181C8.64923 11.1995 8.7458 11.264 8.8521 11.3081C8.9584 11.3521 9.07233 11.3748 9.18739 11.3748C9.30245 11.3748 9.41638 11.3521 9.52268 11.3081C9.62897 11.264 9.72555 11.1995 9.80689 11.1181L13.3056 7.61942C13.387 7.53808 13.4515 7.4415 13.4956 7.3352C13.5396 7.22891 13.5623 7.11498 13.5623 6.99992C13.5623 6.88485 13.5396 6.77092 13.4956 6.66463C13.4515 6.55833 13.387 6.46175 13.3056 6.38041Z"
        fill={props.fill}
      />
    </svg>
  );
};

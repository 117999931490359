export default (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...props}>
    <path
      d="M256,50.4c-66.7,0-75,0-100,2.8c-25,0-38.9,5.6-47.2,8.3C97.7,64.3,89.3,72.7,81,81c-8.3,5.6-16.7,16.7-19.4,27.8
       c-2.8,8.3-8.3,22.2-8.3,47.2c0,27.8-2.8,33.3-2.8,100s0,75,2.8,100c0,25,5.6,38.9,8.3,47.2c2.8,11.1,11.1,19.4,19.4,27.8
       c8.3,8.3,16.7,13.9,27.8,19.4c8.3,2.8,22.2,8.3,47.2,8.3s33.3,2.8,100,2.8s75,0,100-2.8c25,0,38.9-5.6,47.2-8.3
       c22.2-8.3,38.9-25,47.2-47.2c2.8-8.3,8.3-22.2,8.3-47.2c0-25,2.8-33.3,2.8-100s0-75-2.8-100c0-25-5.6-38.9-8.3-47.2
       C447.7,97.7,439.3,89.3,431,81c-8.3-8.3-16.7-13.9-27.8-19.4c-8.3-2.8-22.2-8.3-47.2-8.3C331,50.4,322.7,50.4,256,50.4z M256,6
       c66.7,0,77.8,0,102.8,2.8c27.8,0,44.4,5.6,61.1,11.1c16.7,5.6,30.6,16.7,44.4,27.8c13.9,13.9,22.2,27.8,27.8,44.4
       s11.1,33.3,11.1,61.1S506,189.3,506,256s0,77.8-2.8,102.8c0,27.8-5.6,44.4-11.1,61.1c-13.9,33.3-38.9,61.1-72.2,72.2
       c-16.7,5.6-33.3,11.1-61.1,11.1S322.7,506,256,506s-77.8,0-102.8-2.8c-27.8,0-44.4-5.6-61.1-11.1c-16.7-5.6-30.6-16.7-44.4-27.8
       c-13.9-11.1-22.2-27.8-27.8-44.4c-5.6-16.7-11.1-33.3-11.1-61.1C6,333.8,6,322.7,6,256s0-77.8,2.8-102.8c0-27.8,5.6-44.4,11.1-61.1
       s16.7-30.6,27.8-44.4s27.8-22.2,44.4-27.8s33.3-11.1,61.1-11.1C181,6,189.3,6,256,6L256,6z M256,128.2
       c72.2,0,127.8,58.3,127.8,127.8S325.4,383.8,256,383.8S128.2,328.2,128.2,256S183.8,128.2,256,128.2z M256,339.3
       c47.2,0,83.3-36.1,86.1-80.6c0-47.2-36.1-83.3-80.6-86.1c-2.8,0-2.8,0-5.6,0c-47.2,0-83.3,38.9-80.6,86.1
       C175.4,303.2,211.6,339.3,256,339.3z M389.3,153.2c-16.7,0-30.6-13.9-30.6-30.6s13.9-30.6,30.6-30.6l0,0c16.7,0,30.6,13.9,30.6,30.6
       S406,153.2,389.3,153.2z"
    />
  </svg>
);

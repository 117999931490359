import { Body, BodySmall, H3 } from '@bloom/ui';
import { css } from '@emotion/css/macro';
import { withTheme } from '@emotion/react';
import styled from '@emotion/styled/macro';
import Icon from 'components/Icon';
import { format } from 'date-fns';
import _ from 'lodash';
import { useCallback } from 'react';

import useNotification, { NOTIFICATION_TYPES } from './useNotification';
import wording from './wording.json';

const TYPES_HEIGHT_MAPPING = {
  [NOTIFICATION_TYPES.GLOBAL_INFO]: 0,
  [NOTIFICATION_TYPES.PRECISION_INFO]: 1,
};

const NotificationContainer = styled.div(({ type }) => {
  return css`
    position: fixed;
    right: 15px;
    bottom: calc(15px + 170px * ${TYPES_HEIGHT_MAPPING[type]});
    width: 450px;
    padding: 30px calc(30px * 2);
    background: #193081;
    z-index: 50;
    border-radius: 2px;
  `;
});
const Title = styled(H3)`
  font-weight: bold;
  position: relative;
`;
const CTA = styled(Body)`
  padding-top: 20px;
  cursor: pointer;
  text-decoration-line: underline;
  color: white;

  &:hover {
    text-decoration-line: none;
  }
`;
const NotificationIcon = styled(Icon)(() => {
  return css`
    position: absolute;
    left: -30px;
    top: 3px;
  `;
});
const Timestamp = styled(BodySmall)`
  position: absolute;
  right: 20px;
  bottom: 20px;
`;
const Close = styled(Icon)`
  cursor: pointer;
  stroke: white;
  position: absolute;
  right: 20px;
  top: 20px;

  &:hover {
    opacity: ${({ theme }) => theme.opacity};
  }

  &:active {
    opacity: ${({ theme }) => theme.lightOpacity};
  }
`;

const Notification = withTheme(
  ({
    theme,
    iconType,
    iconColor,
    title,
    content,
    cta,
    onClick,
    closeNotification,
    type,
    timestamp,
  }) => {
    const onClose = useCallback(() => {
      closeNotification(type);
    }, [closeNotification, type]);

    const onClickWrapper = useCallback(() => {
      if (onClick) onClick();
      onClose();
    }, [onClick, onClose]);

    return (
      <NotificationContainer type={type}>
        <div>
          <Title>
            {iconType && (
              <NotificationIcon
                width={18}
                height={18}
                type={iconType}
                color={_.isFunction(iconColor) ? iconColor(theme) : iconColor}
              />
            )}
            {title}
          </Title>
          {content && <Body>{content}</Body>}
        </div>
        {cta && <CTA onClick={onClickWrapper}>{cta}</CTA>}
        <Timestamp>
          {wording.at}
          {format(timestamp, 'HH:mm')}
        </Timestamp>
        <Close type="discovery-close" width={22} height={22} onClick={onClose} color={'white'} />
      </NotificationContainer>
    );
  }
);

const Wrapper = () => {
  const { notifications, closeNotification } = useNotification();

  return _.map(notifications, (notif) => (
    <Notification key={notif.type} {...notif} closeNotification={closeNotification} />
  ));
};

export default Wrapper;

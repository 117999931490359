export default (props) => (
  <svg viewBox="0 0 22 22" {...props}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M8.70710678,11 L16,11 L16,12 L8.70710678,12 L12.8535534,16.1464466 C13.0488155,16.3417088 13.0488155,16.6582912 12.8535534,16.8535534 C12.6582912,17.0488155 12.3417088,17.0488155 12.1464466,16.8535534 L7.14644661,11.8535534 C6.95118446,11.6582912 6.95118446,11.3417088 7.14644661,11.1464466 L12.1464466,6.14644661 C12.3417088,5.95118446 12.6582912,5.95118446 12.8535534,6.14644661 C13.0488155,6.34170876 13.0488155,6.65829124 12.8535534,6.85355339 L8.70710678,11 Z"
        id="Combined-Shape"
        fill="#FFFFFF"
        transform="translate(11.500000, 11.500000) scale(-1, 1) rotate(180.000000) translate(-11.500000, -11.500000)"
      />
    </g>
  </svg>
);

import styled from '@emotion/styled/macro';
import { Box } from 'components';

export const RouteLayout = ({ children, header }) => (
  <Box flex="1">
    {header}
    <Box p="1em">{children}</Box>
  </Box>
);

export const H1 = styled('h1')`
  margin: 0;
  padding: 15px ${({ theme }) => theme.widthMargin} 12px;
  font-family: Geomanist;
  font-size: 13px;
  color: ${({ theme }) => theme.header.colors.text};
  background-color: ${({ theme }) => theme.header.colors.background};
  text-transform: uppercase;
`;

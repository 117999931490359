import styled from '@emotion/styled/macro';
import {
  alignItems,
  borderRight,
  bottom,
  color,
  display,
  flex,
  flexDirection,
  flexWrap,
  fontSize,
  height,
  justifyContent,
  left,
  maxWidth,
  minWidth,
  position,
  right,
  space,
  style,
  textAlign,
  top,
  width,
  zIndex,
} from 'styled-system';

const transform = style({ prop: 'transform' });
const column = (props) => (props.column ? { flexDirection: 'column' } : null);
const overflow = style({ prop: 'overflow' });

const Box = styled('div')(
  space,
  width,
  fontSize,
  color,
  display,
  height,
  maxWidth,
  minWidth,
  flexDirection,
  justifyContent,
  alignItems,
  flexWrap,
  flex,
  overflow,
  textAlign,
  position,
  top,
  right,
  left,
  bottom,
  transform,
  column,
  zIndex,
  borderRight
);

export default Box;

import { hasSomeRoles as hasViewerSomeRoles } from '@bloom-hub/shared';
import sentry, { LOGS_LEVEL, LOGS_TYPE } from 'libs/sentry';

/**
 * Checks if a viewer roles contains a roles or one of the roles of a list
 */
export const hasSomeRoles = (viewer, roles) => {
  try {
    return hasViewerSomeRoles(viewer, roles);
  } catch (error) {
    sentry.sendLogs({
      type: LOGS_TYPE.REACT,
      level: LOGS_LEVEL.WARNING,
      message: error,
    });
  }
  return false;
};
